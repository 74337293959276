import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import * as actions from "../../../actions/clientDetails/ShareRegister";
import * as actionsnew from "../../../actions/Client";
import * as deadline from "../../../actions/clientDetails/Deadline";
import * as personalActions from "../../../actions/clientDetails/Personal";
import Attachment from "../modals/AttachmentModal";
import Edit from "../modals/EditShare";
import Nothing from "../modals/NothingSelected";
import ApproveShare from "../modals/ApproveShare";
import DeleteResolution from "../modals/DeleteResolution";
import moment from "moment";
import $, { valHooks } from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function Resolution(props) {
  var table;
  useEffect(() => {
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getShareResolutionlist();
    props.getShareHolderList();
    props.getShareTypeList();

    if (sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(27)) {
      setEdit("inline-block")
    };

    if (sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(28)) {
      setDelet("inline-block")
    };

    return () => {
      table.destroy(true);
    };
  }, []);

  // console.log("deep", props.shareResolutionList)

  const [edit, setEdit] = useState("none");
  const [delet, setDelet] = useState("none");

  useEffect(() => {
    function format(d) {
      console.log("did", d);
      return (
        `<tr id="detail-view-${d?.id}"><td colspan="7" style="padding:0px;"><table id="detail-view-${d?.id}"   >` +
        "<tr>" +
        "<td>Remark:</td>" +
        "<td>" +
        `${(() => {
          if (d.remarks != undefined) {
            return d?.remarks
          } else {
            return ''
          }
        })()}`
        +
        "</td>" +
        "</tr>" +
        `</table></td></tr>`
      );
    }
    $('#resolution').on('shown.bs.collapse', function () {
      $($.fn.dataTable.tables(true)).DataTable()
         .columns.adjust();
   });
    $(document).ready(function () {

      table = $("#resolution").DataTable({
      
        pageLength:25,
        lengthMenu: [ 25, 50 ],
        paging: true,
        searching: false,
        destroy: true,
        dom: '<"top"lBf>rt<"bottom"ip><"clear">',
        "order": [[ 1, "desc" ]],
        data: props.shareResolutionList,
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
          },
          {
            targets: 0,
            orderable: true,
            searchable: true,
            className: " col-xs-12 col-sm-12 col-md-12 col-lg-12",
            render: function (data, type, row) {
              return `<span/>${moment(row.date).format("DD/MM/YYYY")}</span>`;
            },
          },
        ],
        rowCallback: function (row, data, index) {
          if (!data.is_approved) {
            $(row).css("background-color", "#0075b0");
          }
        },
        createdRow: function (row, data, dataIndex) {
          $("td:eq(0)", row).attr("colspan", 9).css("color", "white");
          $("td:eq(1)", row).css("display", "none");
          $("td:eq(2)", row).css("display", "none");
          $("td:eq(3)", row).css("display", "none");
          $("td:eq(4)", row).css("display", "none");
          $("td:eq(5)", row).css("display", "none");
          $("td:eq(6)", row).css("display", "none");
          $("td:eq(7)", row).css("display", "none");
          $("td:eq(8)", row).css("display", "none");
        },
      });

      $("#resolution tbody").on("click", ".document", function (e) {
        var targetid=e.target.id;
        
        props.changeClientInfo("client_key", targetid);
      
        props.changeClientInfo("attachmentModal", true);
        
      });
      table.rows().every(function () {
        var rowdata = this.data();
        var dataChild = rowdata.list;
        // console.log("dataChild", rowdata.date)
        this.child(
          dataChild.map((item) => {
            let clr = '';
            if (item.is_approved == 0) {
              clr = '#f44242'
            } else {
              clr = '<span style="margin-left: 30px;">---</span>';
            }
            return $(
              `<tr style="background-color: ${clr}" >` +
              `<td id=child${item.id} title=${item.id}  class='details-control '>` +
              `</td>` +
              "<td class='btnbox'>" +
              `${(() => {
                if (item.check == true) {
                  return `<input class="check" id=check${item.id}  title=${item.id} name=""  checked=${item.check} type="checkbox" />`;
                } else {
                  return `<input class="check" id=check${item.id} title=${item.id} name=${item.id}   type="checkbox" />`;
                }
              })()}` +
              "</td>" +
              "<td class=''>" +
              `${(() => {


                console.log('item.action:====> ', item.action);


                if (item.action != null && item.action != undefined) {
                  return item.action;
                } else {
                  return '<span style="margin-left: 30px;">---</span>'
                }
              })()}
              ${(() => {
                if (item.docids != '' && item.docids != null && item.docids != undefined) {
                  return'<a><span class="document glyphicon glyphicon-paperclip" id="'+item.docids+'" /></a>';
                } else {
                  if (item.document_link != '0_1_1' && item.document_link != '' && item.document_link != null && item.document_link != undefined) {
                    return'<a><span class="document glyphicon glyphicon-paperclip" id="'+item.document_link+'" /></a>';
                  }else{
                    return '<span style="margin-left: 30px;">---</span>'
                  }
                  
                }
              })()}` +
              "</td>" +
              "<td class=''>" +
              `${(() => {
                if (
                  item.share_holder != null &&
                  item.share_holder != undefined
                ) {
                  return item.share_holder;
                } else {
                  return '<span style="margin-left: 30px;">---</span>'
                }
              })()}` +
              "</td>" +
              "<td class=''>" +
              `${(() => {
                if (
                  item.to_share_holder != null &&
                  item.to_share_holder != undefined
                ) {
                  return item.to_share_holder;
                } else {
                  return '<span style="margin-left: 30px;">---</span>'
                }
              })()}` +
              "</td>" +
              "<td class=''>" +
              `${(() => {
                if (item.share_type != null && item.share_type != undefined) {
                  return item.share_type;
                } else {
                  return '<span style="margin-left: 30px;">---</span>'
                }
              })()}` +
              "</td>" +
              "<td class=''>" +
              `${(() => {
                if (
                  item.to_share_type != null &&
                  item.to_share_type != undefined
                ) {
                  return item.to_share_type;
                } else {
                  return '<span style="margin-left: 30px;">---</span>'
                }
              })()}` +
              "</td>" +
              "<td class=''>" +
              `${(() => {
                if (
                  item.no_of_shares != null &&
                  item.no_of_shares != undefined
                ) {
                  return item.no_of_shares;
                } else {
                  return '<span style="margin-left: 30px;">---</span>'
                }
              })()}` +
              "</td>" +
              "<td >" +
              `${(() => {
                if (
                  item.share_value != null &&
                  item.share_value != undefined
                ) {
                  return item.share_value;
                } else {
                  return '<span style="margin-left: 30px;">---</span>'
                }
              })()}` +
              "</td>" +
                "<td class=''>" +
              `${(() => {
                // if (
                //   item.action === "add" ||
                //   item.action === "split" 
                // ) {
                //   return  `<button  class="btnDel btn btn-primary btn-xs" style="display:${delet}" id=delete${item.id} title=${item.share_register_id} name="${rowdata.date}" data-type="${item.action}"><span class="glyphicon glyphicon-trash" /></button>`
                // } else {
                  return  `<button class="btnEdit btn btn-primary btn-xs" style="display:${edit}" id=edit${item.id} title=${item.id} name="${rowdata.date}" data-type="${item.action}" ><span class="glyphicon glyphicon-pencil" /></button>
                <button  class="btnDel btn btn-primary btn-xs" style="display:${delet}" id=delete${item.id} title=${item.share_register_id} name="${rowdata.date}" data-type="${item.action}"><span class="glyphicon glyphicon-trash" /></button>`
                // }
              })()}` +
              "</td>" 







              // + "<td >" +
              // `<button class="btnEdit btn btn-primary btn-xs" style="display:${edit}" id=edit${item.id} title=${item.id} name="${rowdata.date}" data-type="${item.action}">Edit</button>
              //   <button  class="btnDel btn btn-primary btn-xs" style="display:${delet}" id=delete${item.id} title=${item.share_register_id} name="${rowdata.date}" data-type="${item.action}"><span class="glyphicon glyphicon-trash" /></button>` +
              // "</td>" +
              // "</tr>"
            );
          })
        ).show();
      });

      // $("#user tbody").off("click");
      // $("#user tbody").on("click", "td.company", function () {
      //   var data = table.row(this).data();
      //   sessionStorage.setItem("personalId", data.id);
      //   // history.push("/client_individual");
      // });

      // $(".details-control").on("click", function () {
      //   let data;
      //   props.shareRegisterList.map((val, idx) => {
      //     val.lists.map((lis) => {
      //       if (lis.id == this.title) {
      //         data = lis;
      //       }
      //     });
      //   });
      //   var tr = $(this).closest("tr");
      //   var row = table.row(tr);
      //   console.log("tr", row);
      //   console.log("rowing", data);

      //   // if (row.child.isShown()) {
      //   //   // This row is already open - close it
      //   //   row.child.hide();
      //   //   tr.removeClass("shown");
      //   //   $("tr.shown td.details-control").toggleClass(
      //   //     "tr.shown td.details-control"
      //   //   );
      //   // } else {
      //   // Open this row
      //   console.log("this", tr);

      //   // document.getElementById(this.title).appendChild($.parseHTML(format(data)))
      //   console.log("dom", $(`#detail-view-${this.title}`).length);
      //   if (!$(`#detail-view-${this.title}`).length) {
      //     row.child($(`#${this.title}`).after(format(data)));
      //     tr.addClass("shown");
      //   } else {
      //     tr.removeClass("shown");
      //     $(`#detail-view-${this.title}`).remove();
      //   }
      // });

      $("#resolution tbody").on("click", ".document", function () { });

      $("#resolution tbody").on("click", ".btnEdit", function () {

        var data = table.row(this).data();


        props.shareResolutionList &&
          props.shareResolutionList.map((val, idx) => {

            console.log(" this trigger -------->" , idx);
            val.list.map((key) => {
              if (key.id == this.title) {
                let shareHolder = "";
                let shareType = "";
                props.shareHolderList.map((reg) => {
                  if (reg.name == key.share_holder) {
                    shareHolder = reg.id;
                  }
                });
                props.ShareTypeList.map((type) => {
                  if (type.Name == key.share_type) {
                    shareType = type.id;
                  }
                });
                // if (new Date(key.date) != "Invalid Date") {
                //   props.sendValues("resolutionDate", new Date(key.date));
                // }
                props.sendValues("resolutionDate", new Date(this.name));
                console.log("chinnu key", key)
                props.sendValues("shareHolder", shareHolder);
                props.sendValues("shareType", shareType);
                props.sendValues("valuePerShare", key.share_value);
                props.sendValues("noOfSharesHeld", key.no_of_shares);
                props.sendValues("documentDate", "");
                props.sendValues("shareId", key.id);
                props.sendValues("editShares", true);
                props.sendValues("shareRegisterId", key.share_register_id);
                props.sendValues("editAction", this.dataset.type);
                // console.log("chinnu key", key)
                props.sendValues("remark", key.remarks);
                props.sendValues("acrafileddate", new Date(key.acrafileddate));
                props.sendValues("key", key);
             


                
                if(this.dataset.type === "transfer"){
                  
                  props.sendValues("transferShareHolder", shareHolder);
                  props.sendValues("transferToShareHolder", key.to_share_holder);
                  props.sendValues("transferShareType", key.share_type);
                  props.sendValues("transferValueShare", key.share_value);
                  // props.sendValues("lotId", key.share_register_id);
                  props.sendValues("transferNoOfShare", key.no_of_shares);
                }else if(this.dataset.type === "convert"){
                  props.sendValues("convertShareHolder", shareHolder);
                  props.sendValues("convertShareType", key.share_type);
                  props.sendValues("convertToShare", key.to_share_type);
                  props.sendValues("convertValueShare", key.share_value);
                  // props.sendValues("lotId", key.share_register_id);
                  props.sendValues("convertNoShares", key.no_of_shares);
                }else if(this.dataset.type === "reduce"){
                  props.sendValues("reduceShareHolder", shareHolder);
                  props.sendValues("reduceShareType", key.share_type);
                  // props.sendValues("convertToShare", key.to_share_type);
                  props.sendValues("reduceValueShare", key.share_value);
                  // props.sendValues("lotId", key.share_register_id);
                  props.sendValues("reduceShareReduction", key.to_no_of_shares);
                }else if(this.dataset.type === "split"){
                  props.sendValues("splitShareType", key.share_type);
                  props.sendValues("splitResolutionDate", new Date(this.name));
                  props.sendValues("splitValues", key.share_value);
                  // props.sendValues("lotId", key.share_register_id);
                  props.sendValues("splitTotalShare", key.no_of_shares);
                  props.sendValues("splitTotalValue", key.no_of_shares*key.share_value);
                }
              }
            });
          });
      });

      $("#resolution tbody").on("click", ".btnDel", function () {
        var data = table.row(this).data();
        props.sendValues("shareId", this.title);
        props.sendValues("resolutionDate", this.name);
        props.sendValues("deleteShareModal", true);
        props.sendValues("editAction", this.dataset.type);
      });

      $("#resolution tbody").on("click", ".btnbox", function (e) {
        var data = table.row(this).data();
        let arr = [...props.shareResolutionList];
        let ind;
        var allids="";

        props.shareResolutionList &&
          props.shareResolutionList.map((val, idx) => {
            val.list.map((key,index) => {

             
             
              var checkid="check"+key.id;
          
              if(document.getElementById(checkid) != null){
                var checkstatus=document.getElementById(checkid).checked;
                key.check =checkstatus;
                if(checkstatus == true){
                  allids += allids + key.id+",";
                }
              }
              // if (key.id == e.target.title) {
              //   key.check = e.target.checked;
              //   props.sendValues("shareId", e.target.name);
              // } else {
              //   key.check = false
              // }
            });
          });
        // arr[ind].check = e.target.checked;
        // props.sendValues("shareHistoryList", arr);
        // if (e.target.name != "" && e.target.name != undefined) {
        //   props.sendValues("shareId", e.target.name);
        // } else {
        //   props.sendValues("shareId", "");
        // }
        console.log(allids,"allids")
        if(allids == ""){
          props.sendValues("shareId","");
        }else{
          allids = allids.slice(0, -1);
          props.sendValues("shareId",allids);
        }
        // if (e.target.name != "" && e.target.name != undefined && e.target.checked == true) {
        //   props.setFieldValues("personal_id", e.target.name);
        // } else {
        //   props.setFieldValues("personal_id", "");
        // }
      });

      $("#resolution tbody").on("click", ".details-control", async function () {
        let data;
        (await props.shareResolutionList.length != 0) &&
          props.shareResolutionList.map((val, idx) => {
            val.list.map((key) => {
              if (key.id == this.title) {
                data = key;
              }
            });
          });
        var tr = $(this).closest("tr");

        // document.getElementById(this.title).appendChild($.parseHTML(format(data)))

        if (!tr.hasClass("shown") && !$(`#detail-view-${this.title}`).length) {
          console.log("remove");
          if (data && Object.keys(data).length > 0) {
            console.log("hi");
            tr.after(format(data));
            tr.addClass("shown");
          }
        } else if (
          !tr.hasClass("shown") &&
          $(`#detail-view-${this.title}`).length
        ) {
          tr.addClass("shown");
          $(`#detail-view-${this.title}`).css("display", "");
        } else {
          console.log("else");
          tr.removeClass("shown");
          $(`#detail-view-${this.title}`).css("display", "none");
          // $(`#detail-view-${this.title}`).removeClass();
        }
      });
    });
  }, [props.shareResolutionList]);

  return (
    <Fragment>
      <div id="share-resolution-tab" className="tab-pane fade active in">
        <h3 />
        <p></p>
        <div className="box box-primary box_align_left" boxHeight>
          <div className="box-header">
            <div>
              <nav className="navbar navbar-default" role="navigation">
                <div className="container-fluid" id="navfluid">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-toggle="collapse"
                      data-target="#navigationbar"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <a className="navbar-brand" href="#" />
                  </div>
                  <div className="collapse navbar-collapse" id="navigationbar">
                    <ul className="nav navbar-nav">
                      <ul className="nav navbar-nav">
                        <li>
                          <a
                            href="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Approval
                            <span className="caret" />
                          </a>
                          <ul className="dropdown-menu">
                            {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(29) ? (
                              <li>
                                <a
                                  className="button-approval"
                                  onClick={() => {
                                    if (props.shareId !== "") {
                                      props.sendValues("approveShareModal", true);
                                    } else {
                                      props.setFieldValues(
                                        "nothing_select",
                                        true
                                      );
                                    }
                                  }}
                                >
                                  Approve
                              </a>
                              </li>
                            ) : null}
                          </ul>
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </nav>
              <div className="box-tools pull-right" />
            </div>
            <div />
          </div>
          <div className="box-body" boxHeight>
            <div className="table-responsive">
              <div
                id="share-action_wrapper"
                className="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
              
                
                      <table
                        className="table table-striped table-bordered col-md-12 dataTable no-footer"
                        role="grid"
                        style={{ marginLeft: 0,padding:0 }}
                        id="resolution"
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="details-control col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "56px" }}
                              aria-label="Details"
                            >
                              Details
                            </th>
                            <th
                              className="select-checkbox col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "55px" }}
                              aria-label="Select"
                            >
                              Select
                            </th>
                            <th
                              className="reorder rowReorder col-md-1 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="share-action"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "56px" }}
                              aria-label="Action: activate to sort column ascending"
                            >
                              Action
                            </th>
                            <th
                              className="col-xs-2 col-md-3 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="share-action"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "97px" }}
                              aria-label="From: activate to sort column ascending"
                            >
                              From
                            </th>
                            <th
                              className="col-xs-2 col-md-3 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="share-action"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "56px" }}
                              aria-label="To: activate to sort column ascending"
                            >
                              To
                            </th>
                            <th
                              className="col-xs-2 col-md-2 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="share-action"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "63px" }}
                              aria-label="From Desc: activate to sort column ascending"
                            >
                              From Desc
                            </th>
                            <th
                              className="col-xs-2 col-md-2 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="share-action"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "56px" }}
                              aria-label="To Desc: activate to sort column ascending"
                            >
                              To Desc
                            </th>
                            <th
                              className="col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "56px" }}
                              aria-label="Sh. Held"
                            >
                              Sh. Held
                            </th>
                            <th
                              className="col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "56px" }}
                              aria-label="Value $"
                            >
                              Value $
                            </th>
                            <th
                              className="col-xs-2 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "57px" }}
                              aria-label="Actions"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    
                <div
                  id="share-action_processing"
                  className="dataTables_processing panel panel-default"
                  style={{ display: props.processModal }}
                >
                  Processing...
                </div>
              </div>
            </div>
          </div>
          <div className="box-footer clearfix no-border"></div>
        </div>
        <p />
      </div>
      <Edit />
      <Nothing />
      <ApproveShare />
      <DeleteResolution />
      <Attachment />
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    shareResolutionList,
    shareHolderList,
    // ShareTypeList,
    shareId,
  } = state.ShareReg;
  return {
    shareResolutionList,
    shareHolderList,
    ShareTypeList: state.Settings.List,
    shareId,
    deleteModal: state.Deadline.deleteModal,
    processModal:state.Client.processModal,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeClientInfo: (field, value) =>
    dispatch(actionsnew.changeClientInfo(field, value)),
    getShareResolutionlist: () => dispatch(actions.getShareResolutionlist()),
    setDeadlineValues: (k, v) => dispatch(deadline.deadline(k, v)),
    sendValues: (k, v) => dispatch(actions.share_register(k, v)),
    setFieldValues: (f, v) => dispatch(personalActions.personal_data(f, v)),
    getShareTypeList: () => dispatch(actions.getShareTypeList()),
    getShareHolderList: (data) => dispatch(actions.getShareHolderList(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Resolution);
