import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/Client";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

function EditCompany(props) {
  useEffect(() => {
    props.reset_client_info();
    props.getCurrencyList();
    props.getClientStatus(1);
    if (props.EditClientStatus === 1) {
      setTimeout(function () {
        props.setFieldValues("EditClientStatus", 0);
        props.setFieldValues("company_modal_show", false);
        props.reset_client_info();
      }, 2000);
    }
  }, [props.EditClientStatus]);




  const UpdateClient = (event) => {
    event.preventDefault();
    let document_date = '';
    if (props.document_date != '') {
      document_date = moment(props.document_date).format('YYYY-MM-DD')
    }
    if (validClientInfo()) {
      var userData = JSON.parse(localStorage.getItem("userData"));
      props.editClient({
        company_id: userData.company_id,
        company_name: props.company_name,
        registration_no: props.registration_no,
        incorporation_date: moment(props.incorporation_date).format(
          "YYYY-MM-DD"
        ),
        currency: props.currency,
        address: props.address,
        business_address: props.business_address,
        referral: props.referral,
        remarks: props.remarks,
        flag: props.flag,
        document_id: props.document_id,
        attachment: props.attachment,
        attachment_keyword: props.attachment_keyword,
        document_link: props.document_link,
        document_type: props.document_type,
        document_date: document_date,
        notes: props.notes,
        description: props.description,
        client_company_id: props.company_id,
        status: props.clientstatus,
      });
    }
  };

  const validClientInfo = () => {
    let valid = true;
    let errors = props.addClientErrors;
    if (!props.company_name) {
      valid = false;
      errors.company_name = "Cannot be Empty";
    } else {
      errors.company_name = "";
    }
    if (!props.registration_no) {
      valid = false;
      errors.registration_no = "Cannot be Empty";
    } else {
      errors.registration_no = "";
    }
    if (!props.incorporation_date) {
      valid = false;
      errors.incorporation_date = "Cannot be Empty";
    } else {
      errors.incorporation_date = "";
    }
    if (!props.address) {
      valid = false;
      errors.address = "Cannot be Empty";
    } else {
      errors.address = "";
    }
    // if (!props.attachment) {
    //   valid = false;
    //   errors.attachment = "Cannot be Empty";
    // } else {
    //   errors.attachment = "";
    // }
    props.updateClientErrors(errors);
    return valid;
  };

  useEffect(() => {
    console.log("Modal visibility updated:", props.show);
  }, [props.show]);
  

  const closeModal = () => {
    console.log("Modal Close Triggered");
    console.log("Modal show prop:", props.show);
    props.setFieldValues("company_edit_modal_show", false);
    props.setFieldValues("company_id", "");
    props.reset_client_info();


  };
  
  

  return (
    <Modal
    key={props.show ? 'modal-open' : 'modal-closed'} 
  show={props.show}
  className="modal bootstrap-dialog type-primary size-normal"
  role="dialog"
  aria-hidden="true"
  id="newclient"
  aria-labelledby="newclient"
  tabIndex={-1}
  onHide={closeModal}



  // onHide={() => {
  //   console.log('onHide: ');
  //   props.setFieldValues("company_edit_modal_show", false);
  //   props.setFieldValues("company_id", '');
  // }}
>

      <div className="modal-content">
        <div className="modal-header bootstrap-dialog-draggable">
          <div className="bootstrap-dialog-header">
            <div
              className="bootstrap-dialog-close-button"
              style={{ display: "none" }}
            >
              <button className="close" aria-label="close">
                ×
              </button>
            </div>
            <div
              className="bootstrap-dialog-title"
              id="6515262a-6027-4e02-a065-90e1c9e04a75_title"
            >
              Edit Client
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="bootstrap-dialog-body">
            <div className="bootstrap-dialog-message">
              <div>
                <form
                  method="POST"
                  action="https://crm2.gbsc.geneasol-apps.info/client/clients?"
                  acceptCharset="UTF-8"
                  encType="multipart/form-data"
                  className="form-horizontal"
                  id="form"
                >
                  <input
                    name="_token"
                    type="hidden"
                    defaultValue="wQUZACCGN0G9CyyGsQ55geMLv6LTTQDh3sskflCL"
                  />
                  <div className="form-group">
                    <label
                      htmlFor="companyname"
                      className="control-label col-sm-4 required"
                    >
                      Company Name
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        required="required"
                        name="companyname"
                        type="text"
                        id="companyname"
                        value={props.company_name}
                        onChange={(e) =>
                          props.setFieldValues("company_name", e.target.value)
                        }
                      />
                      {props.addClientErrors.company_name &&
                        props.addClientErrors.company_name.length > 0 ? (
                        <span className="text-danger">
                          {props.addClientErrors.company_name}
                        </span>
                      ) : (
                        ""
                      )}
                      <p className="help-block">* Required </p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="registrationno"
                      className="control-label col-sm-4 required"
                    >
                      Registration No / UEN
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        required="required"
                        name="registrationno"
                        type="text"
                        id="registrationno"
                        value={props.registration_no}
                        onChange={(e) =>
                          props.setFieldValues(
                            "registration_no",
                            e.target.value
                          )
                        }
                      />
                      {props.addClientErrors.registration_no &&
                        props.addClientErrors.registration_no.length > 0 ? (
                        <span className="text-danger">
                          {props.addClientErrors.registration_no}
                        </span>
                      ) : (
                        ""
                      )}
                      <p className="help-block">* Required </p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="incorporationdate"
                      className="control-label col-sm-4 required"
                    >
                      Incorporation Date
                    </label>
                    <div className="col-sm-8">
                      <DatePicker
                        showPopperArrow={false}
                        dateFormat={"dd/MM/yyyy"}
                        selected={props.incorporation_date}
                        locale={"en"}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: "0px, 0px",
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: "viewport",
                          },
                        }}
                        onChange={(Date) => {
                          console.log("e", moment(Date).format("DD/MM/YYYY"));
                          props.setFieldValues("incorporation_date", Date);
                        }}
                      >
                        <div
                          class="trash-icon"
                          onClick={() =>
                            props.setFieldValues("incorporation_date", "")
                          }
                        >
                          <span class="glyphicon glyphicon-trash"></span>{" "}
                        </div>
                      </DatePicker>
                      {props.addClientErrors.incorporation_date &&
                        props.addClientErrors.incorporation_date.length > 0 ? (
                        <span className="text-danger">
                          {props.addClientErrors.incorporation_date}
                        </span>
                      ) : (
                        ""
                      )}
                      <p className="help-block">* Required </p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="currency_id"
                      className="control-label col-sm-4 required"
                    >
                      Currency
                    </label>
                    <div className="col-sm-8">
                      <select
                        className="form-control"
                        required="required"
                        id="currency_id"
                        name="currency_id"
                        value={props.currency}
                        onChange={(e) =>
                          props.setFieldValues("currency", e.target.value)
                        }
                      >
                        <option value="">Select Currency</option>
                        {props.currencyList.map((cur) => {
                          return <option value={cur.id}>{cur.Name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="currency_id"
                      className="control-label col-sm-4 required"
                    >
                      Client Status
                    </label>
                    <div className="col-sm-8">
                      <select
                        className="form-control"
                        required="required"
                        id="currency_id"
                        name="currency_id"
                        value={props.clientstatus}
                        onChange={(e) =>
                          props.setFieldValues("clientstatus", e.target.value)
                        }
                      >
                        <option value="">Select Client status</option>
                        {props.List1.map((cur) => {
                          return <option value={cur.id}>{cur.Name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="address"
                      className="control-label col-sm-4 required"
                    >
                      Registered Address
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        rows={3}
                        required="required"
                        name="address"
                        cols={50}
                        id="address"
                        value={props.address}
                        onChange={(e) =>
                          props.setFieldValues("address", e.target.value)
                        }
                      />
                      {props.addClientErrors.address &&
                        props.addClientErrors.address.length > 0 ? (
                        <span className="text-danger">
                          {props.addClientErrors.address}
                        </span>
                      ) : (
                        ""
                      )}
                      <p className="help-block">* Required </p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="business_address"
                      className="control-label col-sm-4 required"
                    >
                      Business Address
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        rows={3}
                        required="required"
                        name="business_address"
                        cols={50}
                        id="business_address"
                        value={props.business_address}
                        onChange={(e) =>
                          props.setFieldValues("business_address", e.target.value)
                        }
                      />
                      {props.addClientErrors.business_address &&
                        props.addClientErrors.business_address.length > 0 ? (
                        <span className="text-danger">
                          {props.addClientErrors.business_address}
                        </span>
                      ) : (
                        ""
                      )}
                      <p className="help-block">* Required </p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="referral"
                      className="control-label col-sm-4"
                    >
                      Referral
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="referral"
                        type="text"
                        id="referral"
                        value={props.referral}
                        onChange={(e) =>
                          props.setFieldValues("referral", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="remarks" className="control-label col-sm-4">
                      Remarks
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        rows={3}
                        name="remarks"
                        cols={50}
                        id="remarks"
                        value={props.remarks}
                        onChange={(e) =>
                          props.setFieldValues("remarks", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="flag_id"
                      className="control-label col-sm-4 required"
                    >
                      Flag
                    </label>
                    <div className="col-sm-8">
                      <select
                        className="form-control"
                        required="required"
                        id="flag_id"
                        name="flag_id"
                        value={props.flag}
                        onChange={(e) =>
                          props.setFieldValues("flag", e.target.value)
                        }
                      >
                        <option value={0}>No flag</option>
                        <option value={1}>Good</option>
                        <option value={2}>Bad</option>
                        <option value={3}>Terrible</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="document_link"
                      className="control-label col-sm-4"
                    >
                      Document Link
                    </label>
                    <div className="col-sm-8">
                      <select
                        className="form-control"
                        id="document_link"
                        name="document_link"
                        value={props.document_link}
                        onChange={(e) =>
                          props.setFieldValues("document_link", e.target.value)
                        }
                      >
                        <option value={0} selected="selected">
                        View from the drop-down list / Select New Doc to upload new
                        </option>
                        <option value={1}>New Document</option>
                      </select>
                    </div>
                  </div>
                  {props.document_link == 1 ? (
                    <div>
                      <div className="form-group">
                        <label
                          htmlFor="document_id"
                          className="control-label col-sm-4"
                        >
                          Document ID
                        </label>
                        <div className="col-sm-8">
                          <input
                            className="form-control"
                            name="document_id"
                            type="text"
                            id="document_id"
                            value={props.document_id}
                            onChange={(e) =>
                              props.setFieldValues(
                                "document_id",
                                e.target.value
                              )
                            }
                          />
                          <p className="help-block">
                            Must be unique ID with this client{" "}
                          </p>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="original_filename"
                          className="control-label col-sm-4"
                        >
                          Attachment
                        </label>
                        <div className="col-sm-8">
                          <input
                            className="form-control"
                            accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                            name="original_filename"
                            type="file"
                            id="original_filename"
                            onChange={(e) => {
                              if (1) {
                                props.setFieldValues(
                                  "attachment",
                                  e.target.files[0]
                                );
                              }
                            }}
                          />
                          {props.addClientErrors.attachment &&
                            props.addClientErrors.attachment.length > 0 ? (
                            <span className="text-danger">
                              {props.addClientErrors.attachment}
                            </span>
                          ) : (
                            ""
                          )}

                          {/* maxLength={4096}
                          else{
                              props.setFieldValues("attachment", '');
                              e.target.value = '';
                              props.setFieldValues("clientApiStatus", 2);
                              props.setFieldValues("message", "File size limit only 2Mb");
                              setTimeout(() => {
                                props.setFieldValues("clientApiStatus", 0);
                                props.setFieldValues("message", "");
                              }, 2500);
                            }
                          <p className="help-block">
                            * Uploading of file limited to 2MB only.{" "}
                          </p> */}
                        </div>
                      </div>
                      <div className="form-group">
                    <label
                      htmlFor="document_tag"
                      className="control-label col-sm-4"
                    >
                      Description
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        value={props.description}
                        rows={3}
                        name="document_tag"
                        cols={50}
                        id="document_tag"
                        onChange={(e) =>
                          props.setFieldValues("description", e.target.value)
                        }
                      />
                    </div>
                  </div>
                      <div className="form-group">
                        <label
                          htmlFor="keywords"
                          className="control-label col-sm-4"
                        >
                          Attachment Keyword for Searching Purposes
                        </label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control"
                            rows={3}
                            name="keywords"
                            cols={50}
                            id="keywords"
                            value={props.attachment_keyword}
                            onChange={(e) =>
                              props.setFieldValues(
                                "attachment_keyword",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="document_type_id"
                          className="control-label col-sm-4"
                        >
                          Document Type
                        </label>
                        <div className="col-sm-8">
                          <select
                            className="form-control"
                            id="document_type_id"
                            name="document_type_id"
                            value={props.document_type}
                            onChange={(e) =>
                              props.setFieldValues(
                                "document_type",
                                e.target.value
                              )
                            }
                          >
                            <option value='' >
                              === Select ===
                            </option>
                            {props.DocumentTypeList.map((val) => {
                              return (
                                <option value={val.id}>{val.Name}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="document_date"
                          className="control-label col-sm-4"
                        >
                          Resolution/Document Date
                        </label>
                        <div className="col-sm-8">
                          <DatePicker
                            showPopperArrow={false}
                            dateFormat={"dd/MM/yyyy"}
                            selected={props.document_date}
                            locale={"en"}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: "0px, 0px",
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                              },
                            }}
                            onChange={(Date) => {
                              console.log(
                                "e",
                                moment(Date).format("DD/MM/YYYY")
                              );
                              props.setFieldValues("document_date", Date);
                            }}
                          >
                            <div
                              className="trash-icon"
                              onClick={() =>
                                props.setFieldValues("document_date", "")
                              }
                            >
                              <span class="glyphicon glyphicon-trash"></span>
                            </div>
                          </DatePicker>
                          {/* <input
                        className="form-control datetimepicker"
                        style={{ backgroundColor: "#fff" }}
                        autoComplete="off"
                        name="document_date"
                        type="text"
                        id="document_date"
                        value={props.document_date}
                        onChange={(e) =>
                          props.setFieldValues("document_date", e.target.value)
                        }
                      /> */}
                          <p className="help-block">DD/MM/YYYY </p>
                        </div>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="document_tag"
                          className="control-label col-sm-4"
                        >
                          Notes
                        </label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control"
                            rows={3}
                            name="document_tag"
                            cols={50}
                            id="document_tag"
                            value={props.notes}
                            onChange={(e) =>
                              props.setFieldValues("notes", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      name="client_id_doc"
                      type="hidden"
                    />
                  </div>
                  <div className="col-sm-12" >
                    {props.clientApiStatus ? (
                      <div
                        className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                          }`}
                        role="alert"
                      >
                        {props.clientApiStatus === 1 ? '' : props.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    className="btn form-control btn-success"
                    id="addnew"
                    value="new"
                    name="action"
                    type="button"
                    onClick={(e) => {
                      // props.changeCompanyInfo("addCompanyStatus", 1);
                      UpdateClient(e);
                    }}
                  >
                    <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="bootstrap-dialog-footer">
            <div className="bootstrap-dialog-footer-buttons">
              {/* <button
                onClick={() => {
                  props.setFieldValues("company_edit_modal_show", false);
                  props.setFieldValues("document_link", 0);
                  props.setFieldValues("company_id", '');
                }}
                className="btn form-control"
                id="f86f3f7f-ac8a-417d-9cba-2410e5baee15"
                data-dismiss="modal"
              >
                <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                Close
              </button> */}


<button onClick={closeModal} className="btn form-control">
  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
  Close
</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    clientstatus: state.Client.clientstatus,
    show: state.Client.company_edit_modal_show,
    company_name: state.Client.company_name,
    registration_no: state.Client.registration_no,
    incorporation_date: state.Client.incorporation_date,
    currency: state.Client.currency,
    address: state.Client.address,
    business_address: state.Client.business_address,
    referral: state.Client.referral,
    remarks: state.Client.remarks,
    flag: state.Client.flag,
    document_id: state.Client.document_id,
    attachment: state.Client.attachment,
    attachment_keyword: state.Client.attachment_keyword,
    document_type: state.Client.document_type,
    document_date: state.Client.document_date,
    notes: state.Client.notes,
    description: state.Client.description,
    addClientErrors: state.Client.addClientErrors,
    addClientStatus: state.Client.addClientStatus,
    document_link: state.Client.document_link,
    company_id: state.Client.company_id,
    EditClientStatus: state.Client.EditClientStatus,
    currencyList: state.Client.currencyList,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    List1: state.Client.List1,
    DocumentTypeList: state.Settings.DocumentTypeList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {



  
  return {
    setFieldValues: (f, v) => dispatch(actions.changeClientInfo(f, v)),
    editClient: (data) => dispatch(actions.editClient(data)),
    updateClientErrors: (data) => dispatch(actions.updateClientErrors(data)),
    reset_client_info: () => dispatch(actions.reset_client_info()),
    getCurrencyList: () => dispatch(actions.getCurrencyList()),
    getClientStatus: () => dispatch(actions.getClientStatus()),
  };
};




export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
