import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import * as actions from "../../../actions/clientDetails/Personal";
import * as client from "../../../actions/Client";
import AddPersonal from "../modals/AddNewPersonal";
import EditPersonal from "../modals/EditPersonal";
import DeletePersonal from "../modals/DeletePersonal";
import Approve from "../modals/ConfirmationApprove";
import Nothing from "../modals/NothingSelected";
import Attachment from "../modals/AttachmentModal";
import moment from "moment";

function Personal(props) {
  var table;
  useEffect(() => {
    props.setFieldValues("officeBearer", false);
    window.$(document).ready(function () {
      window.$(".dropdown-toggle").dropdown();

      //console.log("template tab");
      window.$('a[data-toggle="pill"]').on("show.bs.tab", function (e) {
        localStorage.setItem("activeTab", $(e.target).attr("href"));
        console.log("selected tab :" + $(e.target).attr("href"));
      });

      var activeTab = localStorage.getItem("activeTab");

      if (activeTab) {
        window.$('#myTab a[href="' + activeTab + '"]').tab("show");
      }
    });

    props.getPersonalList(sessionStorage.getItem("personalId"));

    if (sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(20)) {
      setEdit(false)
    };

    if (sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(21)) {
      setDelet(false)
    };

    return () => {
      table && table.destroy(true);
    };
  }, []);

  const [edit, setEdit] = useState("none");
  const [delet, setDelet] = useState("none");


  useEffect(() => {
    function format(d) {
      console.log("did", d);
      return (
        // cellpadding="5" style="padding-left:50px;width:100%"
        '<table  cellpadding="8" border="0" style="width:100%;background-color:white;" >' +
        "<tr>" +
        "<td>Address :" +
        '<span style="margin-left:5px">' + d.address + '</span>' + "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>Email :" + '<span style="margin-left:5px">' + d.email + '</span>' + "</td>" +
        "</tr>" +
        "<tr>" +
        "<td>Mobile :" + '<span style="margin-left:5px">' + d.phone + '</span>' + "</td>" +
        "</tr>" +
        "</table>"
      );
    }

    $(document).ready(function () {
      table = $("#personal").DataTable({
        pageLength:25,
        lengthMenu: [ 25, 50 ],
        paging: true,
        destroy: true,
        data: props.personalList,
        columnDefs: [
          {
            targets: "_all",
            defaultContent: "",
            // "targets": 12,
          },
          {
            targets: 0,
            orderable: false,
            searchable: false,
            className: "details-control col-md-1 col-lg-1",
          },
          {
            targets: 1,
            orderable: false,
            searchable: false,
            className: " btnbox col-md-1 col-lg-1 sorting_1",
            render: function (data, type, row) {
              // if (row.check == true) {
              //   return `<input class="check" id=check${row.id}  name=""  checked=${row.check} type="checkbox" />`;
              // } else {
              return `<input class="check" id="check${row.id}"  name=${row.id} title=${row.id}    type="checkbox" />`;
              // }
            },
          },
          {
            targets: 2,
            orderable: true,
            className: "col-md-4 col-lg-4",
            data: "name",
            render: function (data, type, row) {
              if(row.document_link != '' && row.document_link != null && row.document_link != undefined){
              return `<span>${data}</span><a><span class="document glyphicon glyphicon-paperclip" id=${row.document_link}  /></a>`}
              else {
                if(row.docids != '' && row.docids != null && row.docids != undefined){
                  return `<span>${data}</span><a><span class="document glyphicon glyphicon-paperclip" id=${row.docids}  /></a>`}
                  else{
                    return `<span>${data}</span>`
                  }
                
                
              }
            },
          },
          {
            targets: 3,
            orderable: true,
            className: "col-md-1 col-lg-1",
            data: "identification_no",
          },
          {
            targets: 4,
            orderable: true,
            className: "col-md-1 col-lg-2",
            data: "designation_id",
            render: function (data, type, row) {
              console.log('data:----------------> ', data);
              console.log('props.designationList: ', props.designationList);


              if(props.designationList && data ){
                let activeName = props.designationList.filter(


                  (item) => item.id == data
                );
                // if (activeName.length > 0) {
                  return `<span>${activeName[0].Name}</span>`;
                // } else {
                //   return data;
                // }
              }else{
                return "";
              }

             
            },
          },
          {
            targets: 5,
            orderable: true,
            className: "col-md-2 col-lg-1",
            data: "apponitment_date",
            render: function (data, type, row) {
              if (moment(data).format("DD/MM/YYYY") != "Invalid date") {
                return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
              } else {
                return '<span style="margin-left: 30px;">---</span>';
              }
            },
          },{
            targets: 6,
            orderable: true,
            className: "col-md-2 col-lg-1",
            data: "acra_apponitment_date",
            render: function (data, type, row) {
              if (moment(data).format("DD/MM/YYYY") != "Invalid date") {
                return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
              } else {
                return '<span style="margin-left: 30px;">---</span>';
              }
            },
          },{
            targets: 7,
            orderable: true,
            className: "col-md-2 col-lg-1",
            data: "acra_resignation_date",
            render: function (data, type, row) {
              if (moment(data).format("DD/MM/YYYY") != "Invalid date") {
                return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
              } else {
                return '<span style="margin-left: 30px;">---</span>';
              }
            },
          },
          {
            targets: 8,
            orderable: true,
            className: "col-md-1 col-lg-1",
            data: "resignation_date",
            render: function (data, type, row) {
              if (moment(data).format("DD/MM/YYYY") != "Invalid date") {
                return `<span>${moment(data).format("DD/MM/YYYY")}</span>`;
              } else {
                return '<span style="margin-left: 30px;">---</span>';
              }
            },
          },
          {
            targets: 9,
            orderable: true,
            className: "col-md-1 col-lg-1",
            data: "id",
            render: function (data, type, row) {

              return `<button class="btnEdit btn btn-primary btn-xs" style="display:${edit}" id=edit${data} title="Edit" name=${data} onClick="console.log('Hello',title)" ><span class="glyphicon glyphicon-pencil" /></button>
              <button class="btnDel btn btn-primary btn-xs" style="display:${delet}" id=delete${data} title="Delete" name=${data}  data-toggle="modal" data-target="#deletemodal""><span class="glyphicon glyphicon-trash" /></button>`;

            },
          },
          {
            targets: 10,
            orderable: true,
            className: "col-xs-1 col-sm-1 col-md-1 col-lg-1",
            data: "attachment_keyword",
            visible: false
          }
        ],
        rowCallback: function (row, data, index) {
          if (!data.is_approved) {
            $(row).css("background-color", "#f44242");
          }
        },
      });
      $("#personal tbody").on("click", ".btnEdit", function () {
        var data = table.row(this).data();
        props.personalList &&
          props.personalList.map((val, idx) => {
            if (val.id == this.name) {
              props.setFieldValues("idNo", val.identification_no);
              props.setFieldValues("idType", val.identification_type_id);
              if (new Date(val.id_date_of_issue) != "Invalid Date") {
                props.setFieldValues(
                  "dateIssue",
                  new Date(val.id_date_of_issue)
                );
              }
              if (new Date(val.id_date_of_expiry) != "Invalid Date") {
                props.setFieldValues(
                  "dateExpiry",
                  new Date(val.id_date_of_expiry)
                );
              }
              props.setFieldValues("designation", val.designation_id);
              props.setFieldValues("salutation", val.salutation_id);
              props.setFieldValues("name", val.name);
              props.setFieldValues("address", val.address);
              props.setFieldValues("nationality", val.nationality_id);
              if (new Date(val.apponitment_date) != "Invalid Date") {
                props.setFieldValues(
                  "dateAppointment",
                  new Date(val.apponitment_date)
                );
              }
              if (new Date(val.resignation_date) != "Invalid Date") {
                props.setFieldValues(
                  "dateResignation",
                  new Date(val.resignation_date)
                );
              }
              if (new Date(val.acra_apponitment_date) != "Invalid Date") {
                props.setFieldValues("appointmentAcradate", new Date(val.acra_apponitment_date));
              }
              if (new Date(val.acra_resignation_date) != "Invalid Date") {
                props.setFieldValues("resignationAcradate", new Date(val.acra_resignation_date));
              }
              
              if (new Date(val.date_of_birth) != "Invalid Date") {
                props.setFieldValues("dateBirth", new Date(val.date_of_birth));
              }
              if(val.person_copied_status == 1){
              props.setFieldValues("copiedStatus", true);
              } else{
                props.setFieldValues("copiedStatus", false);
              }
              props.setFieldValues("email", val.email);
              props.setFieldValues("status", val.status);
              props.setFieldValues("phone", val.phone);
              props.setFieldValues("flag", val.flag);
              props.setFieldValues("flagRemark", val.flag_remarks);
              // props.setFieldValues("attachment", val.attachment);
              props.setFieldValues("documentId", val.document_id);
              props.setFieldValues("documentKey", val.attachment_keyword);
              props.setFieldValues("documentType", val.document_type);
              if (new Date(val.document_date) != "Invalid Date") {
                props.setFieldValues(
                  "documentDate",
                  new Date(val.document_date)
                );
              }
              props.setFieldValues("note", val.notes);
              props.setFieldValues("documentUpload", val.document_upload);
              props.setFieldValues("documentLink", val.document_link);
              props.setFieldValues("personal_id", val.id);
              props.setFieldValues("editPersonal", true);
              props.setFieldValues("copiedStatus_value", val.person_login_status == 1 ? true : false);
            }
          });
      });

      $("#personal tbody").on("click", ".document", function (e) {
        var targetid=e.target.id;
        props.setValue("client_key", targetid);
        props.setValue("attachmentModal", true)

      });

      $("#personal tbody").on("click", ".btnDel", function () {
        var data = table.row(this).data();

        props.setFieldValues("personal_id", this.name);
        props.setFieldValues("deletePersonal", true);
      });
      var ids=[... props.personalList];
      
      $("#personal tbody").on("click", ".btnbox", function (e) {
        var data = table.row(this).data();
        let arr = [];
       
        var allids="";
        props.personalList && props.personalList.map((key,index) => {
         
          var checkid="check"+key.id;
          if(document.getElementById(checkid) != null){
            var checkstatus=document.getElementById(checkid).checked;
            key.check =checkstatus;
            if(checkstatus == true){
              allids += allids + key.id+",";
            }
          }
          

          // if (key.id == e.target.title) {
          //   key.check = e.target.checked
          // } else {
          //   key.check = false
          // }
        });
        console.log("allids",allids)
        if(allids == ""){
          props.setFieldValues("personal_id","");
        }else{
          allids = allids.slice(0, -1);
          props.setFieldValues("personal_id",allids);
        }
        // arr[table.row(this).index()].check = e.target.checked;
        //props.setFieldValues("personalList", props.personalList);
       
        // if (e.target.name != "" && e.target.name != undefined && e.target.checked == true) {
        //   props.setFieldValues("personal_id", e.target.name);
        // } else {
        //   props.setFieldValues("personal_id","");
        // }
        console.log("nature",ids)
      });
      
      // Add event listener for opening and closing details
      table.on("click", "td.details-control", function () {
        var tr = $(this).closest("tr");
        var row = table.row(tr);
        if (row.data()) {
          if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass("shown");
            $("tr.shown td.details-control").toggleClass(
              "tr.shown td.details-control"
            );
          } else {
            // Open this row
            row.child(format(row.data())).show();
            tr.addClass("shown");
          }
        }
      });
    });
  }, [props.personalList]);

  return (
    <Fragment>
      <div id="office-bearer-tab" className="tab-pane fade active in">
        <h3 />
        <p></p>
        <div className="box box-primary box_align_left" boxHeight>
          <div className="box-header">
            <div>
              <nav className="navbar navbar-default" role="navigation">
                <div className="container-fluid" id="navfluid">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-toggle="collapse"
                      data-target="#navigationbar"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                    <a className="navbar-brand" href="#" />
                  </div>
                  <div className="collapse navbar-collapse" id="navigationbar">
                    <ul className="nav navbar-nav">
                      <ul className="nav navbar-nav">
                        <li >
                          <a
                            href="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Personal
                            <span className="caret" />
                          </a>
                          <ul className="dropdown-menu">
                            {
                              sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(19) ? (
                                <li>
                                  <a
                                    onClick={() =>
                                      props.setFieldValues("addPersonal", true)
                                    }
                                    role="menuitem"
                                    tabIndex={-1}
                                  // data-toggle="modal" data-target="#addpersonnel"
                                  >
                                    Add
                              </a>
                                </li>
                              ) : null}
                            {sessionStorage.getItem("permissions") && sessionStorage.getItem("permissions").includes(22) ? (
                              <li>
                                <a
                                  className="button-approval"
                                  // href="javascript:void()"
                                  data-title="Approve Selection"
                                  data-table="office-bearer"
                                  data-selection="Approve"
                                  onClick={() => {
                                    if (props.personal_id !== "") {
                                      props.setFieldValues("approve_modal", true);
                                    } else {
                                      props.setFieldValues(
                                        "nothing_select",
                                        true
                                      );
                                    }
                                  }}
                                >
                                  Approve
                              </a>
                              </li>
                            ) : null}
                          </ul>
                        </li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </nav>
              <div className="box-tools pull-right" />
            </div>
            <div />
          </div>
          <div className="box-body" boxHeight>
            <div className="table-responsive">
              <div
                id="office-bearer_wrapper"
                className="dataTables_wrapper form-inline dt-bootstrap no-footer"
              >
                
                      <table
                        id="personal"
                        className="table table-striped table-bordered col-lg-12 col-md-12 col-sm-12 col-xs-12 dataTable no-footer"
                        role="grid"
                        style={{ marginLeft: 0,padding:0 }}
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "42px" }}
                              aria-label="Details"
                            >
                              Details
                            </th>
                            <th
                              className="select-checkbox hidden-xs hidden-sm col-md-1 col-lg-1 sorting_asc"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "43px" }}
                              aria-label="Select"
                            >
                              Select
                            </th>
                            <th
                              className="col-md-4 col-lg-4 sorting"
                              tabIndex={0}
                              aria-controls="office-bearer"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "270px" }}
                              aria-label="Name: activate to sort column ascending"
                            >
                              Name
                            </th>
                            <th
                              className="col-md-1 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="office-bearer"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "44px" }}
                              aria-label="ID: activate to sort column ascending"
                            >
                              ID
                            </th>
                            <th
                              className="col-md-1 col-lg-2 sorting"
                              tabIndex={0}
                              aria-controls="office-bearer"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "121px" }}
                              aria-label="Designation: activate to sort column ascending"
                            >
                              Designation
                            </th>
                            <th
                              className="col-md-2 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="office-bearer"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "64px" }}
                              aria-label="Appointment: activate to sort column ascending"
                            >
                              Appointment
                            </th>
                            <th
                              className="col-md-2 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="office-bearer"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "64px" }}
                              aria-label="Appointment: activate to sort column ascending"
                            >
                              Appointment Acra Filed Date
                            </th>
                            <th
                              className="col-md-2 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="office-bearer"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "64px" }}
                              aria-label="Appointment: activate to sort column ascending"
                            >
                             Resignation Acra Filed Date  
                            </th>
                            <th
                              className="col-md-1 col-lg-1 sorting"
                              tabIndex={0}
                              aria-controls="office-bearer"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "57px" }}
                              aria-label="Resignation: activate to sort column ascending"
                            >
                              Resignation
                            </th>
                            <th
                              className="col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "45px" }}
                              aria-label="Actions"
                            >
                              Actions
                            </th>
                            <th
                              className="col-xs-1 col-sm-1 col-md-1 col-lg-1 sorting_disabled"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: 40 }}
                              aria-label="Status"
                            >
                              Attachment Keyword
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                         
                        </tbody>
                      </table>
                   
                <div id="client-reminder_processing" className="dataTables_processing panel panel-default" style={{ display: props.processModal }}>Processing...</div>
              </div>
            </div>
          </div>
          <div className="box-footer clearfix no-border"></div>
        </div>
        <p />
      </div>

      <AddPersonal />
      <EditPersonal />
      <DeletePersonal />
      <Nothing />
      <Approve />
      <Attachment />
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    personalList: state.Personal.personalList,
    designationList: state.Personal.designationList,
    personal_id: state.Personal.personal_id,
    personalApiStatus: state.Personal.personalApiStatus,
    message: state.Personal.message,
    processModal:state.Client.processModal,
    singlePersonList:state.Client.singlePersonList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPersonalList: (data) => dispatch(actions.getPersonalList(data)),
    setFieldValues: (f, v) => dispatch(actions.personal_data(f, v)),
    setValue: (f, v) => dispatch(client.changeClientInfo(f, v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Personal);
