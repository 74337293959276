import React, { Fragment, useEffect, useState, useRef } from "react";
import Header from "../Header";
import Leftsidebar from "../LeftSideBar";
import history from "../../stores/history";
import Footer from "../Footer";
import { connect } from "react-redux";
import * as actions from "../../actions/CompanySetups";
import { signUpload_newUser } from "../../actions/clientDetails/Document";
import * as client from "../../actions/Client";
import "../administration/CompanySetups.css";
import moment from "moment";
import $, { valHooks } from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
var SignaturePad = require("react-signature-pad");
// var imageToBase64 = require('image-to-base64');
function SignDocument(props) {
  const { history, location = {} } = props;
  let padRef = useRef({});
  let fileRef = useRef({});
  const [file, setFile] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [iframelink, setIframelink] = useState("");
  const [agreeList, setAgreeList] = useState([]);
  const [image, setImage] = useState({ preview: "", raw: "" });
  useEffect(() => {
    props.documentListbyID(sessionStorage.getItem("client_key_id"));
    props.documentListbyID(sessionStorage.getItem("client_key_id"));
    props.agreeListbyID(sessionStorage.getItem("client_key_id"));

    props.signatureListByUser(sessionStorage.getItem("login_userid"));
  }, []);

  useEffect(() => {
    var arr = [];
    props.agreeListdata &&
      props.agreeListdata.map((v, i) => {
        if (sessionStorage.getItem("agree_status") == 1) {
          var obj = { id: v.agreeid, name: v.agree_name, status: true };
        } else if (sessionStorage.getItem("agree_status") == 3) {
          var obj = { id: v.agreeid, name: v.agree_name, status: false };
        } else {
          var obj = { id: v.agreeid, name: v.agree_name, status: false };
        }
        arr.push(obj);
      });
    setAgreeList(arr);
  }, [props.agreeListdata]);

  const handleChange = async (e) => {
    console.log(e.target);
    if (e.target.files.length) {
      const base64 = await convertBase64(e.target.files[0]);
      setIframelink(base64);
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const changecheckboxValue = (targerid, i) => {
    setAgreeList((prev) => {
      agreeList[i].status = document.getElementById(targerid).checked;
      return agreeList;
    });
    //console.log("agreeList",agreeList)
  };

  const update_selectpreview = async (i) => {
    if (
      props.signatureListdata[i].signature_image != "" &&
      props.signatureListdata[i].signature_image != null
    ) {
      var signimageresult =
        "https://api-gbs.smitiv.co" +
        props.signatureListdata[i].signature_image;
      setIframelink(signimageresult);
    } else {
      setIframelink("");
    }
  };
  const AddNewFunc = (e) => {
    if (iframelink == "") {
      alert("Please Preview it. before Upload");
    } else {
      let input = {
        image: iframelink,
        document_id: sessionStorage.getItem("client_key_id"),
        placeholder: placeholder,
        agreeList: agreeList,
        office_bearer_id: sessionStorage.getItem("office_bearer_ids"),
        agreestatus: sessionStorage.getItem("agree_status"),
      };
      console.log("input", input);
      props.signUpload(input);
    }
  };
  const handleClear = () => {
    padRef.current.clear();
  };
  const handleImages = (imagetype) => {
    console.log("🚀 ~ handleImages ~ imagetype:", imagetype);

    if (imagetype == 1) {
      padRef.current.clear();
      $("#drawsign").css("opacity", "1");
      $("#clearButton").css("display", "block");
      $("#signlist").css("display", "none");
    } else if (imagetype == 2) {
      $("#drawsign").css("opacity", "0");
      $("#clearButton").css("display", "none");
      $("#signlist").css("display", "block");
    } else if (imagetype == 3) {
      console.log("🚀 ~ handleImages ~ else if:");

      $("#upload-button").val("");
      $("#drawsign").css("opacity", "0");
      $("#clearButton").css("display", "none");
      $("#signlist").css("display", "none");
      fileRef.current.click();
    }
    setImage({ preview: "", raw: "" });
    setIframelink("");
    setPlaceholder(imagetype);
  };
  console.log("🚀 ~ handleImages ~ handleImages:", handleImages);
  const showpreview = () => {
    var imagelink = padRef.current.toDataURL();
    setIframelink(imagelink);
  };
  props.clientApiStatus == 6 && history.push("/pending_docs_user");
  const [edit, setEdit] = useState("none");
  const [delet, setDelet] = useState("none");

  return (
    <Fragment>
      <div className="wrapper">
        <Header />
        <Leftsidebar />
        <div className="content-wrapper" id="content-window">
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-default">
                    <div className="panel-heading">Upload Sign</div>
                    <div className="panel-body">
                      {props.clientApiStatus ? (
                        <div
                          className={`alert ${
                            props.clientApiStatus == 3 ||
                            props.clientApiStatus == 4
                              ? "alert-success"
                              : "alert-danger"
                          }`}
                          role="alert"
                        >
                          {props.message}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row">
                        <div className="col-sm-6 ">
                          <div
                            className="mt-2 text-center"
                            style={{ minHeight: "" }}
                          >
                            <iframe
                              // ref={iframeRef}
                              key={1}
                              // src ={url}
                              src={
                                "https://docs.google.com/viewer?url=" +
                                props.documentlist +
                                "&embedded=true"
                              }
                              frameborder="0"
                              width="100%"
                              id="iFrame"
                              style={{ minHeight: "inherit" }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3 ">
                          <p>
                            <button
                              className="btn btn-light same-width"
                              onClick={() => {
                                handleImages(1);
                              }}
                            >
                              Draw sign
                            </button>
                          </p>
                          <p>
                            <button
                              className="btn btn-light same-width"
                              onClick={() => {
                                handleImages(2);
                              }}
                            >
                              Select Previous sign
                            </button>
                          </p>
                          <p>
                            <button
                              className="btn btn-light same-width"
                              onClick={() => {
                                handleImages(3);
                              }}
                            >
                              Upload From computer
                            </button>
                          </p>
                          <p id="signlist" style={{ display: "none" }}>
                            <div className="row">
                              {props.signatureListdata &&
                                props.signatureListdata.map(
                                  (signaturevalue, indeximage) => {
                                    var imagelinknew = "";
                                    if (
                                      signaturevalue.signature_image != "" &&
                                      signaturevalue.signature_image != null
                                    ) {
                                      imagelinknew =
                                        "https://api-gbs.smitiv.co" +
                                        signaturevalue.signature_image;
                                    }
                                    var generatedid = "imageid" + indeximage;
                                    return (
                                      <>
                                        <div
                                          className="col-sm-4"
                                          style={{ padding: "15px" }}
                                        >
                                          <input
                                            type="radio"
                                            name="image-previous"
                                            value={indeximage}
                                            onClick={() => {
                                              update_selectpreview(indeximage);
                                            }}
                                          />
                                          <img
                                            src={imagelinknew}
                                            id={generatedid}
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                            }}
                                          />
                                          <br />
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                            </div>
                          </p>
                          <p id="drawsign" style={{ opacity: "0" }}>
                            <SignaturePad ref={padRef} />
                            <p
                              style={{ marginTop: "10px", display: "none" }}
                              id="clearButton"
                            >
                              <button
                                className="btn btn-light same-width-2"
                                onClick={() => {
                                  handleClear();
                                }}
                              >
                                Clear{" "}
                              </button>
                              &nbsp;
                              <button
                                className="btn btn-light same-width-2"
                                onClick={() => {
                                  showpreview();
                                }}
                              >
                                Preview{" "}
                              </button>
                            </p>
                          </p>

                          <p>
                            <input
                              ref={fileRef}
                              type="file"
                              id="upload-button"
                              style={{ display: "none" }}
                              onChange={handleChange}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </p>
                        </div>
                        <div className="col-sm-3">
                          <p>
                            <div
                              className="mt-20 text-center"
                              style={{ height: "200px" }}
                            >
                              <h6 className="mb-25">Sign Preview</h6>

                              <iframe
                                // ref={iframeRef}
                                key={1}
                                // src ={url}
                                src={iframelink}
                                frameborder="0"
                                width="100%"
                                id="iFrame"
                                style={{ minHeight: "inherit" }}
                              />
                            </div>
                          </p>
                          <p>
                            {sessionStorage.getItem("agree_status") != 1 &&
                              sessionStorage.getItem("agree_status") != 3 &&
                              props.agreeListdata &&
                              props.agreeListdata.map((valdata, indexdata) => {
                                return (
                                  <>
                                    <input
                                      type="checkbox"
                                      id={valdata.agree_name}
                                      name="agree[]"
                                      onChange={(e) =>
                                        changecheckboxValue(
                                          valdata.agree_name,
                                          indexdata
                                        )
                                      }
                                    />
                                    {valdata.agree_name}
                                    <br />
                                  </>
                                );
                              })}
                          </p>
                          <div
                            className="form-group row"
                            style={{ padding: "20px" }}
                          >
                            <div className="col-md-5"></div>
                            <button
                              className="btn btn-primary"
                              onClick={(e) => AddNewFunc(e)}
                            >
                              Save Signature
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* <div className="row">
                            <label className="col-md-4 control-label">
                                sign Type
                            </label>
                            <div className="col-md-6">
                                <select
                                        className="form-control"
                                        onChange={(e) => {
                                        setPlaceholder(e.target.value);
                                        }}
                                        id="signname"
                                        name="signname"
                                    ><option value='' >--Select Signature --</option>
                                        { props.signatureListdata && props.signatureListdata.map((value,index)=>{
                                            return(
                                            <>
                                                <option value={value.id} >{ value.signature_name}</option>
                                            </>
                                            )
                                        })
                                        }
                                    
                                </select>
                            </div>
                            </div> */}
                      {/* <div className="row">
                                <br />
                                <label className="col-md-4 control-label">
                                sign 
                                </label>
                            <div className="col-md-6">
                                <input
                                className="form-control"
                                onChange={(e) => {
                                    setFile(e.target.files[0]);
                                }}
                                maxLength={4096}
                                accept=".jpg, .jpeg, .png"
                                name="original_filename"
                                type="file"
                                id="original_filename"
                                />
                            </div>
                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>

      {/* ./wrapper */}
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { shareResolutionList, shareHolderList, ShareTypeList, shareId } =
    state.ShareReg;
  return {
    shareResolutionList,
    shareHolderList,
    ShareTypeList,
    shareId,
    deleteModal: state.Deadline.deleteModal,
    processModal: state.Client.processModal,
    companyList: state.CompanySetups.companyList,
    agreeListdata: state.Client.agreeListdata,
    documentlist: state.Client.documentlist,
    client_key: state.Client.client_key,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    signatureListByUser: (input) =>
      dispatch(client.signatureList_byuser(input)),
    documentListbyID: (input) => dispatch(client.documentList_BYID(input)),
    agreeListbyID: (input) => dispatch(client.agreeList_BYID(input)),
    getdocumentpendingList: (data) =>
      dispatch(actions.getdocumentpendingList(data)),
    getdocumentManagementList: () =>
      dispatch(actions.getdocumentManagementList()),
    changeCompanyInfo: (f, e) => dispatch(actions.changeCompanyInfo(f, e)),
    addCompany: (data) => dispatch(actions.addCompany(data)),
    updateCompanyErrors: (data) => dispatch(actions.updateCompanyErrors(data)),
    reset_company_info: () => dispatch(actions.reset_company_info()),
    setValue: (f, v) => dispatch(client.changeClientInfo(f, v)),
    signUpload: (input) => dispatch(signUpload_newUser(input)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignDocument);
