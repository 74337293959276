import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../../actions/clientDetails/Document";
import * as share from "../../../actions/clientDetails/ShareRegister";
import DatePicker from "react-datepicker";
import { s3_file_upload } from "../../../actions/common/S3_Upload";
import moment from "moment";
import { changeClientInfo } from "../../../actions/Client";
import { ProgressBar } from 'react-loader-spinner';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function SendRequest(props) {

  
  const [selectCategory, setSelectCategory] = useState("");
  console.log('selectCategory: initail -------------> ', selectCategory);


  
  useEffect(() => {


    setSelectCategory("")

    const getCategory = () => {
      console.log('getCategory: ');
      const category = sessionStorage.getItem("select_category");
     
        setSelectCategory(category);
        console.log("selectCategory updated:", category);
     
    };

    getCategory();
  }, []); 





  useEffect(() => {
    props.getShareHolderDirectorList(1);
  }, []);







  const fileNameSplit = (filePath) => {
    let file = filePath.split("/");
  let fullFileName = file[file.length - 1];

  let beforeText = fullFileName.split("---")[0];
    return beforeText;
  };

  useEffect(() => {
    if (props.multiple_document_id.length) {
      props.getDocPath(props.multiple_document_id);
    }
  }, [props.multiple_document_id]);

  const [filename, setFilename] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState({
    tittle: "",
    body: "",
    document_category:"",
    deadline1_date: "",
    deadline2_date: "",
    deadline3_date: "",
    deadline1_status: "",
    deadline2_status: "",
    deadline3_status: "",
  });

  useEffect(() => {
    let add = [...props.shareHolderList];
    props.shareHolderList.map((share) => {
      if (share.person_copied_status != 1) {
        share.check = false;
      } else {
        share.check = true;
      }
    });
    props.sendValues("shareHolder", add);
  }, [props.shareHolderList]);

  useEffect(() => {
    let add = [...props.shareHolder];
    let fill = props.shareHolder.filter((share) => {
      if (share.person_copied_status != 1) {
        if (share.check == true) {
          return share;
        }
      }
    });
    props.sendValues("filterValue", fill);
  }, [props.shareHolder]);

  const checkChangeFunc = (e) => {
    
    let change = [...props.shareHolder];
    props.shareHolder.map((val) => {
      if (val.id == e.target.name) {
        val.check = e.target.checked;
      }
    });
    props.sendValues("shareHolder", change);
  };


  
  const selectAllFunc = (e) => {
    let change = [...props.shareHolder];
    props.shareHolder.map((val) => {
      if (val.person_copied_status != 1) {
        val.check = e.target.checked;
      }
    });
    props.sendValues("shareHolder", change);
  };

  const removeAttchFile = (idx) => {
    let nme = [...filename];
    let lnk = [...props.attachment];
    nme.splice(idx, 1);
    lnk.splice(idx, 1);
    setFilename(nme);
    props.attachmentArray(lnk);
  };

  const uploadImage = async (val) => {
    console.log("chinnu val", val)
    setLoading(true);
    let response = {status: 1};
    var userData = JSON.parse(localStorage.getItem("userData"));
    let tempArr = Array.from(Array(val.length), (_, index) => index + 1);
    console.log("chinnu tempArr", tempArr)
    let s3List = await Promise.all(tempArr.map(async (item, i) => {
      let data = {
        company_id: userData.company_id,
        client_id: sessionStorage.getItem("personalId"),
        active_tab: props.pageSelected,
        attachment: val[i],
      };
      console.log("chinnu data", data)
      if (data.attachment != "") {
        response = await s3_file_upload(data);
      }
      let file = response.original_link ? response.original_link : "";
      
      props.attachmentArray(file);
      if(i == (val.length - 1)){
        setLoading(false);
      }
      return file
    }))
    console.log("chinnu s3List", s3List)
    setFilename([...s3List]);
    // let data = {
    //   company_id: userData.company_id,
    //   client_id: sessionStorage.getItem("personalId"),
    //   active_tab: props.pageSelected,
    //   attachment: val,
    // };
    // console.log("chinnu data", data)
    // if (data.attachment != "") {
    //   response = await s3_file_upload(data);
    // }
    // let file = response.original_link ? response.original_link : "";
    // setFilename([...filename, val.name]);
    // if (file != "") {
    //   props.attachmentArray(file);
    // } else {
    //   props.changeClientInfo("clientApiStatus", 2);
    //   props.changeClientInfo("message", "File not upload");
    //   setTimeout(() => {
    //     props.changeClientInfo("clientApiStatus", 0);
    //     props.changeClientInfo("message", "");
    //   }, 2500);
    // }
  };

  const isBodyEmpty = (body) => {
    const strippedBody = body.replace(/<[^>]*>/g, '').trim();
    return strippedBody === "";
  };

  // console.log("multiple_document_id", props.multiple_document_id);
  const sendDocumentFunc = (e) => {

    if (
      fieldValue.tittle === "" || 
      isBodyEmpty(fieldValue.body) || 
      fieldValue.document_category === ""
    ) {
      alert("Please fill all the fields");
      return;
    }

   
    


    e.preventDefault();
    let error = false;
    // console.log("mail", props.filterValue);
    let mail = props.filterValue.map((fill) => {
      return fill.email;
    });
    let mail_id = props.filterValue.map((fill) => {
      return fill.id;
    });
    let fillMail = props.shareHolder.filter((fill) => {
      if (fill.person_copied_status == 1) {
        return fill;
      }
    });
    // console.log("ccMail", fillMail, props.shareHolder);
    let ccMail = fillMail.map((m) => {
      return m.email;
    });
    let ccMail_id = fillMail.map((m) => {
      return m.id;
    });

    props.shareHolder.filter((fill) => {
      if (fill.person_copied_status == 1) {
        return fill;
      }
    });

    let deadline_list = [];
    if(props.tabSelected !== "Received"){
      if (fieldValue.deadline1_status != "") {
        if (fieldValue.deadline1_date != "") {
          let val = {
            deadline_date: moment(fieldValue.deadline1_date).format("YYYY-MM-DD"),
            deadline_type_id: fieldValue.deadline1_status,
          };
          deadline_list.push(val);
        } else {
          error = true;
          props.changeClientInfo("clientApiStatus", 2);
          props.changeClientInfo("message", "Please Fill Deadline 1 Date Field");
          setTimeout(() => {
            props.changeClientInfo("clientApiStatus", 0);
            props.changeClientInfo("message", "");
          }, 2500);
        }
      }
      if (fieldValue.deadline2_status != "") {
        if (fieldValue.deadline2_date != "") {
          let val = {
            deadline_date: moment(fieldValue.deadline2_date).format("YYYY-MM-DD"),
            deadline_type_id: fieldValue.deadline2_status,
          };
          deadline_list.push(val);
        } else {
          error = true;
          props.changeClientInfo("clientApiStatus", 2);
          props.changeClientInfo("message", "Please Fill Deadline 2 Date Field");
          setTimeout(() => {
            props.changeClientInfo("clientApiStatus", 0);
            props.changeClientInfo("message", "");
          }, 2500);
        }
      }
      if (fieldValue.deadline3_status != "") {
        if (fieldValue.deadline3_date != "") {
          let val = {
            deadline_date: moment(fieldValue.deadline3_date).format("YYYY-MM-DD"),
            deadline_type_id: fieldValue.deadline3_status,
          };
          deadline_list.push(val);
        } else {
          error = true;
          props.changeClientInfo("clientApiStatus", 2);
          props.changeClientInfo("message", "Please Fill Deadline 3 Date Field");
          setTimeout(() => {
            props.changeClientInfo("clientApiStatus", 0);
            props.changeClientInfo("message", "");
          }, 2500);
        }
      }
    }
    // console.log("chinnu mail.length", mail.length)
    if (mail.length != 0 && error == false) {
      let input = {
        mail_title: fieldValue.tittle,
        mail_body: fieldValue.body,
        document_category: fieldValue.document_category,
        deadline_list: deadline_list,
        document_type_id: props.selectDocumentType,
        document_id: props.document_id,
        multiple_document_id: props.multiple_document_id,
        to: mail,
        cc: ccMail,
        mail_id: mail_id,
        ccMail_id: ccMail_id,
        attach_doc: props.attachment,
        view_doc: "view",
      };

      if (
        deadline_list.length === 0
      ) {
        alert("Please Add Deadline Date");
        return;
      }

      console.log('input: ', input);

props.sendDocumentInMail(input, props.tabSelected)
.then(() => {
  localStorage.setItem('activeTab', 'documents');
  window.location.reload();
})
.catch((error) => {
  alert("The network is slowing down. Please delete this document and start the process again.");
});


window.addEventListener('DOMContentLoaded', () => {
const activeTab = localStorage.getItem('activeTab');
if (activeTab === 'documents') {
  document.querySelector('.nav-tabs .active')?.classList.remove('active');
  document.querySelector('.nav-tabs li:nth-child(5)')?.classList.add('active'); 
}

localStorage.removeItem('activeTab');
});



      setTimeout(() => {
        props.changeClientInfo("clientApiStatus", 0);
        props.changeClientInfo("message", "");
      }, 2500);
      
    } else if (mail.length == 0) {
      props.changeClientInfo("clientApiStatus", 2);
      props.changeClientInfo("message", "Please Add Send Mail First");
      setTimeout(() => {
        props.changeClientInfo("clientApiStatus", 0);
        props.changeClientInfo("message", "");
      }, 2500);
    }
  };



// const sendDocumentFunc = (e) => {
//   console.log('fieldValue.tittle: ', fieldValue.tittle);
//   console.log('fieldValue.body: ', fieldValue.body);
//   console.log('fieldValue.document_category: ', fieldValue.document_category);

//   if (
//     fieldValue.tittle === "" || 
//     isBodyEmpty(fieldValue.body) || 
//     fieldValue.document_category === ""
//   ) {
//     alert("Please fill all the fields");
//     return;
//   }



// };
  // const saveChangesFunc  =(e)=>{
  //   e.preventDefault();

  // let input ={
  //   document_type:props.selectDocumentType,
  //   // generate_doc:true,
  //   // file_url:props.docPreview,
  //   user_id:2,
  //   client_id:sessionStorage.getItem("personalId"),
  //   status:5,
  //   placeholder_list:obj,
  // };
  //   props.sendAndSaveDocument(input);

  // };
  return (
    <Modal
      show={props.sendRequestModal}
      id="myModal"
      className="modal fade in"
      style={{ display: "block" }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-dialog modal-xl"
    >
      <div className="modal-content ">
        <div className="modal-header text-center">
          {/* <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() =>
            props.sendValues("sendRequestModal", false)
          }>X</button> */}
          <h4 className="modal-title blue-text">
          Send Request Signature for - { sessionStorage.getItem("select_category")} </h4>
        </div>
        <div className="modal-body pb-0">
          <div className="row">
            <div className="col-sm-6 border-right-gray">
              <div className="share-list-box-wrapper">
                <div className="sl-head-wrapper">
                  <h6>TO</h6>
                  <div className="name-list">
                    {props.filterValue.map((fill, idx) => {
                      return <span key={idx}>{fill.name},</span>;
                    })}
                    {/* <input /> */}
                  </div>
                  <div className="border-line" />
                </div>
                <div className="sl-body-wrapper">
                  <h5>Shareholder &amp; Director list</h5>
                  <ul>
                    {props.shareHolderList &&
                      props.shareHolderList.map((lis, idx) => {
                        // console.log('lis: ', lis);
                        return (
                          <li key={idx}>
                            <div className="user-details user-lis ">
                              <div className="user-img" />
                              {/* <h6>{lis.name+"-"+lis.desgnames}</h6> */}
                              <h6>

                                  {/* {lis.name}
                                    {lis.desgnames ? "-" + lis.desgnames : ""} */}
                                {lis.name}
                                {" " + " - " + " "}
                                {lis.desgnames }
                              </h6>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                id="Jane"
                                name={lis.id}
                                checked={lis.check}
                                defaultValue="Jane"
                                onClick={checkChangeFunc}
                              />
                            </div>
                          </li>
                        );
                      })}
                    {/* <li>
                          <div className="user-details">
                            <div className="user-img" />
                            <h6>Jane Doe</h6>
                          </div>
                          <div>
                            <input type="checkbox" id="Jane" name="Jane" defaultValue="Jane" />
                          </div>
                        </li> */}
                    {/* <li>
                          <div className="user-details">
                            <div className="user-img" />
                            <h6>Jane Doe</h6>
                          </div>
                          <div>
                            <input type="checkbox" id="Jane" name="Jane" defaultValue="Jane" />
                          </div>
                        </li>
                        <li>
                          <div className="user-details">
                            <div className="user-img" />
                            <h6>Jane Doe</h6>
                          </div>
                          <div>
                            <input type="checkbox" id="Jane" name="Jane" defaultValue="Jane" />
                          </div>
                        </li> */}
                    {/* <li>
                          <div className="user-details">
                            <div className="user-img" />
                            <h6>Jane Doe</h6>
                          </div>
                          <div>
                            <input type="checkbox" id="Jane" name="Jane" defaultValue="Jane" />
                          </div>
                        </li> */}
                    {/* <li>
                          <div className="user-details">
                            <div className="user-img" />
                            <h6>Jane Doe</h6>
                          </div>
                          <div>
                            <input type="checkbox" id="Jane" name="Jane" defaultValue="Jane" />
                          </div>
                        </li> */}
                    {/* <li>
                          <div className="user-details">
                            <div className="user-img" />
                            <h6>Jane Doe</h6>
                          </div>
                          <div>
                            <input type="checkbox" id="Jane" name="Jane" defaultValue="Jane" />
                          </div>
                        </li> */}
                  </ul>
                </div>
                <div className="sl-footer-wrapper">
                  <h6>
                    <span>{props.filterValue.length} personnal</span> selected
                  </h6>
                  <div>
                    <h6>Select All</h6>
                    <input
                      type="checkbox"
                      id="Jane"
                      name="Jane"
                      defaultValue="Jane"
                      onClick={selectAllFunc}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 border-right-gray">
              <div className="form-group">
                <label
                  htmlFor="registrationno"
                  className="control-labelrequired"
                >
                  Document Category
                </label>
                <select
                  className="form-control mb-10"
                  id="document_type_id"
                  name="document_category"
                  onChange={(e) => {
                    setFieldValue({
                      ...fieldValue,
                      [e.target.name]: e.target.value,
                    });
                  }}
                >
                  <option value selected="selected"> === Select === </option>
                  <option value={"Secreterial"}>Secreterial</option>
                  <option value={"Others"}>Others</option>
                  <option value={"Financial statement"}>Financial statement</option>
                  <option value={"Tax computation"}>Tax computation</option>
                  <option value={"GST"}>GST</option>
                  <option value={"CERTIFICATE OF RESIDENCE"}>CERTIFICATE OF RESIDENCE </option>
                  <option value={"PERSONAL TAXES"}>PERSONAL TAXES</option>
                  {/* <option value={"Vds"}>Vds</option>
                  <option value={"xyz"}>xyz</option> */}
                </select>
                <input
                  className="form-control  mb-10"
                  required="required"
                  name="tittle"
                  type="text"
                  id="registrationno"
                  onChange={(e) => {
                    setFieldValue({
                      ...fieldValue,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
                {/* <textarea
                  className="form-control"
                  rows={10}
                  name="body"
                  cols={50}
                  id="remarks"
                  onChange={(e) => {
                    setFieldValue({
                      ...fieldValue,
                      [e.target.name]: e.target.value,
                    });
                  }}
                /> */}





<ReactQuill
  value={fieldValue.body}
  onChange={(content) => {
    setFieldValue({
      ...fieldValue,
      body: content, 
    });
  }}
  theme="snow"
/>

                <p className="help-block">
                  A link to the document will be automatically included with
                  your message
                </p>
              </div>
              <div className="view-attach">
                {/* <h6> <span className="glyphicon glyphicon-paperclip" />
                  <label for="myview">
                    <span className="txt">View</span>
                    <div id="myview" name="myfile" style={{ display: "none" }}  ></div>
                  </label>
                </h6> */}
                <h6> <span className="fa fa-save" />
                  <label for="myfile">
                    <span className="txt" >Attach</span>
                    <input type="file" id="myfile" name="myfile" multiple={"true"}
                      style={{ display: "none" }} 
                      onChange={(e) => uploadImage(e.target.files)}
                    />
                  </label> 
                </h6>
                { loading &&
                  <ProgressBar
                    height="50"
                    width="80"
                    ariaLabel="progress-bar-loading"
                    wrapperStyle={{}}
                    wrapperClass="progress-bar-wrapper"
                    borderColor = '#F4442E'
                    barColor = '#51E5FF'
                  />
                }
              </div>
              {filename.map((val) => <><a>{fileNameSplit(val)}{""}</a><hr style={{marginTop: "5px", marginBottom: "5px"}}/></>)}
              <div className="view-attach mb-20">
                  <div>
                  {props.docPath?.map((data, i) => {
                    return (
                      <div>
                        <a
                          target={"_blank"}
                          href={`https://docs.google.com/viewer?url=https://api-gbs.smitiv.co${data.file_path}&embedded=true`}
                          className="txt"
                        >
                          {fileNameSplit(data?.file_path)}
                        </a>
                        <br />
                      </div>
                    );
                  })}
                  </div>
                </div>
                {
                  props.tabSelected === "Pending" ? 
                  <>
                  
                    <div className="row  align-center">
                      <div className="col-sm-3">
                        <label htmlFor="Deadline1" className="control-label required">
                          Deadline 1
                        </label>
                      </div>
                      <div className="col-sm-4 p-0">
                        <div className="calendar-icon">
                          <DatePicker
                            showPopperArrow={false}
                            dateFormat={"dd/MM/yyyy"}
                            selected={fieldValue.deadline1_date}
                            locale={"en"}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: "0px, 0px",
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                              },
                            }}
                            onChange={(Date) => {
                              setFieldValue({ ...fieldValue, deadline1_date: Date });
                            }}
                          >
                            <div
                              class="trash-icon"
                              onClick={() => {
                                setFieldValue({ ...fieldValue, deadline1_date: "" });
                              }}
                            >
                              <span class="glyphicon glyphicon-trash"></span>{" "}
                            </div>
                          </DatePicker>
                          {/* <i className="fa fa-calendar left-img" /> */}
                        </div>
                      </div>
                      <div className="col-sm-5 pr-0 align-center">
                        <input
                          type="checkbox"
                          className="check-box-active"
                          id="2weeks"
                          name="deadline1_status"
                          defaultValue="2weeks"
                          onChange={(e) => {
                            setFieldValue({
                              ...fieldValue,
                              [e.target.name]: e.target.checked == true ? 1 : "",
                            });
                          }}
                        />
                        <label
                          htmlFor="Deadline1"
                          className="control-label pl-10 mb-0 required"
                        >
                          Remind every 2 weeks
                        </label>
                      </div>
                    </div>
                    <div className="row mt-15  align-center">
                      <div className="col-sm-3">
                        <label htmlFor="Deadline2" className="control-label required">
                          Deadline 2
                        </label>
                      </div>
                      <div className="col-sm-4 p-0">
                        <div className="calendar-icon">
                          <DatePicker
                            showPopperArrow={false}
                            dateFormat={"dd/MM/yyyy"}
                            selected={fieldValue.deadline2_date}
                            locale={"en"}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: "0px, 0px",
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                              },
                            }}
                            onChange={(Date) => {
                              setFieldValue({ ...fieldValue, deadline2_date: Date });
                            }}
                          >
                            <div
                              class="trash-icon"
                              onClick={() => {
                                setFieldValue({ ...fieldValue, deadline2_date: "" });
                              }}
                            >
                              <span class="glyphicon glyphicon-trash"></span>{" "}
                            </div>
                          </DatePicker>
                          {/* <i className="fa fa-calendar left-img" /> */}
                        </div>
                      </div>
                      <div className="col-sm-5 pr-0 align-center">
                        <input
                          type="checkbox"
                          className="check-box-active"
                          id="1weeks"
                          name="weeks"
                          defaultValue="1weeks"
                          onClick={(e) => {
                            setFieldValue({
                              ...fieldValue,
                              deadline2_status: e.target.checked == true ? 2 : "",
                            });
                          }}
                        />
                        <label
                          htmlFor="Deadline1"
                          className="control-label pl-10 mb-0 required"
                        >
                          Remind every 1 weeks
                        </label>
                      </div>
                    </div>
                    <div className="row mt-15  align-center">
                      <div className="col-sm-3">
                        <label htmlFor="Deadline3" className="control-label required">
                          Deadline 3
                        </label>
                      </div>
                      <div className="col-sm-4 p-0">
                        <div className="calendar-icon">
                          <DatePicker
                            showPopperArrow={false}
                            dateFormat={"dd/MM/yyyy"}
                            selected={fieldValue.deadline3_date}
                            locale={"en"}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: "0px, 0px",
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                              },
                            }}
                            onChange={(Date) => {
                              setFieldValue({ ...fieldValue, deadline3_date: Date });
                            }}
                          >
                            <div
                              class="trash-icon"
                              onClick={() => {
                                setFieldValue({ ...fieldValue, deadline3_date: "" });
                              }}
                            >
                              <span class="glyphicon glyphicon-trash"></span>{" "}
                            </div>
                          </DatePicker>
                          {/* <i className="fa fa-calendar left-img" /> */}
                        </div>
                      </div>
                      <div className="col-sm-5 pr-0 align-center">
                        <input
                          type="checkbox"
                          className="check-box-active"
                          id="every"
                          name="weeks"
                          defaultValue="every"
                          onClick={(e) => {
                            setFieldValue({
                              ...fieldValue,
                              deadline3_status: e.target.checked == true ? 3 : "",
                            });
                          }}
                        />
                        <label
                          htmlFor="Deadline1"
                          className="control-label pl-10 mb-0 required"
                        >
                          Remind every day
                        </label>
                      </div>
                    </div>
                  </> : ""
                }
            </div>

            <div className="col-sm-12 mt-20">
              {props.clientApiStatus ? (
                <div
                  className={`alert ${
                    props.clientApiStatus === 1 ? "" : "alert-danger"
                  }`}
                  role="alert"
                >
                  {props.clientApiStatus === 1 ? "" : props.message}
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className="col-sm-12 pt-20 pb-20 mt-20 flex-center-space"
              style={{ background: "rgba(92, 132, 149, 0.32)" }}
            >
              <button
                className="btn transparent-btn"
                onClick={() => props.sendValues("sendRequestModal", false)}
              >
                Cancel
              </button>
              <button className="btn blue-btn" onClick={sendDocumentFunc}>
                Send Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    transferOfShareModal,
    docPreview,
    defaultFields,
    selectDocumentType,
    sendRequestModal,
    attachment,
    shareHolder,
    filterValue,
    document_id,
    multiple_document_id,
    docPath,
    tabSelected
  } = state.Document;
  // console.log("tabSelected popup", tabSelected)
  return {
    transferOfShareModal,
    docPreview,
    defaultFields,
    selectDocumentType,
    sendRequestModal,
    shareHolder,
    filterValue,
    attachment,
    document_id,
    tabSelected,
    multiple_document_id,
    shareHolderList: state.ShareReg.shareHolderList,
    pageSelected: state.Client.pageSelected,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    tabSelected: state.Document.tabSelected,
    docPath: state.Document.docPath,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.documentFunction(k, v)),
    getDocumentPreview: (val) => dispatch(actions.getDocumentPreview(val)),
    getShareHolderDirectorList: (data) =>
      dispatch(share.getShareHolderDirectorList(data)),
    getDocPath: (data) => dispatch(actions.getDocumentPath(data)),
    attachmentArray: (data) => dispatch(actions.attachmentArray(data)),
    sendDocumentInMail: (input, tab) =>
      dispatch(actions.sendDocumentInMail(input, tab)),
    changeClientInfo: (k, v) => dispatch(changeClientInfo(k, v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendRequest);
