/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import DocumentEditor from "../../documentEditor/DocumentEditor";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Container, Modal } from "react-bootstrap";
import "file-viewer";
import * as share from "../../../actions/clientDetails/ShareRegister";
import * as actions from "../../../actions/clientDetails/Document";
import { changeClientInfo } from "../../../actions/Client";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DecoupledcEditor from "@ckeditor/ckeditor5-build-decoupled-document";
// import "../../../../node_modules/@ckeditor/ckeditor5-build-decoupled-document/build/ckeditor.js"
import { ColorRing } from "react-loader-spinner";
import Draggable from "react-draggable";
// import { Worker, Viewer } from "@react-pdf-viewer/core";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { headers } from "../../../actions/common/Headers";

import { Viewer } from "@react-pdf-viewer/core";

import { Worker } from "@react-pdf-viewer/core";

import { PDFDocument, rgb } from "pdf-lib";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import MarkerImg01 from "../../../assets/close.png";
import config from "../../../actions/common/Api_Links";

import { documentFunction } from "../../../actions/clientDetails/Document";

import * as pdfjsLib from "pdfjs-dist"; // For rendering PDF pages
var _ = require("lodash");



pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js";


function TransferShares(props) {
  const dropdownRef = useRef(null);
  const iframeRef = useRef(0);
  const [random, setRandom] = useState(0);
  const [url, setUrl] = useState("");
  const [url2, setUrl2] = useState("");
  const [obj, setObj] = useState({});
  const [signObj, setSignObj] = useState({});
  const [signName, setSignName] = useState({});
  const [email, setEmail] = useState([]);

  const [receivers, setReceivers] = useState([]);
  const [transferor, setTransferor] = useState({});
  const [shareHolder, setShareHolder] = useState([{ shareholder_name: "" }]);
  const [templateData, setTemplateData] = useState();
  const [rendr, setRendr] = useState(false);

  const [temName, setTemName] = useState("");
  const [shareHolderList, setShareHolderList] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [secratoryList, setSecratoryList] = useState([]);
  const [corporateRepresentativeList, setCorporateRepresentativeList] =
    useState([]);
  const [loading, setLoading] = useState(false);

  // const frame = document.getElementById("myFrame");
  const [selectEmail, setSelectEmail] = useState("");
  const [edited, setEdited] = useState("");
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);

  const [dis, setDis] = useState(false);



  const [othetName, setOthetName] = useState("");




  {
    /* ============================================== ==============================================  ==============================================  */
  }

  const dispatch = useDispatch();
  const [document, setDocument] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [persons, setPersons] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [isAddingMarker, setIsAddingMarker] = useState(false);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [newMarkerLabel, setNewMarkerLabel] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedSign, setSelectedSign] = useState("");
  const [finalFile, setFinalFile] = useState(null);
  const [optionsNames, setOptionsNames] = useState([]);



  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [currentMarker, setCurrentMarker] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [draggingMarker, setDraggingMarker] = useState(null);
  const [liveX, setLiveX] = useState(null);
  const [liveY, setLiveY] = useState(null);

  const [docId, setDocId] = useState();

  const [saveData, setSaveData] = useState([]);

  const viewerRef = useRef();

  const designations = ["Director", "Shareholder"];

  const  validData = [];

  const [validObj, setValidObj] = useState([]);

  const [reload, setReload] = useState(false);




  const optionsByDesignation = {
    Director: [
      { name: "Director1", value: "director_name1", sign: "director_sign1" },
      { name: "Director2", value: "director_name2", sign: "director_sign2" },
      { name: "Director3", value: "director_name3", sign: "director_sign3" },
    ],
    Shareholder: [
      {
        name: "Shareholder1",
        value: "shareholder_name1",
        sign: "shareholder_sign1",
      },
      {
        name: "Shareholder2",
        value: "shareholder_name2",
        sign: "shareholder_sign2",
      },
      {
        name: "Shareholder3",
        value: "shareholder_name3",
        sign: "shareholder_sign3",
      },
    ],
  };

  {
    /* ============================================== ==============================================  ==============================================  */
  }







  useEffect(() => {
    props.getShareHolderList();
    setRandom(random + 1000);
    setUrl(
      `https://docs.google.com/viewer?url=https://api-gbs.smitiv.co${props.docPreview}&embedded=true`
    );
    iframeRef.current = random;
  }, []);

  // useEffect(() => {
  //   if(props.transferOfShareModal){
  //     previewFunc();
  //   }
  // },[props.transferOfShareModal])

  const assignDefaultFieldValues = () => {


    // if (props.editDocumentModal == true) {
      props.documentList &&
        props.documentList.map((data) => {

          // setObj({});

          data.list.map((lis) => {
            if (lis.id == props.document_id) {
              let arr = lis.placeholder_values.split(",");
              let signArr = lis.signature_placeholder_values?.split(",");
           

              if (props.defaultFields) {
                if (
                  props.defaultFields.placeholderList &&
                  props.defaultFields.placeholderList?.length > 0
                ) {
                  props.defaultFields.placeholderList.map((fie, ind) => {
                    arr.map((val, idx) => {
                      if (ind == idx) {
                        // let ass = {...obj}
                        obj[fie.Name] = val; 
                        

                        setObj((prevObj) => {
                          return { ...prevObj, ...obj };
                        });
                      }
                    });
                  });
                }
                if (signArr == undefined) {
                  signArr = [];
                }
                if (
                  props.defaultFields.signaturePlaceholderList &&
                  props.defaultFields.signaturePlaceholderList?.length > 0 &&
                  signArr?.length > 0
                ) {
                  props.defaultFields.signaturePlaceholderList.map(
                    (fie, ind) => {
                      signArr.map((val, idx) => {
                        if (ind == idx) {
                          // let ass = {...obj}
                          signObj[fie.Name] = val;
                          setSignObj((prevObj) => {
                            return { ...prevObj, ...signObj };
                          });
                        }
                      });
                    }
                  );
                }
              }
            }
          });
        });
    // }
  };

  const date = new Date();
  const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;


  useEffect(() => {



    var company;
    if (props.defaultFields) {
      if (
        props.defaultFields.placeholderList &&
        props.defaultFields.placeholderList?.length > 0
      ) {
        props.defaultFields.placeholderList.map((key) => {




const validNames = ['name1', 'name2', 'name3', 'name4'];

if (key.Name && validNames.some(name => key.Name.toLowerCase().includes(name))) {
  let value = { name: key.Name, value: "" };

  validData.push(value);
}



          if (key.Name.toLowerCase().includes("date")) {
      


            obj[key.Name] = formattedDate;
          } else {
            obj[key.Name] = "";
          }
         
          console.log('obj: ', obj);

          setObj({ ...obj });


        });
      }
      if (
        props.defaultFields.placeholderList &&
        props.defaultFields.signaturePlaceholderList?.length > 0
      ) {
        props.defaultFields.signaturePlaceholderList.map((key) => {

         


          signObj[key.Name] = "";
          setSignObj({ ...signObj });
        });

        props.defaultFields.signaturePlaceholderList.map((key) => {
          signName[key.Name] = "";
          setSignName({ ...signName });
        });

        props.defaultFields.signaturePlaceholderList.map((key) => {
          email[key.email] = "";
          setEmail({ ...email });
        });
      }

      if (
        props.defaultFields.clientdata &&
        props.defaultFields.clientdata?.length > 0
      ) {
        sessionStorage.setItem(
          "companyname",
          props.defaultFields.clientdata[0].company_name
        );


        sessionStorage.setItem(
          "registration_no",
          props.defaultFields.clientdata[0].registration_no
        );

        sessionStorage.setItem(
          "Identification_no1",
          props.defaultFields.clientdata[0].Identification_no1 ? props.defaultFields.clientdata[0].Identification_no1 : " - "
        );


        sessionStorage.setItem(
          "Address1",
      

          props.defaultFields.clientdata[0].Address1 ? props.defaultFields.clientdata[0].Address1 : " - "
        );

        


        sessionStorage.setItem("current_date", moment().format("DD/MM/YYYY"));

        sessionStorage.setItem(
          "address",
          props.defaultFields.clientdata[0].address
        );

        if (
          props.defaultFields.placeholderList.map(
            (item) => item.Name == "FinancialYearEnd_date"
          )
        ) {
          sessionStorage.setItem(
            "FinancialYearEnd_date",
            formattedDate
          );
        }

        if (
          props.defaultFields.placeholderList.map(
            (item) => item.Name == "Filing_date"
          )
        ) {
          sessionStorage.setItem(
            "Filing_date",
            formattedDate
          );
        }

         if (
          props.defaultFields.placeholderList.map(
            (item) => item.Name == "FinancialYearEnd_date"
          )
        ) {
          sessionStorage.setItem(
            "FinancialYearEnd_date",
            formattedDate
          );
        }

        if (
          props.defaultFields.placeholderList.map(
            (item) => item.Name == "Resolution_date"
          )
        ) {
          sessionStorage.setItem(
            "Resolution_date",
            formattedDate
          );
        }

        if (
          props.defaultFields.placeholderList.map(
            (item) => item.Name == "convened_date"
          )
        ) {
          sessionStorage.setItem(
            "convened_date",
            formattedDate
          );
        }



        

        

        changeFunc(sessionStorage.getItem("Address1"), "Address1");

        changeFunc(sessionStorage.getItem("companyname"), "company_name");

        changeFunc(sessionStorage.getItem("Identification_no1"), "Identification_no1");

        changeFunc(
          sessionStorage.getItem("registration_no"),
          "registration_no"
        );
        changeFunc(sessionStorage.getItem("address"), "address");
        changeFunc(sessionStorage.getItem("current_date"), "current_date");

        if (sessionStorage.getItem("FinancialYearEnd_date") != null) {
          changeFunc(
            sessionStorage.getItem("FinancialYearEnd_date"),
            "FinancialYearEnd_date"
          );
        }

        if (sessionStorage.getItem("Resolution_date") != null) {
          changeFunc(
            sessionStorage.getItem("Resolution_date"),
            "Resolution_date"
          );
        }
        if (sessionStorage.getItem("Filing_date") != null) {
          changeFunc(
            sessionStorage.getItem("Filing_date"),
            "Filing_date"
          );
        }

        

        if (sessionStorage.getItem("convened_date") != null) {
          changeFunc(sessionStorage.getItem("convened_date"), "convened_date");
        }

        if (sessionStorage.getItem("CorporateRepresentative_name1") != null) {
          changeFunc(
            sessionStorage.getItem("CorporateRepresentative_name1"),
            "CorporateRepresentative_name1"
          );
        }

        if (sessionStorage.getItem("CorporateRepresentative_name2") != null) {
          changeFunc(
            sessionStorage.getItem("CorporateRepresentative_name2"),
            "CorporateRepresentative_name2"
          );
        }

        if (sessionStorage.getItem("CorporateRepresentative_name3") != null) {
          changeFunc(
            sessionStorage.getItem("CorporateRepresentative_name3"),
            "CorporateRepresentative_name3"
          );
        }

        if (sessionStorage.getItem("CorporateRepresentative_name4") != null) {
          changeFunc(
            sessionStorage.getItem("CorporateRepresentative_name4"),
            "CorporateRepresentative_name4"
          );
        }

        if (sessionStorage.getItem("director_name1") != null) {
          changeFunc(
            sessionStorage.getItem("director_name1"),
            "director_name1"
          );
        }
        if (sessionStorage.getItem("director_name2") != null) {
          changeFunc(
            sessionStorage.getItem("director_name2"),
            "director_name2"
          );
        }
        if (sessionStorage.getItem("director_name3") != null) {
          changeFunc(
            sessionStorage.getItem("director_name3"),
            "director_name3"
          );
        }
        if (sessionStorage.getItem("director_name4") != null) {
          changeFunc(
            sessionStorage.getItem("director_name4"),
            "director_name4"
          );
        }
        if (sessionStorage.getItem("shareholder_name1") != null) {
          changeFunc(
            sessionStorage.getItem("shareholder_name1"),
            "shareholder_name1"
          );
        }
        if (sessionStorage.getItem("shareholder_name2") != null) {
          changeFunc(
            sessionStorage.getItem("shareholder_name2"),
            "shareholder_name2"
          );
        }
        if (sessionStorage.getItem("shareholder_name3") != null) {
          changeFunc(
            sessionStorage.getItem("shareholder_name3"),
            "shareholder_name3"
          );
        }
        if (sessionStorage.getItem("shareholder_name4") != null) {
          changeFunc(
            sessionStorage.getItem("shareholder_name4"),
            "shareholder_name4"
          );
        }

        if (sessionStorage.getItem("secratory_name1") != null) {
          changeFunc(
            sessionStorage.getItem("secratory_name1"),
            "secratory_name1"
          );
        }

        if (sessionStorage.getItem("secratory_name2") != null) {
          changeFunc(
            sessionStorage.getItem("secratory_name2"),
            "secratory_name2"
          );
        }

        if (sessionStorage.getItem("secratory_name3") != null) {
          changeFunc(
            sessionStorage.getItem("secratory_name3"),
            "secratory_name3"
          );
        }

        if (sessionStorage.getItem("secratory_name4") != null) {
          changeFunc(
            sessionStorage.getItem("secratory_name4"),
            "secratory_name4"
          );
        }
      }

      if (
        props.defaultFields.officebearerdata &&
        props.defaultFields.officebearerdata?.length > 0
      ) {
        setDirectorList(props.defaultFields.officebearerdata);

        props.defaultFields.officebearerdata.map((key, inx) => {
          var jinx = inx + 1;
          var directorfield = "director_name" + jinx;
          sessionStorage.setItem(directorfield, key.name);
        });
      }

      // if (
      //   props.defaultFields.officebearerdata &&
      //   props.defaultFields.officebearerdata?.length > 0
      // ) {
      //   setDirectorList(props.defaultFields.officebearerdata);

      //   props.defaultFields.officebearerdata.map((key, inx) => {
      //     var jinx = inx + 1;
      //     var directorfield = "director_name" + jinx;
      //     sessionStorage.setItem(directorfield, key.name);
      //   });
      // }

      if (
        props.defaultFields.officebearerdata_shareholder &&
        props.defaultFields.officebearerdata_shareholder?.length > 0
      ) {
        setShareHolderList(props.defaultFields.officebearerdata_shareholder);

        props.defaultFields.officebearerdata_shareholder.map((key, inx) => {
          var jinx = inx + 1;
          var directorfield = "shareholder_name" + jinx;
          sessionStorage.setItem(directorfield, key.name);
        });
      }

      if (
        props.defaultFields.officebearerdata_secratory &&
        props.defaultFields.officebearerdata_secratory?.length > 0
      ) {
        setSecratoryList(props.defaultFields.officebearerdata_secratory);

        props.defaultFields.officebearerdata_secratory.map((key, inx) => {
          var jinx = inx + 1;
          var directorfield = "secratory_name" + jinx;
          sessionStorage.setItem(directorfield, key.name);
        });
      }

      if (
        props.defaultFields.officebearerdata_corporaterepresentative &&
        props.defaultFields.officebearerdata_corporaterepresentative?.length > 0
      ) {

        setCorporateRepresentativeList(
          props.defaultFields.officebearerdata_corporaterepresentative
        );
   
        props.defaultFields.officebearerdata_corporaterepresentative.map(
          (key, inx) => {
            var jinx = inx + 1;
            var directorfield = "corporate_representative" + jinx;
            sessionStorage.setItem(directorfield, key.name);
          }
        );
      }
    }
    // setCorporateRepresentativeList

   
    // assignDefaultFieldValues();
    if (props.editDocumentModal == true) {
      assignDefaultFieldValues();
    }


    setValidObj(validData)


  }, [props.defaultFields]);
  console.log('props.defaultFields: ', props.defaultFields);

  
  
  const changeFunc = (e, val) => {
  

   


    let placeholder_list = {};

    if (val != undefined && val != null) {
      obj[val] = e;

      setObj({ ...obj });
    }
  };

  // const addData = (e, val) => {

  //   if (val != undefined && val != null) {
  //     obj[val] = e;

  //     let extra = { value: e };

  //     setSaveData((prevSaveData) =>
  //       Array.isArray(prevSaveData) ? [...prevSaveData, extra] : [extra]
  //     );
  //   }
  // };


  const addData = (e, val) => {



    
  

    if (val === "shareholder_name1") {
      const shareholder = props.shareHolderList.find(
        (item) => String(item.name) === String(e)
      );
    
      if (shareholder) {
        const id_obj = shareholder.identification_no;
        let e = "identification_no"
        let val = id_obj

        sessionStorage.setItem(
          "Identification_no1",
          id_obj
        );


        changeFunc(sessionStorage.getItem("Identification_no1"), "Identification_no1");

      
      } else {
        console.warn("No shareholder found with the name: ", e);
      }
    }
    
    
  
    sessionStorage.setItem(
      `${val}`,
      e
    );
  
    const validNames = ['name1', 'name2', 'name3', 'name4'];
  
    if (validNames.some((name) => val.toLowerCase().includes(name))) {
      validObj.forEach(item => {
        if (val.toLowerCase().includes(item.name.toLowerCase())) {
          item.value = e;  
        }
      });
  

    }


    if (val != undefined && val != null) {
      const obj = {};  
      obj[val] = e;
  
      let extra = { value: e };
  
      setSaveData((prevSaveData) =>
        Array.isArray(prevSaveData) ? [...prevSaveData, extra] : [extra]
      );
    }
  };
  


  const changeSignFunc = (e, val) => {
    let placeholder_list = {};
    if (val != undefined && val != null) {
      signObj[val] = e;
      setSignObj({ ...signObj });
    }
  };

  const saveChangesFunc = (e) => {
    e.preventDefault();
    let input = {
      document_type: props.selectDocumentType,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 1,
      htmldata: edited,
      placeholder_list: obj,
      sign_placeholder_list: signObj,

      selectedid: props.selectedid,
      
    };

    let editInput = {
      document_id: props.document_id,
      document_type: props.selectDocumentType,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      attachment_keyword: "",
      notes: "",
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 1,
      htmldata: edited,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
      selectedid: props.selectedid,
    };

    if (props.editDocumentModal == true) {
      props.editDocument(editInput, "draft", props.tabSelected);
    } else {


      props.sendAndSaveDocument(
        input,
        "draft",
        props.tabSelected,
        templateData,
        temName
      );
    }
  };

  const previewFunc = (e) => {
  
    for (let i = 0; i < validObj.length; i++) {
      if (validObj[i].value === "") {
        alert("Please fill all fields and click the preview");
        return;
      }
    }
  
    setLoading(true);
  
    // Set a timeout to stop loading after 30 seconds
  
  
    let input = {
      document_type: props.selectDocumentType,
      generate_doc: true,
      file_url: props.docPreview,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
    };
  
    if (props.selectDocumentType !== "") {
  
      props.sendDocTypeAndGetDocument(props.selectDocumentType, setRendr)
        .then(() => {
            setUrl2("");  // Clear the URL
            previewFunc2();  // Call the preview function after the API call finishes
        })
        .catch(err => {
            console.error("Error in sendDocTypeAndGetDocument:", err);
        });
  
     
    } else {
      // setLoading(false);

    setTimeout(() => {
      setLoading(false);
      console.log('Loading stopped due to timeout');
    }, 10000);
      previewFunc2();
    }
  
    // props.getDocumentPreview(input, setRendr);
  };
  
  

  useEffect(() => {
    if (url2 == "" && props.selectDocumentType !== "" && !_.isEmpty(obj)) {
      previewFunc2();
      setUrl2("URLUpdated");
    }
  }, [props.docPreview]);






  const previewFunc2 = () => {


    if (props.docPreview) {
      setLoading(true);
      let input = {
        document_type: props.selectDocumentType,
        generate_doc: true,
        file_url: props.docPreview,
        placeholder_list: obj,
        sign_placeholder_list: signObj,
      };
      props.getDocumentPreview(input, setRendr, setLoading);

      setTimeout(() => {
        setLoading(false);
      }, 10000); 
    }
  };



  const sendNewFuncMultipledocument = () => {
    setTimeout(() => {
      let ass = Object.values(obj);
      let allids = props.to;
      allids.forEach((element) => {
        let id = element.value;
        let check = ass.find((ele) => {
          return ele === "";
        });

        let check2 = false;
        if (signObj) {
          let ass1 = Object.values(signObj);
          let findErr = ass1.find((ele) => {
            return ele === "";
          });
          check2 = findErr ? false : true;
        } else {
          check2 = true;
        }
        // if (check == undefined) {
        let input = {
          document_type: id,
          user_id: sessionStorage.getItem("login_userid"),
          client_id: sessionStorage.getItem("personalId"),
          status: 5,
          placeholder_list: obj,
          sign_placeholder_list: signObj,
          transferor_list: transferor,
          receiver_list: receivers,
          shareholdername_list: shareHolder,
          selectedid: props.selectedid,
        };
        props.sendAndSaveDocument_multiple(input, "save", props.tabSelected);
      });
    }, 1000);
  };

  const sendNewFunc = (e) => {


    for (let i = 0; i < validObj.length; i++) {
      if (validObj[i].value === "") {
        alert("Please fill all fields and click the preview");
        return; 
      }

  }


  



    e.preventDefault();

    let ass = Object.values(obj);

    let check = ass.find((ele) => {
      return ele === "";
    });

    let check2 = false;
    if (signObj) {
      let ass1 = Object.values(signObj);
      let findErr = ass1.find((ele) => {
        return ele === "";
      });
      check2 = findErr ? false : true;
    } else {
      check2 = true;
    }
    // if (check == undefined) {



    let input = {
      document_type: props.selectDocumentType,
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 6,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      selectedid: props.selectedid,
      isCreateTemplate: isCreateTemplate,
    };

    if (props.selectDocumentType === 937) {
      let other_input = {
        document_type: props.selectDocumentType,
        user_id: sessionStorage.getItem("login_userid"),
        client_id: sessionStorage.getItem("personalId"),
        status: 6,
        placeholder_list: obj,
        sign_placeholder_list: signObj,
        transferor_list: transferor,
        receiver_list: receivers,
        shareholdername_list: shareHolder,
        selectedid: props.selectedid,
        isCreateTemplate: isCreateTemplate,
        sign_name_list: markers,
        email: email,
        file: document,
      };

     

      let originalFileName = "";

      const formData = new FormData();


      originalFileName =  othetName;


  


  



      if (!(finalFile instanceof Blob)) {
        alert("Preview the File After Submitting");
        return;
      }

      formData.append("file", orgFile, originalFileName);
      formData.append("document_type", other_input.document_type);
      formData.append("user_id", other_input.user_id);
      formData.append("client_id", other_input.client_id);
      formData.append("status", other_input.status);
      formData.append("emails", JSON.stringify(other_input.email));
      formData.append(
        "placeholder_list",
        JSON.stringify(other_input.placeholder_list)
      );
      formData.append(
        "sign_placeholder_list",
        JSON.stringify(other_input.sign_placeholder_list)
      );
      formData.append(
        "transferor_list",
        JSON.stringify(other_input.transferor_list)
      );
      formData.append(
        "receiver_list",
        JSON.stringify(other_input.receiver_list)
      );
      formData.append(
        "shareholdername_list",
        JSON.stringify(other_input.shareholdername_list)
      );
      formData.append("selectedid", other_input.selectedid);
      formData.append("isCreateTemplate", other_input.isCreateTemplate);
      formData.append(
        "sign_name_list",
        JSON.stringify(other_input.sign_name_list)
      );
      formData.append("save", "save");
      formData.append("tabSelected", props.tabSelected);
      formData.append("temName", temName);

      const dummy = "save";
      // val, dummy, tab, templateData, temName

      fetch(config.otherSendAndSaveDocument, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.status === 1) {
            dispatch(documentFunction("transferOfShareModal", false));
            dispatch(documentFunction("document_id", responseData.document_id));
            dispatch(actions.multipleidArray(responseData.document_id));
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 1));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
              if (dummy == "save") {
                dispatch(documentFunction("sendRequestModal", true));
              }
              if (props.tabSelected == "ALL") {
                dispatch(actions.getDocumentList());
              } else if (props.tabSelected == "Pending") {
                dispatch(actions.getDocumentList(1));
              } else if (props.tabSelected == "Received") {
                dispatch(actions.getDocumentList(2));
              }
            }, 2500);
          }
        })

        .catch((error) => {
          console.error("Error:", error);
        });
    } else {

      

      console.log('input: ', input);



      props.sendAndSaveDocument(
        input,
        "save",
        props.tabSelected,
        templateData,
        temName
       
      );
    }


  };







  // const nextFunction = (e) => {

  //   setReload(!reload)
  //   console.log('reload:-----------------> 1 ', reload);


  //   e.preventDefault();

  //   for (let i = 0; i < validObj.length; i++) {
  //     if (validObj[i].value === "") {
  //       alert("Please fill all fields and click the preview");
  //       return; 
  //     }
  // }

  //   assignDefaultFieldValues()

  //   let allids = props.to;
  //   let currentindex = props.nextValue - 1;
  //   let newIndex = currentindex + 1;
  //   let increaseIndex = currentindex + 2;
  //   let documentid = allids[currentindex].value;
  //   let ass = Object.values(obj);
  //   let check = ass.find((ele) => {
  //     return ele === "";
  //   });

  //   let check2 = false;
  //   if (signObj) {
  //     let ass1 = Object.values(signObj);
  //     let findErr = ass1.find((ele) => {
  //       return ele === "";
  //     });
  //     check2 = findErr ? false : true;
  //   } else {
  //     check2 = true;
  //   }
  //   // if (check == undefined) {
  //   let input = {
  //     document_type: documentid,
  //     user_id: sessionStorage.getItem("login_userid"),
  //     client_id: sessionStorage.getItem("personalId"),
  //     status: 6,
  //     placeholder_list: obj,
  //     sign_placeholder_list: signObj,
  //     transferor_list: transferor,
  //     receiver_list: receivers,
  //     shareholdername_list: shareHolder,
  //     selectedid: props.selectedid,
  //   };
  //   props.sendValues("nextValue", increaseIndex);

    
  //   // props.sendAndSaveDocumentNext(
  //   //   input,
  //   //   "save",
  //   //   props.tabSelected,
  //   //   templateData,
  //   //   temName
  //   // );

  //   setReload(!reload)
   
    
  //   if (props.nextValue < props.to?.length) {
  //     if (saveData.length > 0) {
  //       setSaveData([]);
  //     }

  //     props.getDefaultPlaceHolder(allids[newIndex].value);
  //     props.sendValues("selectDocumentType", allids[newIndex].value);
  //     previewFunc2();
  //   }

  //   setSaveData([]);
  //   console.log('reload:-----------------> 2 ', reload);
  // };



  const nextFunction = (e) => {
    e.preventDefault();
  
    // Toggle reload to true
    setReload(true);
    console.log("reload:-----------------> 1 ", reload);
  
    
    setTimeout(() => {
      setReload(false);
      console.log("reload:-----------------> 3 ", reload);
    }, 3000);
  
    for (let i = 0; i < validObj.length; i++) {
      if (validObj[i].value === "") {
        alert("Please fill all fields and click the preview");
        return;
      }
    }
  
    assignDefaultFieldValues();
  
    let allids = props.to;
    let currentindex = props.nextValue - 1;
    let newIndex = currentindex + 1;
    let increaseIndex = currentindex + 2;
    let documentid = allids[currentindex].value;
    let ass = Object.values(obj);
    let check = ass.find((ele) => ele === "");
  
    let check2 = false;
    if (signObj) {
      let ass1 = Object.values(signObj);
      let findErr = ass1.find((ele) => ele === "");
      check2 = findErr ? false : true;
    } else {
      check2 = true;
    }
  
    let input = {
      document_type: documentid,
      user_id: sessionStorage.getItem("login_userid"),
      client_id: sessionStorage.getItem("personalId"),
      status: 6,
      placeholder_list: obj,
      sign_placeholder_list: signObj,
      transferor_list: transferor,
      receiver_list: receivers,
      shareholdername_list: shareHolder,
      selectedid: props.selectedid,
    };
    props.sendValues("nextValue", increaseIndex);


   props.sendAndSaveDocumentNext(
      input,
      "save",
      props.tabSelected,
      templateData,
      temName
    );




  
    if (props.nextValue < props.to?.length) {
      if (saveData.length > 0) {
        setSaveData([]);
      }
  
      props.getDefaultPlaceHolder(allids[newIndex].value);
      props.sendValues("selectDocumentType", allids[newIndex].value);
      previewFunc2();
    }
  
    setSaveData([]);
  };
  




  const receiversAddFunc = () => {
    let arr = [...receivers];
    let add = { receiver_name: "", no_of_shares_transfered: "" };
    arr.push(add);
    setReceivers([...arr]);
  };

  const removeReceiverFunc = (ind) => {
    let arr = [...receivers];
    if (arr?.length > 1) {
      arr.splice(ind, 1);
      setReceivers([...arr]);
    }
  };

  const addReceiverDetails = (e, idx) => {
    let arr = [...receivers];
    arr[idx][e.target.name] = e.target.value;
    setReceivers([...arr]);
  };

  const addTransferorDetails = (e) => {
    let obj = { ...transferor };
    obj[e.target.name] = e.target.value;
    setTransferor({ ...obj });
  };

  const addShareHolder = () => {
    let arr = [...shareHolder];
    let add = { shareholder_name: "" };
    arr.push(add);
    setShareHolder([...arr]);
  };

  const addShareHolderDetails = (e, idx) => {
    let arr = [...shareHolder];
    arr[idx][e.target.name] = e.target.value;
    setShareHolder([...arr]);
  };

  const removeShareHoderFunc = (idx) => {
    let arr = [...shareHolder];
    if (arr?.length > 1) {
      arr.splice(idx, 1);
      setShareHolder([...arr]);
    }
  };

  {
    /* ============================================== ==============================================  ==============================================  */
  }

  const [renderedPages, setRenderedPages] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfDoc, setPdfDoc] = useState(null);

  const [orgFile, setOrgFile] = useState(null);


  const [uploadFile, setUploadFile] = useState(null);


  useEffect(() => {
    console.log('orgFile: ', orgFile);
     
    }, [orgFile]);

  const handleFileUpload = async (event) => {
    setDis(true)
   
    setUploadFile(null)
    const file = event.target.files[0];
    setUploadFile(file);

    if (file && file.type === "application/pdf") {
     

      setOthetName(file.name)


      const fileUrl = URL.createObjectURL(file);



      setPdfFile(file);
      setPdfUrl(fileUrl);
    setOrgFile(file)



      setOthetName(file.name)

      const fileBytes = await file.arrayBuffer();
      const loadedPdfDoc = await PDFDocument.load(fileBytes);
      setPdfDoc(loadedPdfDoc);
      
      

      renderPdfPages(fileUrl); 
    } else {
      alert("Please upload a valid PDF file.");
    }
  };





  const handleDesignationChange = (event) => {
    const newDesignation = event.target.value;
    setSelectedDesignation(newDesignation);
    setSelectedOption("");
    setSelectedName("");
    setNewMarkerLabel("");

    if (newDesignation === "Director") {
      setOptionsNames(directorList);
    } else if (newDesignation === "Shareholder") {
      setOptionsNames(shareHolderList);
    } else if (newDesignation === "Secretary") {
      setOptionsNames(secratoryList);
    }
  };

  const handleOptionChange = (event) => {
    const selectedOptionValue = event.target.value;
    setSelectedOption(selectedOptionValue);

    const selectedOption = optionsByDesignation[selectedDesignation]?.find(
      (option) => option.value === selectedOptionValue
    );

    updateMarkerLabel(selectedOptionValue, selectedName);

    if (selectedOption) {
      setSelectedSign(selectedOption.sign);
    }
  };

  const handleNameChange = (event) => {
    const selectedNameValue = event.target.value;
    const selectedPerson = optionsNames?.find(
      (option) => option.name === selectedNameValue
    );
    const select_email = selectedPerson?.email;
    setSelectEmail(select_email?.email);

    setEmail((prevEmails) => {
      const emailsArray = Array.isArray(prevEmails) ? prevEmails : [];
      const emailExists = emailsArray.some(
        (emailObj) => emailObj.email === select_email
      );

      if (!emailExists && select_email) {
        return [...emailsArray, { email: select_email, signed: false }];
      }

      return emailsArray;
    });

    setSelectedName(selectedNameValue);
    updateMarkerLabel(selectedOption, selectedNameValue);
  };

 

  const updateMarkerLabel = (option, name) => {
    if (selectedDesignation && option && name) {
      const selectObject = {
        name: name,
        value: option,
      };

      setPersons((prevPersons) => {
        const existingPersonIndex = prevPersons.findIndex(
          (person) => person.value === option
        );

        if (existingPersonIndex !== -1) {
          const updatedPersons = [...prevPersons];
          updatedPersons[existingPersonIndex] = selectObject;
          return updatedPersons;
        }

        return [...prevPersons, selectObject];
      });

      setNewMarkerLabel(`${option} - ${name}`);
    }
  };

  const handleAddMarkerButtonClick = () => {
    setIsAddingMarker(true);
  };


  const handleRemoveMarker = (id) => {
    const updatedMarkers = markers.filter((marker) => marker.id !== id);
    setMarkers(updatedMarkers); // Update state with the filtered list
    handleSavePdf(updatedMarkers); // Save the filtered list
  };
  

// const savePdfWithMarkers = async () => {
  



//   if (!pdfDoc && !orgFile) {
//     alert("Please upload a PDF file first.");
//     return;
//   }

//   try {
//     const pages = orgFile.getPages();
//     markers.forEach((marker) => {
//       const page = pages[marker.pageIndex];
//       const { x, y } = marker;

//       page.drawText("X", {
//         x: x - 5,
//         y: y - 5,
//         size: 12,
//         width:20,
//         height:20,
//         color: rgb(1, 0, 0),
//       });

//       // page.drawText(marker.label, {
//       //   x: x + 10,
//       //   y: y - 5,
//       //   width: 20,
//       //   height: 20,
//       //   size: 18,
//       //   color: rgb(0, 0, 0),
//       // });
//     });

//     const pdfBytes = await pdfDoc.save();
//     const blob = new Blob([pdfBytes], { type: "application/pdf" });
//     const updatedPdfUrl = URL.createObjectURL(blob); 

//     window.open(updatedPdfUrl, "_blank");
//     // window.open(updatedPdfUrl, "_blank");

//     // setTimeout(() => {
//     //   if (blob) {
//     //     setFinalFile(blob)
//     //     sendNewFunc(new Event("submit")); 
//     //   } else {
        
//     //     alert("Review the Document After Submit");
//     //   }
//     // }, 0);
//   } catch (error) {
//     console.error("Error saving PDF with markers:", error);
//     if (
//       window.confirm(
//         "An error occurred while saving the PDF. Do you want to reload the page?"
//       )
//     ) {
//       window.location.reload();
//     }
//   }
// };

  const type =
    props?.defaultFields?.documentType?.length > 0
      ? props.defaultFields.documentType[0].code
      : "";






  // Render PDF pages as canvases using pdfjs-dist
  const renderPdfPages = async (fileUrl) => {

    console.log('uploadFile: ----------->', uploadFile);

    const loadingTask = pdfjsLib.getDocument(fileUrl);
    const pdf = await loadingTask.promise;
    const pages = [];

    for (let pageIndex = 0; pageIndex < pdf.numPages; pageIndex++) {
      const page = await pdf.getPage(pageIndex + 1);
      const viewport = page.getViewport({ scale: 1 });

      pages.push({
        index: pageIndex,
        width: viewport.width,
        height: viewport.height,
        renderPage: (canvas) => {
          const context = canvas.getContext("2d");
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const renderContext = {
            canvasContext: context,
            viewport,
          };

          page
            .render(renderContext)
            .promise.then(() => {
              
        
            })
            .catch((error) => {
              console.error("Error rendering page:", error);
            });
        },
      });
    }

    setRenderedPages(pages);
  };

  // Add marker interactively
  const handleCanvasClick = (event, pageIndex, width, height) => {
    if (!isAddingMarker || !newMarkerLabel.trim()) return;
  
    const rect = event.target.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / rect.width) * width;
    const y = height - ((event.clientY - rect.top) / rect.height) * height;


    const signMarkerLetter = `{{${selectedSign}}}`;

    const selectedPerson = optionsNames?.find(
      (option) => option.name === selectedName
    );
    const select_email = selectedPerson?.email;
  
    const newMarker = {
      id: Date.now(),
      x,
      y,
      label: newMarkerLabel,
      role: selectedDesignation,
      designation: selectedOption,
      name: selectedName,
      signature: signMarkerLetter,
      pageIndex,
      email: select_email,
    };

   

  
    setMarkers((prev) => {
      const updatedMarkers = [...prev, newMarker];
      console.log("Updated Markers: ", updatedMarkers);
      return updatedMarkers;
    });

    // const updatedMarkers = [
    //   {
    //     "id": 1734422385294,
    //     "label": "director_name1 - Shahi Suvarna",
    //     "pageIndex": 0,
    //     "x": 185.3941368078176,
    //     "y": 715.3030541561712
    //   },
    //   {
    //     "id": 1734422412090,
    //     "label": "shareholder_name1 - Jayanthi",
    //     "pageIndex": 0,
    //     "x": 192.371335504886,
    //     "y": 669.4189231738035
    //   }
    // ];

    // setMarkers(updatedMarkers);
  
    // Reset marker input state
    setIsAddingMarker(false);
    setNewMarkerLabel("");
  
    // Save PDF with updated markers
    handleSavePdf([...markers, newMarker]); // Pass the latest marker list
  };
  

  // Save the updated PDF with markers
//   const handleSavePdf = async (updatedMarkers) => {
//     // setDis(false)

    

// console.log("step-------->1");

//     if (!pdfDoc && !orgFile) {
//       alert("Please upload a PDF file first.");
//       return;
//     }
  
// console.log("step-------->  2");

// setPdfDoc(orgFile)

//     try {


// console.log("step-------->  3");

//       const pages = pdfDoc.getPages();


//       console.log("step-------->  4  loop markers", updatedMarkers);


//       updatedMarkers.forEach((marker) => {
//         console.log("step-------->    loop", marker);

//         const page = pages[marker.pageIndex];
//         const { x, y, label } = marker;



//         page.drawText("X", {
//           x: x - 5,
//           y: y - 5,
//           size: 12,
//           color: rgb(1, 0, 0),
//         });

//         page.drawText(label, {
//           x: x + 10,
//           y: y - 5,
//           size: 10,
//           color: rgb(0, 0, 0),
//         });
//       });


//       console.log("step-------->  5");
//       const pdfBytes = await pdfDoc.save();
//       console.log("step-------->  6");
//       const blob = new Blob([pdfBytes], { type: "application/pdf" });
//       console.log("step-------->  7");
//       const updatedPdfUrl = URL.createObjectURL(blob);
//       console.log("step-------->  8");
   
//       setPdfUrl(updatedPdfUrl);
//       console.log("step-------->  9");
//       renderPdfPages(updatedPdfUrl);
//       console.log("step-------->  complete");
//     } catch (error) {
//       console.error("Error saving PDF:", error);
//       alert("An error occurred while saving the PDF.");
//     }
//   };


// Save the updated PDF with markers
const handleSavePdf = async (updatedMarkers) => {
  if (!orgFile) {
    alert("Please upload a PDF file first.");
    return;
  }

  try {
    console.log("Loading original file...");
    const fileBytes = await orgFile.arrayBuffer(); // Load the original file's bytes
    const originalPdfDoc = await PDFDocument.load(fileBytes); // Load the original document

    console.log("Adding markers...");
    const pages = originalPdfDoc.getPages();

    updatedMarkers.forEach((marker) => {
      const { pageIndex, x, y, label } = marker;
      const page = pages[pageIndex];

      // Draw marker position (e.g., "X") and label
      page.drawText("X", {
        x: x - 5,
        y: y - 5,
        size: 12,
        color: rgb(1, 0, 0), // Red for the marker
      });

      // page.drawText(label, {
      //   x: x + 10,
      //   y: y - 5,
      //   size: 10,
      //   color: rgb(0, 0, 0), // Black for the label
      // });
    });

    console.log("Saving updated PDF...");
    const pdfBytes = await originalPdfDoc.save(); // Save updated PDF bytes
    const blob = new Blob([pdfBytes], { type: "application/pdf" }); // Create a Blob
    const updatedPdfUrl = URL.createObjectURL(blob); // Generate a new URL for the updated PDF

    // Update the state with the new URL and render the updated PDF
    setFinalFile(blob)
    setPdfUrl(updatedPdfUrl);
    renderPdfPages(updatedPdfUrl);


    setSelectedDesignation("");
    setSelectedOption("");
    setSelectedName("");

    console.log("PDF updated successfully.");
  } catch (error) {
    console.error("Error saving PDF:", error);
    alert("An error occurred while saving the PDF.");
  }
};


const savePdfWithMarkers = async () => {
  try {


    if (!orgFile) {
      alert("Please upload a PDF file first.");
      return;
    }
  
  
      console.log("Loading original file...");
      const fileBytes = await orgFile.arrayBuffer(); // Load the original file's bytes
      const originalPdfDoc = await PDFDocument.load(fileBytes); // Load the original document
  
      console.log("Adding markers...");
      const pages = originalPdfDoc.getPages();
  
      markers.forEach((marker) => {
        const { pageIndex, x, y, label } = marker;
        const page = pages[pageIndex];
  
        // Draw marker position (e.g., "X") and label
        page.drawText("X", {
          x: x - 5,
          y: y - 5,
          size: 12,
          color: rgb(1, 0, 0), // Red for the marker
        });
  
  
      });
  
      console.log("Saving updated PDF...");
      const pdfBytes = await originalPdfDoc.save(); // Save updated PDF bytes
      const blob = new Blob([pdfBytes], { type: "application/pdf" }); // Create a Blob
      const updatedPdfUrl = URL.createObjectURL(blob); // Generate a new URL for the updated PDF
  
      // Update the state with the new URL and render the updated PDF
      setFinalFile(blob)
      setPdfUrl(updatedPdfUrl);
      renderPdfPages(updatedPdfUrl);













    if (!finalFile) {
      alert("Please review the document before submitting.");
      return;
    }

    console.log("Submitting document...");
    // Trigger submission
    sendNewFunc(new Event("submit"));

    console.log("Document submitted successfully.");
  } catch (error) {
    console.error("Error during submission:", error);

    // Prompt user to reload if an error occurs
    const reload = window.confirm(
      "An error occurred while submitting the document. Do you want to reload the page?"
    );
    if (reload) {
      window.location.reload();
    }
  }
};



// Refresh the editor to its initial state
const handleRefresh = () => {
  setPdfFile(null);
  setPdfDoc(null);
  setPdfUrl("");
  setOrgFile(null);
  setRenderedPages([]);
  setMarkers([]);
  setIsAddingMarker(false);
  setNewMarkerLabel("");
};






  {
    /* ============================================== ==============================================  ==============================================  */
  }


  return (
    <Modal
      show={props.transferOfShareModal}
      id="myModal"
      className="modal fade in"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-dialog modal-xl"
      style={{ display: "block" }}
    >
      <div className="">
        <div className="modal-content ">
          <div className="modal-header ">
            {/* <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button> */}
            <h4
              className=""
              style={{ fontSize: "18px", color: "black", marginBottom: "10px" }}
            >
              <span>Update Document Placeholder Values of - </span>
              {/* <span className="modal-title blue-text text-bold">{props?.documentList[0]?.documentType}</span> */}

              <span className="modal-title blue-text text-bold"> {type}</span>
            </h4>
          </div>
          <div className="modal-body pb-0">
         
            <div className="row">
              <div className="col-sm-6 border-right-gray">
             
              {reload ?
               (
                <>
                {props.selectDocumentType !== 937 ? (
                  
                  <>
                    {props.defaultFields &&
                      props.defaultFields.placeholderList?.map((fie, idx) => {
                        

                        


                        let companynamevalue = "";

                        if (fie.Name == "company_name") {
                          companynamevalue =
                            sessionStorage.getItem("companyname");
                        }
                        if (fie.Name == "address") {
                          companynamevalue = sessionStorage.getItem("address");
                        }

                        if (fie.Name == "Address1") {
                          companynamevalue = sessionStorage.getItem("Address1");
                        }

                        

                        
                        if (fie.Name == "current_date") {
                          companynamevalue =
                            sessionStorage.getItem("current_date");
                        }
                        if (fie.Name == "registration_no") {
                          companynamevalue =
                            sessionStorage.getItem("registration_no");
                        }
                        if (fie.Name == "FinancialYearEnd_date") {
                          
                          companynamevalue =
                            sessionStorage.getItem("FinancialYearEnd_date");
                        }
                        if (fie.Name == "Resolution_date") {
                          
                          companynamevalue =
                            sessionStorage.getItem("Resolution_date");
                        }
                        if (fie.Name == "Filing_date") {
                          
                          companynamevalue =
                            sessionStorage.getItem("Filing_date");
                        }

                        

                        if (fie.Name == "convened_date") {
                          
                          companynamevalue =
                            sessionStorage.getItem("convened_date");
                        }
                       


                        if (fie.Name == "CorporateRepresentative_name1") {
                          companynamevalue = sessionStorage.getItem(
                            "corporate_representative1"
                          );

                          
                        }

                        if (fie.Name == "Identification_no1") {
                          companynamevalue = sessionStorage.getItem(
                            "Identification_no1"
                          );

                          
                        }
                        if (fie.Name == "CorporateRepresentative_name2") {

                          companynamevalue = sessionStorage.getItem(
                            "corporate_representative2"
                          );
                        }

                        if (fie.Name == "CorporateRepresentative_name3") {

                          companynamevalue = sessionStorage.getItem(
                            "corporate_representative3"
                          );
                        } 
                        if (fie.Name == "CorporateRepresentative_name4") {

                          companynamevalue = sessionStorage.getItem(
                            "corporate_representative4"
                          );
                        }                    
                           if (fie.Name == "director_name1") {
                          companynamevalue =
                            sessionStorage.getItem("director_name1");
                        }
                        if (fie.Name == "shareholder_name1") {
                          companynamevalue =
                            sessionStorage.getItem("shareholder_name1");
                        }
                        if (fie.Name == "shareholder_name2") {
                          companynamevalue =
                            sessionStorage.getItem("shareholder_name2");
                        }
                        if (fie.Name == "shareholder_name3") {
                          companynamevalue =
                            sessionStorage.getItem("shareholder_name3");
                        }
                        if (fie.Name == "shareholder_name4") {
                          companynamevalue =
                            sessionStorage.getItem("shareholder_name4");
                        }
                        if (fie.Name == "secratory_name1") {
                          companynamevalue =
                            sessionStorage.getItem("secratory_name1");
                        }
                        if (fie.Name == "secratory_name2") {
                          companynamevalue =
                            sessionStorage.getItem("secratory_name2");
                        }
                        if (fie.Name == "secratory_name3") {
                          companynamevalue =
                            sessionStorage.getItem("secratory_name3");
                        }
                        if (fie.Name == "secratory_name4") {
                          companynamevalue =
                            sessionStorage.getItem("secratory_name4");
                        }
                        if(fie.Name.includes("director_name") ||  fie.Name.includes("CorporateRepresentative_name") || fie.Name.includes("shareholder_name") ||  fie.Name.includes("secratory_name") ){
                          companynamevalue = "";

                          console.log('companynamevalue: ', fie.Name, companynamevalue);
                        }

                        return fie.Name.includes("Dev_rr") ? (
                          
                          <div className="form-group" key={idx}>
                            <label
                              htmlFor="companyname"
                              className="control-label col-sm-4 required"
                            >
                              {_.startCase(fie.Name)}
                              <p className="help-block ">({fie.Name}) </p>
                              {/* <p className="help-block text-right">(* Required) </p> */}
                            </label>
                            <div className="col-sm-8">
                              <select
                              ref={dropdownRef}
                                className="form-control"
                                onChange={(e) => {
                                  changeFunc(e.target.value, fie.Name),
                                    addData(e.target.value, fie.Name);
                                }}
                                required="required"
                                name="companyname"
                                id="companyname"
                                // defaultValue={companynamevalue ? companynamevalue : ""}
                                defaultValue={companynamevalue}
                              >
                                <option value="" >
          Choose a Director
        </option>
                                {directorList.map((data, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={data.name}
                                     
                                    >
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <p className="help-block text-right">
                                (* Required)
                              </p>
                            </div>
                          </div>
                        ) 
                        
                        
                        
                        : (
                          <div className="form-group" key={idx}>
                            <label
                              htmlFor="companyname"
                              className="control-label col-sm-4 required"
                            >
                              {_.startCase(fie.Name)}
                              <p className="help-block ">({fie.Name}) </p>
                              {/* <p className="help-block text-right">(* Required) </p> */}
                            </label>
                            <div className="col-sm-8">
                              <input
                                className="form-control"
                                required="required"
                                name="companyname"
                                type="text"
                                // value={obj[fie.Name]}
                                id={fie.Name}
                                onChange={(e) => {
                                  changeFunc(e.target.value, fie.Name),
                                    addData(e.target.value, fie.Name);
                                }}
                                value={companynamevalue}
                                disabled={fie.Name === "Identification_no1" ? true: false }
                              />
                              <p className="help-block text-right">
                                (* Required)
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </>
                ) : (
                  ""
                )}
                </>
              ):(
                <>
                 {props.selectDocumentType !== 937 ? (
                  
                  <>
                    {props.defaultFields &&
                      props.defaultFields.placeholderList?.map((fie, idx) => {
                        

                        


                        let companynamevalue = "";

                        if (fie.Name == "company_name") {
                          companynamevalue =
                            sessionStorage.getItem("companyname");
                        }
                        if (fie.Name == "address") {
                          companynamevalue = sessionStorage.getItem("address");
                        }

                        if (fie.Name == "Address1") {
                          companynamevalue = sessionStorage.getItem("Address1");
                        }

                        

                        
                        if (fie.Name == "current_date") {
                          companynamevalue =
                            sessionStorage.getItem("current_date");
                        }
                        if (fie.Name == "registration_no") {
                          companynamevalue =
                            sessionStorage.getItem("registration_no");
                        }
                        if (fie.Name == "FinancialYearEnd_date") {
                          
                          companynamevalue =
                            sessionStorage.getItem("FinancialYearEnd_date");
                        }
                        if (fie.Name == "Resolution_date") {
                          
                          companynamevalue =
                            sessionStorage.getItem("Resolution_date");
                        }
                        if (fie.Name == "Filing_date") {
                          
                          companynamevalue =
                            sessionStorage.getItem("Filing_date");
                        }

                        

                        if (fie.Name == "convened_date") {
                          
                          companynamevalue =
                            sessionStorage.getItem("convened_date");
                        }
                       


                        if (fie.Name == "CorporateRepresentative_name1") {
                          companynamevalue = sessionStorage.getItem(
                            "corporate_representative1"
                          );

                          
                        }

                        if (fie.Name == "Identification_no1") {
                          companynamevalue = sessionStorage.getItem(
                            "Identification_no1"
                          );

                          
                        }
                        if (fie.Name == "CorporateRepresentative_name2") {

                          companynamevalue = sessionStorage.getItem(
                            "corporate_representative2"
                          );
                        }

                        if (fie.Name == "CorporateRepresentative_name3") {

                          companynamevalue = sessionStorage.getItem(
                            "corporate_representative3"
                          );
                        } 
                        if (fie.Name == "CorporateRepresentative_name4") {

                          companynamevalue = sessionStorage.getItem(
                            "corporate_representative4"
                          );
                        }                    
                           if (fie.Name == "director_name1") {
                          companynamevalue =
                            sessionStorage.getItem("director_name1");
                        }
                        if (fie.Name == "shareholder_name1") {
                          companynamevalue =
                            sessionStorage.getItem("shareholder_name1");
                        }
                        if (fie.Name == "shareholder_name2") {
                          companynamevalue =
                            sessionStorage.getItem("shareholder_name2");
                        }
                        if (fie.Name == "shareholder_name3") {
                          companynamevalue =
                            sessionStorage.getItem("shareholder_name3");
                        }
                        if (fie.Name == "shareholder_name4") {
                          companynamevalue =
                            sessionStorage.getItem("shareholder_name4");
                        }
                        if (fie.Name == "secratory_name1") {
                          companynamevalue =
                            sessionStorage.getItem("secratory_name1");
                        }
                        if (fie.Name == "secratory_name2") {
                          companynamevalue =
                            sessionStorage.getItem("secratory_name2");
                        }
                        if (fie.Name == "secratory_name3") {
                          companynamevalue =
                            sessionStorage.getItem("secratory_name3");
                        }
                        if (fie.Name == "secratory_name4") {
                          companynamevalue =
                            sessionStorage.getItem("secratory_name4");
                        }
                        if(fie.Name.includes("director_name") ||  fie.Name.includes("CorporateRepresentative_name") || fie.Name.includes("shareholder_name") ||  fie.Name.includes("secratory_name") ){
                          companynamevalue = "";

                          console.log('companynamevalue: ', fie.Name, companynamevalue);
                        }

                        return fie.Name.includes("director_name") ? (
                          
                          <div className="form-group" key={idx}>
                            <label
                              htmlFor="companyname"
                              className="control-label col-sm-4 required"
                            >
                              {_.startCase(fie.Name)}
                              <p className="help-block ">({fie.Name}) </p>
                              {/* <p className="help-block text-right">(* Required) </p> */}
                            </label>
                            <div className="col-sm-8">
                              <select
                              ref={dropdownRef}
                                className="form-control"
                                onChange={(e) => {
                                  changeFunc(e.target.value, fie.Name),
                                    addData(e.target.value, fie.Name);
                                }}
                                required="required"
                                name="companyname"
                                id="companyname"
                                // defaultValue={companynamevalue ? companynamevalue : ""}
                                defaultValue={companynamevalue}
                              >
                                <option value="" >
          Choose a Director
        </option>
                                {directorList.map((data, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={data.name}
                                     
                                    >
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <p className="help-block text-right">
                                (* Required)
                              </p>
                            </div>
                          </div>
                        ) 
                        
                        
                        
                        : // ========================================================================================================
                        fie.Name.includes("CorporateRepresentative_name") ? (
                          <div className="form-group" key={idx}>
                            <label
                              htmlFor="companyname"
                              className="control-label col-sm-4 required"
                            >
                              {_.startCase(fie.Name)}

                              <p className="help-block ">({fie.Name}) </p>
                              {/* <p className="help-block text-right">(* Required) </p> */}
                            </label>
                            <div className="col-sm-8">
                              <select
                               ref={dropdownRef}
                                className="form-control"
                                onChange={(e) => {
                                  changeFunc(e.target.value, fie.Name),
                                    addData(e.target.value, fie.Name);
                                }}
                                required="required"
                                name="companyname"
                                id="companyname"
                                // defaultValue={companynamevalue}
                              >
                                <option value={""} >
                                  {" "}
                                  Choose a Corporate Representative{" "}
                                </option>
                                {corporateRepresentativeList.map((data, i) => {
                                 


                                  return (
                                    <option
                                      key={i}
                                      value={data.name}
                                      // selected={companynamevalue == data.name}
                                    >
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </select>

                              <p className="help-block text-right">
                                (* Required)
                              </p>
                            </div>
                          </div>
                        ) : // ========================================================================================================

                        fie.Name.includes("shareholder_name") ? (
                          <div className="form-group" key={idx}>
                            <label
                              htmlFor="companyname"
                              className="control-label col-sm-4 required"
                            >
                              {_.startCase(fie.Name)}
                              {/* <p className="help-block text-right">(* Required) </p> */}
                              <p className="help-block ">({fie.Name}) </p>
                            </label>
                            <div className="col-sm-8">
                              <select
                               ref={dropdownRef}
                                className="form-control"
                                onChange={(e) => {
                                  changeFunc(e.target.value, fie.Name),
                                  
                                    addData(e.target.value, fie.Name);
                                }}
                                required="required"
                                name="companyname"
                                id="companyname"
                                // defaultValue={companynamevalue}
                              >
                                <option value={""}> Choose a ShareHolder </option>
                                {shareHolderList.map((data, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={data.name}
                                      // selected={companynamevalue == data.name}
                                    >
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </select>

                              <p className="help-block text-right">
                                (* Required)
                              </p>
                            </div>
                          </div>
                        ) : fie.Name.includes("secratory_name") ? (
                          <div className="form-group" key={idx}>
                            <label
                              htmlFor="companyname"
                              className="control-label col-sm-4 required"
                            >
                              {_.startCase(fie.Name)}

                              {/* <p className="help-block text-right">(* Required) </p> */}
                            </label>
                            <div className="col-sm-8">
                              <select
                               ref={dropdownRef}
                                className="form-control"
                                onChange={(e) => {
                                  changeFunc(e.target.value, fie.Name),
                                    addData(e.target.value, fie.Name);
                                }}
                                required="required"
                                name="companyname"
                                id="companyname"
                                // defaultValue={companynamevalue}
                              >
                                <option value={""}> Choose a Secratory </option>
                                {secratoryList.map((data, i) => {
                                  return (
                                    <option
                                      value={data?.name}
                                      // selected={companynamevalue == data.name}
                                    >
                                      {data?.name}
                                    </option>
                                  );
                                })}
                              </select>

                              <p className="help-block text-right">
                                (* Required)
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="form-group" key={idx}>
                            <label
                              htmlFor="companyname"
                              className="control-label col-sm-4 required"
                            >
                              {_.startCase(fie.Name)}
                              <p className="help-block ">({fie.Name}) </p>
                              {/* <p className="help-block text-right">(* Required) </p> */}
                            </label>
                            <div className="col-sm-8">
                              <input
                                className="form-control"
                                required="required"
                                name="companyname"
                                type="text"
                                // value={obj[fie.Name]}
                                id={fie.Name}
                                onChange={(e) => {
                                  changeFunc(e.target.value, fie.Name),
                                    addData(e.target.value, fie.Name);
                                }}
                                value={companynamevalue}
                                disabled={fie.Name === "Identification_no1" ? true: false }
                              />
                              <p className="help-block text-right">
                                (* Required)
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </>
                ) : (
                  ""
                )}
                </>
              )}
                {/* {props.selectDocumentType !== 937 ? (
                  
                  <>
                    {props.defaultFields &&
                      props.defaultFields.placeholderList?.map((fie, idx) => {
                        

                        


                        let companynamevalue = "";

                        if (fie.Name == "company_name") {
                          companynamevalue =
                            sessionStorage.getItem("companyname");
                        }
                        if (fie.Name == "address") {
                          companynamevalue = sessionStorage.getItem("address");
                        }

                        if (fie.Name == "Address1") {
                          companynamevalue = sessionStorage.getItem("Address1");
                        }

                        

                        
                        if (fie.Name == "current_date") {
                          companynamevalue =
                            sessionStorage.getItem("current_date");
                        }
                        if (fie.Name == "registration_no") {
                          companynamevalue =
                            sessionStorage.getItem("registration_no");
                        }
                        if (fie.Name == "FinancialYearEnd_date") {
                          
                          companynamevalue =
                            sessionStorage.getItem("FinancialYearEnd_date");
                        }
                        if (fie.Name == "Resolution_date") {
                          
                          companynamevalue =
                            sessionStorage.getItem("Resolution_date");
                        }
                        if (fie.Name == "Filing_date") {
                          
                          companynamevalue =
                            sessionStorage.getItem("Filing_date");
                        }

                        

                        if (fie.Name == "convened_date") {
                          
                          companynamevalue =
                            sessionStorage.getItem("convened_date");
                        }
                       


                        if (fie.Name == "CorporateRepresentative_name1") {
                          companynamevalue = sessionStorage.getItem(
                            "corporate_representative1"
                          );

                          
                        }

                        if (fie.Name == "Identification_no1") {
                          companynamevalue = sessionStorage.getItem(
                            "Identification_no1"
                          );

                          
                        }
                        if (fie.Name == "CorporateRepresentative_name2") {

                          companynamevalue = sessionStorage.getItem(
                            "corporate_representative2"
                          );
                        }

                        if (fie.Name == "CorporateRepresentative_name3") {

                          companynamevalue = sessionStorage.getItem(
                            "corporate_representative3"
                          );
                        } 
                        if (fie.Name == "CorporateRepresentative_name4") {

                          companynamevalue = sessionStorage.getItem(
                            "corporate_representative4"
                          );
                        }                    
                           if (fie.Name == "director_name1") {
                          companynamevalue =
                            sessionStorage.getItem("director_name1");
                        }
                        if (fie.Name == "shareholder_name1") {
                          companynamevalue =
                            sessionStorage.getItem("shareholder_name1");
                        }
                        if (fie.Name == "shareholder_name2") {
                          companynamevalue =
                            sessionStorage.getItem("shareholder_name2");
                        }
                        if (fie.Name == "shareholder_name3") {
                          companynamevalue =
                            sessionStorage.getItem("shareholder_name3");
                        }
                        if (fie.Name == "shareholder_name4") {
                          companynamevalue =
                            sessionStorage.getItem("shareholder_name4");
                        }
                        if (fie.Name == "secratory_name1") {
                          companynamevalue =
                            sessionStorage.getItem("secratory_name1");
                        }
                        if (fie.Name == "secratory_name2") {
                          companynamevalue =
                            sessionStorage.getItem("secratory_name2");
                        }
                        if (fie.Name == "secratory_name3") {
                          companynamevalue =
                            sessionStorage.getItem("secratory_name3");
                        }
                        if (fie.Name == "secratory_name4") {
                          companynamevalue =
                            sessionStorage.getItem("secratory_name4");
                        }
                        if(fie.Name.includes("director_name") ||  fie.Name.includes("CorporateRepresentative_name") || fie.Name.includes("shareholder_name") ||  fie.Name.includes("secratory_name") ){
                          companynamevalue = "";

                          console.log('companynamevalue: ', fie.Name, companynamevalue);
                        }

                        return fie.Name.includes("director_name") ? (
                          
                          <div className="form-group" key={idx}>
                            <label
                              htmlFor="companyname"
                              className="control-label col-sm-4 required"
                            >
                              {_.startCase(fie.Name)}
                              <p className="help-block ">({fie.Name}) </p>
                            </label>
                            <div className="col-sm-8">
                              <select
                              ref={dropdownRef}
                                className="form-control"
                                onChange={(e) => {
                                  changeFunc(e.target.value, fie.Name),
                                    addData(e.target.value, fie.Name);
                                }}
                                required="required"
                                name="companyname"
                                id="companyname"
                                // defaultValue={companynamevalue ? companynamevalue : ""}
                                defaultValue={companynamevalue}
                              >
                                <option value="" >
          Choose a Director
        </option>
                                {directorList.map((data, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={data.name}
                                     
                                    >
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <p className="help-block text-right">
                                (* Required)
                              </p>
                            </div>
                          </div>
                        ) 
                        
                        
                        
                        : // ========================================================================================================
                        fie.Name.includes("CorporateRepresentative_name") ? (
                          <div className="form-group" key={idx}>
                            <label
                              htmlFor="companyname"
                              className="control-label col-sm-4 required"
                            >
                              {_.startCase(fie.Name)}

                              <p className="help-block ">({fie.Name}) </p>
                            </label>
                            <div className="col-sm-8">
                              <select
                               ref={dropdownRef}
                                className="form-control"
                                onChange={(e) => {
                                  changeFunc(e.target.value, fie.Name),
                                    addData(e.target.value, fie.Name);
                                }}
                                required="required"
                                name="companyname"
                                id="companyname"
                                // defaultValue={companynamevalue}
                              >
                                <option value={""} >
                                  {" "}
                                  Choose a Corporate Representative{" "}
                                </option>
                                {corporateRepresentativeList.map((data, i) => {
                                 


                                  return (
                                    <option
                                      key={i}
                                      value={data.name}
                                      // selected={companynamevalue == data.name}
                                    >
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </select>

                              <p className="help-block text-right">
                                (* Required)
                              </p>
                            </div>
                          </div>
                        ) : // ========================================================================================================

                        fie.Name.includes("shareholder_name") ? (
                          <div className="form-group" key={idx}>
                            <label
                              htmlFor="companyname"
                              className="control-label col-sm-4 required"
                            >
                              {_.startCase(fie.Name)}
                              <p className="help-block ">({fie.Name}) </p>
                            </label>
                            <div className="col-sm-8">
                              <select
                               ref={dropdownRef}
                                className="form-control"
                                onChange={(e) => {
                                  changeFunc(e.target.value, fie.Name),
                                  
                                    addData(e.target.value, fie.Name);
                                }}
                                required="required"
                                name="companyname"
                                id="companyname"
                                // defaultValue={companynamevalue}
                              >
                                <option value={""}> Choose a ShareHolder </option>
                                {shareHolderList.map((data, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={data.name}
                                      // selected={companynamevalue == data.name}
                                    >
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </select>

                              <p className="help-block text-right">
                                (* Required)
                              </p>
                            </div>
                          </div>
                        ) : fie.Name.includes("secratory_name") ? (
                          <div className="form-group" key={idx}>
                            <label
                              htmlFor="companyname"
                              className="control-label col-sm-4 required"
                            >
                              {_.startCase(fie.Name)}

                            </label>
                            <div className="col-sm-8">
                              <select
                               ref={dropdownRef}
                                className="form-control"
                                onChange={(e) => {
                                  changeFunc(e.target.value, fie.Name),
                                    addData(e.target.value, fie.Name);
                                }}
                                required="required"
                                name="companyname"
                                id="companyname"
                                // defaultValue={companynamevalue}
                              >
                                <option value={""}> Choose a Secratory </option>
                                {secratoryList.map((data, i) => {
                                  return (
                                    <option
                                      value={data?.name}
                                      // selected={companynamevalue == data.name}
                                    >
                                      {data?.name}
                                    </option>
                                  );
                                })}
                              </select>

                              <p className="help-block text-right">
                                (* Required)
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="form-group" key={idx}>
                            <label
                              htmlFor="companyname"
                              className="control-label col-sm-4 required"
                            >
                              {_.startCase(fie.Name)}
                              <p className="help-block ">({fie.Name}) </p>
                            </label>
                            <div className="col-sm-8">
                              <input
                                className="form-control"
                                required="required"
                                name="companyname"
                                type="text"
                                // value={obj[fie.Name]}
                                id={fie.Name}
                                onChange={(e) => {
                                  changeFunc(e.target.value, fie.Name),
                                    addData(e.target.value, fie.Name);
                                }}
                                value={companynamevalue}
                                disabled={fie.Name === "Identification_no1" ? true: false }
                              />
                              <p className="help-block text-right">
                                (* Required)
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </>
                ) : (
                  ""
                )} */}

                {props.selectDocumentType !== 937 ? (
                  <div className="col-sm-12 text-center">
                    <button
                      className="btn blue-border-btn mb-10"
                      onClick={() => previewFunc()}
                    >
                      {loading ? (
                        <ColorRing
                          visible={true}
                          height="25"
                          width="25"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={[
                            "#e15b64",
                            "#f47e60",
                            "#f8b26a",
                            "#abbd81",
                            "#849b87",
                          ]}
                        />
                      ) : (
                        "Get Document Preview"
                      )}
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {/* ============================================== ==============================================  ==============================================  */}



                {props.selectDocumentType === 937 && (
                  <div className="form-group mb-3">
                    <h3>Upload Document</h3>
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={handleFileUpload}
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "8px 12px",
                        fontSize: "14px",
                        color: "#333",
                        border: "1px solid #ccc",
                        borderRadius: "6px",
                        backgroundColor: "#f9f9f9",
                        cursor: "pointer",
                        marginBottom: "12px",
                        outline: "none",
                      }}
                    />

                    <div className="my-5">
                      <h3>Markers List</h3>
                      <ul className="list-group mb-3">
                        {markers.map((marker, index) => (
                          <li
                            key={marker.id}
                            className="list-group-item d-flex justify-content-around align-items-center"
                          >
                            <span>
                            <b style={{ marginRight: "10px" }}>{index + 1}</b>
                            </span>



                            <span className="d-flex gap-4 align-items-center">
                            <span style={{  marginRight: "5px" }}>{marker.label}</span>
 
                              <span  className=""> (Page: {marker.pageIndex + 1})</span>
                            </span>
                            <button
                              onClick={() => handleRemoveMarker(marker.id)}
                              className="btn btn-sm btn-danger"
                            >
                              Remove
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  






                )}







                {/* ============================================== ==============================================  ==============================================  */}

                <div className="form-group">
                  <div className="col-sm-1" />
                  <div className="col-sm-10">
                    <div className="vertical-border" />
                  </div>
                  <div className="col-sm-1" />
                </div>

                <div className="col-sm-12 text-center">
                  {props.selectDocumentType !== 937 ? (
                    <button
                      className="btn black-border-btn mb-30"
                      onClick={() => saveChangesFunc}
                    >
                      {props.editDocumentModal == true
                        ? "Update changes"
                        : "Save changes"}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-sm-6 ">
                {/* ============================================== ==============================================  ==============================================  */}

              

                {props.selectDocumentType === 937 && (
                  <div className="border p-3 position-relative" ref={viewerRef}>
                    <div
                      style={{
                        width: "100%",
                        margin: "10px 0",
                        textAlign: "end",
                      }}
                    >

{/* <button
              className="btn btn-warning me-2"
              onClick={handleSavePdf}
              disabled={ !dis }
              style={{
                        margin:"0  5px 0 0",
                    }}

            >
              Preview
            </button>
            <button className="btn btn-danger m-2"      disabled={ !dis }
            style={{
                        margin:"0  5px 0 0",
                    }}
                       onClick={handleRefresh}>
              Refresh
            </button> */}
                      <button
                        onClick={handleAddMarkerButtonClick}
                        // disabled={renderedPages.length > 0 || isAddingMarker}
                        disabled={ !dis }
                        style={{
                        margin:"0  5px 0 0",
                    }}

                        className="btn btn-success mb-3"
                      >
                        Add Marker
                      </button>
                    </div>

                    <div
                      style={{
                        marginTop: "10px",
                        fontWeight: "bold",
                        padding: "10px",
                        border: "1px solid gray",
                      }}
                    >
                      Note:{" "}
                      <span style={{ color: "red", marginLeft: "10px" }}>
                        {" "}
                        If Move the markers , Place of center to marker to pick
                        the center and drag to move it.
                      </span>
                    </div>
                    {isAddingMarker && (
                      <>
                        <div className="mb-3">
                          <div>
                            {/* Designation Selection */}
                            <div
                              className="col-4"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <label
                                className="form-label"
                                style={{ fontSize: "16px" }}
                              >
                                Category:
                              </label>
                              <select
                                value={selectedDesignation}
                                onChange={handleDesignationChange}
                                className="form-control"
                                style={{
                                  width: "70%",
                                  padding: "5px 10px",
                                  fontSize: "18px",
                                  color: "black",
                                  border: "1px solid lightgray",
                                  marginBottom: "10px",
                                }}
                              >
                                <option value="">Select a designation</option>
                                {designations.map((designation) => (
                                  <option key={designation} value={designation}>
                                    {designation}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {/* Option Selection */}
                            {selectedDesignation && (
                              <div
                                className="col-4"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <label
                                  className="form-label"
                                  style={{ fontSize: "16px" }}
                                >
                                  Designation:
                                </label>
                                <select
                                  value={selectedOption}
                                  onChange={handleOptionChange}
                                  className="form-control"
                                  style={{
                                    width: "70%",
                                    padding: "5px 10px",
                                    fontSize: "18px",
                                    color: "black",
                                    border: "1px solid lightgray",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <option value="">Select an option</option>
                                  {optionsByDesignation[
                                    selectedDesignation
                                  ].map((option, index) => (
                                    <option key={index} value={option.value}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}

                            {/* Name Selection */}
                            {selectedDesignation && (
                              <div
                                className="col-4"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <label
                                  className="form-label"
                                  style={{ fontSize: "16px" }}
                                >
                                  Name:
                                </label>
                                <select
                                  value={selectedName}
                                  onChange={handleNameChange}
                                  className="form-control"
                                  style={{
                                    width: "70%",
                                    padding: "5px 10px",
                                    fontSize: "18px",
                                    color: "black",
                                    border: "1px solid lightgray",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <option value="">Select a name</option>
                                  {optionsNames.map((data, i) => {
                                    const isDisabled = markers.some(
                                      (add) => data.name === add.name
                                    );

                                    return (
                                      <option
                                        key={i}
                                        value={data.name}
                                        disabled={isDisabled}
                                      >
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}

{}
                    <h3 className="text-center" style={{ margin: "20px 10px" }}>
                      Document Preview
                    </h3>
                    {/* {fileUrl ? (
                      <div
                        style={{
                          width: "100%",
                          height: "500px",
                          overflow: "auto",
                          position: "relative",
                        }}
                      >
                        <div
                          onClick={handleDocumentClick}
                          onDragOver={(event) => event.preventDefault()} // Enable dropping
                          onDrop={handleMarkerDrop} // Handle dropping
                          style={{ cursor: "pointer", position: "relative" }}
                        >
                          <Worker
                            workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
                          >
                            <Viewer
                              fileUrl={fileUrl}
                              plugins={[defaultLayoutPluginInstance]}
                              onPageChange={handlePageChange}
                            />
                          </Worker>
                          {markers.map((marker) => (
                            <div
                              key={marker.id}
                              style={{
                                position: "absolute",
                                left: `${marker.x}%`,
                                top: `${marker.y}%`,
                                transform: "translate(-50%, -50%)",
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                                border: "1px solid gray",
                                borderRadius: "10px",
                                padding: "3px",
                              }}
                              draggable
                              onDragStart={(event) =>
                                handleMarkerDragStart(event, marker)
                              }
                              onDrag={(event) => handleMarkerDrag(event)}
                              onDragEnd={handleMarkerDragEnd}
                            >
                              <img
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  backgroundColor: "transparent",
                                  cursor: "pointer",
                                }}
                                src={MarkerImg01}
                                alt="Marker"
                              />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  padding: "2px",
                                  fontSize: "16px",
                                  fontWeight: 700,
                                }}
                              >
                                {marker.label}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <p>No document uploaded.</p>
                    )} */}
 {renderedPages.length > 0 && (
            <div
              style={{
                border: "1px solid #ccc",
                overflowY: "scroll",
                height: "80vh",
                position: "relative",
              }}
            >
              {renderedPages.map((page) => (
                <canvas
                  key={page.index}
                  ref={(canvas) => {
                    if (canvas) page.renderPage(canvas);
                  }}
                  style={{
                    border: "1px solid #ccc",
                    cursor: isAddingMarker ? "crosshair" : "default",
                    display: "block",
                    marginBottom: "20px",
                  }}
                  onClick={(event) =>
                    handleCanvasClick(
                      event,
                      page.index,
                      page.width,
                      page.height
                    )
                  }
                />
              ))}
            </div>
          )}
                  </div>
                )}

                {/* ============================================== ==============================================  ==============================================  */}

                {props.selectDocumentType !== 937 ? (
                  <div
                    className="mt-20 text-center"
                    style={{ minHeight: "728px" }}
                  >
                    <h4 className="mb-25">Form Preview</h4>
                    {props?.fileJson && (
<>
<DocumentEditor
                        fileJson={props?.fileJson}
                        setTemplateData={setTemplateData}
                        rendr={rendr}
                        setRendr={setRendr}
                      />
</>


                      
                    )}

                    {/* {props.docPreview && (
                    <>
                      <iframe
                        key={random}
                        src={`https://docs.google.com/viewer?url=http://13.214.35.227:4444${props.docPreview}&embedded=true`}
                        frameBorder="0"
                        width="100%"
                        id="iFrame"
                        title="doc-preview"
                        style={{ minHeight: "inherit" }}
                      />
                    </>
                  )} */}
                    <div className="form-group">
                      <div
                        className="col-sm-5 align-center justify-center"
                        style={{ justifyContent: "center", marginTop: "25px" }}
                      >
                        <input
                          type="checkbox"
                          className="check-box-active"
                          id="isCreateTemplate"
                          name="create_template"
                          value={isCreateTemplate}
                          onClick={(e) => {
                            setIsCreateTemplate(e.target.checked);
                          }}
                        />
                        <label
                          htmlFor="isCreateTemplate"
                          className="control-label pl-10 mb-0 required"
                        >
                          Create Template
                        </label>
                      </div>
                    </div>
                    {isCreateTemplate && (
                      <div className="form-group">
                        <div
                          className="col-sm-10 align-center justify-center"
                          style={{
                            justifyContent: "center",
                            marginTop: "5px",
                            marginLeft: "13px",
                          }}
                        >
                          <label
                            htmlFor="isCreateTemplate"
                            className="control-label col-sm-6 pl-12 mb-0 required"
                          >
                            Template Name
                          </label>
                          <input
                            type="text"
                            className="form-control col-sm-4"
                            name="templateName"
                            onChange={(e) => setTemName(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>

              {props.selectDocumentType !== 937 ? (
                <div className="col-sm-12">
                  {props.clientApiStatus ? (
                    <div
                      className={`alert ${
                        props.clientApiStatus === 1 ? "" : "alert-danger"
                      }`}
                      role="alert"
                    >
                      {props.clientApiStatus === 1 ? "" : props.message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              <div
                className="col-sm-12 pt-20 pb-20 mt-20 flex-center-space"
                style={{ background: "rgba(92, 132, 149, 0.32)" }}
              >
                <button
                  className="btn transparent-btn"
                  onClick={() => {
                    props.sendValues("transferOfShareModal", false);
                    props.sendValues("defaultFields", []);
                    props.sendValues("multiple_document_id", []);
                    props.sendValues("editDocumentModal", false);
                    props.sendValues("document_id", "");
                    props.sendValues("selectDocumentType", "");
                    setObj({});
                    window.location.reload();
                  }}
                >
                  Cancel
                </button>
                {props?.lengtharray == props.nextValue && (
                  <>
                    {props.selectDocumentType === 937 ? (
                      <button
                        className="btn blue-btn"
                        onClick={savePdfWithMarkers}
                      >
                        Goto Next
                      </button>
                    ) : (
                      <button className="btn blue-btn" onClick={sendNewFunc}>
                        Goto Next
                      </button>
                    )}
                  </>
                )}
                {props?.lengtharray != props.nextValue && (
                  <button
                    className="btn blue-btn"
                    onClick={(e) => nextFunction(e)}
                  >
                    Next Document
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

// props.sendValues("sendRequestModal", true)
const mapStateToProps = (state, ownProps) => {
  const {
    transferOfShareModal,
    docPreview,
    defaultFields,
    selectDocumentType,
    to,
    multiple_document_id,
    lengtharray,
    nextValue,
    tabSelected,
    editDocumentModal,
    documentList,
    document_id,
    htmldata,
    fileJson,
  } = state.Document;

  return {
    transferOfShareModal,
    docPreview,
    htmldata,
    defaultFields,
    selectDocumentType,
    to,
    multiple_document_id,
    lengtharray,
    nextValue,
    shareHolderList: state.ShareReg.shareHolderList,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    tabSelected,
    editDocumentModal,
    documentList,
    document_id,
    selectedid: state.Client.selectedid,
    fileJson,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.documentFunction(k, v)),
    getDocumentPreview: (val, setRendr, setLoading) =>
      dispatch(actions.getDocumentPreview(val, setRendr, setLoading)),
    sendAndSaveDocument: (val, typ, tab, templateData, temName) =>
      dispatch(
        actions.sendAndSaveDocument(val, typ, tab, templateData, temName)
      ),
    sendAndSaveDocumentNext: (val, typ, tab, templateData, temName) =>
      dispatch(
        actions.sendAndSaveDocumentNext(val, typ, tab, templateData, temName)
      ),
    getDefaultPlaceHolder: (type) =>
      dispatch(actions.getDefaultPlaceHolderNext(type)),

    sendAndSaveDocument_multiple: (val, typ, tab) =>
      dispatch(actions.sendAndSaveDocument_multiple(val, typ, tab)),
    sendDocTypeAndGetDocument: (type, setRendr) =>
      dispatch(actions.sendDocTypeAndGetDocument_AutoPreview(type, setRendr)),
    editDocument: (val, typ, tab) =>
      dispatch(actions.editDocument(val, typ, tab)),
    getShareHolderList: () => dispatch(share.getShareHolderList()),
    changeClientInfo: (k, v) => dispatch(changeClientInfo(k, v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferShares);
