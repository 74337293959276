import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import {
  charges,
  editCharegs,
  getCurrencyList,
} from "../../../actions/clientDetails/Charges";
import * as client from "../../../actions/Client";
import DatePicker from "react-datepicker";
import moment from "moment";

function AddCharges(props) {
  useEffect(() => {
    props.getCurrencyList();
  }, []);
  const getfilename=(e)=>{
    var splitedval=(e.target.value).split("_")
   
    props.sendValues("docLink",e.target.value)
    var index = e.nativeEvent.target.selectedIndex;

    if(e.target.value != 0 && e.target.value != ''){
      props.sendValues("docLink",e.target.value)
      props.alldocumentclientList && props.alldocumentclientList.map((filepathresultnn,indexvalnn)=>{
      
        if(splitedval[0] == filepathresultnn.id){
          document.getElementById("viewdocument").style.display="block";
          document.getElementById("viewdocument").href=filepathresultnn.file_path;
        }
      })
    }else{
      document.getElementById("viewdocument").style.display="none";
    }
    
  }
  
  const UpdateFunc = () => {
    let document_date;
    if (props.docDate != '') {
      document_date = moment(props.docDate).format('YYYY-MM-DD')
    }
    var userData = JSON.parse(localStorage.getItem("userData"));
    let input = {
      company_id: userData.company_id,
      client_id: sessionStorage.getItem("personalId"),
      charge_no: props.chargeNo,
      date_registered: moment(props.dateRegistered).format("YYYY-MM-DD"),
      date_discharge: moment(props.dateDischarge).format("YYYY-MM-DD"),
      currency_id: props.currency,
      amount_secured: props.amountSecured,
      chargee: props.chargee,
      remarks: props.remark,
      client_charge_id: props.charges_id,
      active_tab: props.pageSelected,
      document_link: props.docLink,
      document_id: props.docId,
      attachment: props.attachment,
      attachment_keyword: props.attachmentKey,
      document_type: props.docType,
      document_date: document_date,
      notes: props.note,
      description: props.description,

    };
    props.editCharegs(input);
    clearFields()

  };

  const handleClose = () => {
    
    console.log('handleClose: ');
    console.log("Modal editCharges prop:", props.editCharges);

    props.sendValues("editCharges", false)// Close the modal
    props.sendValues("charges_id", '');// Reset docLink
    clearFields()
  };
  const clearFields = () => {
    console.log('clearFields: ');
    const defaultValues = {

      dateRegistered: null,
      dateDischarge: null,

    };
  
    Object.entries(defaultValues).forEach(([key, value]) => {
      props.sendValues(key, value);
    });
  

  };


  return (
    <Modal
    key={props.editCharges ? 'modal-open' : 'modal-closed'} 
    onHide={handleClose} 





      show={props.editCharges}
     
      className="modal bootstrap-dialog type-primary fade size-normal "
      role="dialog"
      aria-hidden="true"
      id="addcharges"
      aria-labelledby="addcharges"
      tabIndex={-1}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              <div
                className="bootstrap-dialog-title"
                id="3482c5e5-cce1-4718-aa17-bd0354520bba_title"
              >
                Edit Charges
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div>
                  <form
                    acceptCharset="UTF-8"
                    encType="multipart/form-data"
                    onSubmit={UpdateFunc}
                    id="form"
                    className="form-horizontal"
                  >
                    <input
                      name="_token"
                      type="hidden"
                      defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446"
                    />
                    <div className="form-group">
                      <label
                        htmlFor="chargeno"
                        className="control-label col-sm-4 required"
                      >
                        Charge No
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          required="required"
                          value={props.chargeNo}
                          name="chargeno"
                          type="text"
                          onChange={(e) =>
                            props.sendValues("chargeNo", e.target.value)
                          }
                          id="chargeno"
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="dateregistered"
                        className="control-label col-sm-4 required"
                      >
                        Date Registered
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.dateRegistered}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("dateRegistered", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() =>
                              props.sendValues("dateRegistered", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="discharged"
                        className="control-label col-sm-4"
                      >
                        Date Discharge
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.dateDischarge}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("dateDischarge", Date);
                          }}
                        >
                          <div
                            className="trash-icon"
                            onClick={() =>
                              props.sendValues("dateDischarge", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>
                          </div>
                        </DatePicker>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="currency_id"
                        className="control-label col-sm-4 required"
                      >
                        Currency
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control"
                          value={props.currency}
                          onChange={(e) =>
                            props.sendValues("currency", e.target.value)
                          }
                          required="required"
                          id="currency_id"
                          name="currency_id"
                        >
                          <option value="">Select Currency</option>
                          {props.currencyList.map((cur) => {
                            return <option value={cur.id}>{cur.Name}</option>;
                          })}
                        </select>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="amountsecured"
                        className="control-label col-sm-4 required"
                      >
                        Amount Secured
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          value={props.amountSecured}
                          onChange={(e) =>
                            props.sendValues("amountSecured", e.target.value)
                          }
                          step="0.01"
                          required="required"
                          name="amountsecured"
                          type="number"
                          id="amountsecured"
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="chargee"
                        className="control-label col-sm-4 required"
                      >
                        Chargee
                      </label>
                      <div className="col-sm-8">
                        <input
                          className="form-control"
                          value={props.chargee}
                          onChange={(e) =>
                            props.sendValues("chargee", e.target.value)
                          }
                          required="required"
                          name="chargee"
                          type="text"
                          id="chargee"
                        />
                        <p className="help-block">* Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="remarks"
                        className="control-label col-sm-4"
                      >
                        Remarks
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          rows={3}
                          value={props.remark}
                          onChange={(e) =>
                            props.sendValues("remark", e.target.value)
                          }
                          name="remarks"
                          cols={50}
                          id="remarks"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="document_link"
                        className="control-label col-sm-4"
                      >
                        Document Link
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control" 
                          onChange={(e) => getfilename(e)
                          }
                          id="document_link"
                          name="document_link"
                        >
                        <option value="" selected="selected">
                        View from the drop-down list / Select New Doc to upload new
                          </option>
                          <option value={0}>New Document</option>
                          {
                            props.alldocumentclientList && props.alldocumentclientList.map((filepathresult,index)=>{
                              return(<>
                             <option value= {filepathresult.id+"_"+filepathresult.document_type+"_"+filepathresult.indexno}> {filepathresult.file_path.split('/').pop().split('?')[0].split('#')[0].replace(/[/\\?%*:|"<>]/g, '-') }</option>
                              </>);
                            })
                          }
                        </select>
                        <a href="" id="viewdocument" style={{display:"none"}} target="_blank">View Selected Document</a>
                      </div>
                    </div>
                    {props.docLink == '0' ? (
                      <div>
                        <div className="form-group">
                          <label
                            htmlFor="document_id"
                            className="control-label col-sm-4"
                          >
                            Document ID
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              value={props.docId}
                              onChange={(e) =>
                                props.sendValues("docId", e.target.value)
                              }
                              name="document_id"
                              type="text"
                              id="document_id"
                            />
                            <p className="help-block">
                              Must be unique ID with this client{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="original_filename"
                            className="control-label col-sm-4"
                          >
                            Attachment
                          </label>
                          <div className="col-sm-8">
                            <input
                              className="form-control"
                              onChange={(e) => {
                                if (e.target.files[0].size <= 2000000) {
                                  props.sendValues(
                                    "attachment",
                                    e.target.files[0]
                                  )
                                } else {
                                  props.sendValues("attachment", '');
                                  e.target.value = '';
                                  props.sendError("clientApiStatus", 2);
                                  props.sendError("message", "File size limit only 2Mb");
                                  setTimeout(() => {
                                    props.sendError("clientApiStatus", 0);
                                    props.sendError("message", "");
                                  }, 2500);
                                }
                              }
                              }
                              maxLength={4096}
                              accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                              name="original_filename"
                              type="file"
                              id="original_filename"
                            />
                            <p className="help-block">
                              * Uploading of file limited to 2MB only.{" "}
                            </p>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="document_tag" className="control-label col-sm-4">Description</label>
                          <div className="col-sm-8">
                            <textarea className="form-control"
                             rows={3}                               
                            value={props.description}
                            onChange={(e) => { props.sendValues("description", e.target.value) }} 
                            name="document_tag" 
                            cols={50} 
                            id="document_tag" 
                            defaultValue={""} />
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="keywords"
                            className="control-label col-sm-4"
                          >
                            Attachment Keyword for Searching Purposes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              value={props.attachmentKey}
                              onChange={(e) =>
                                props.sendValues(
                                  "attachmentKey",
                                  e.target.value
                                )
                              }
                              rows={3}
                              name="keywords"
                              cols={50}
                              id="keywords"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_type_id"
                            className="control-label col-sm-4"
                          >
                            Document Type
                          </label>
                          <div className="col-sm-8">
                            <select
                              className="form-control"
                              value={props.docType}
                              onChange={(e) =>
                                props.sendValues("docType", e.target.value)
                              }
                              id="document_type_id"
                              name="document_type_id"
                            >
                              <option value='' >
                                === Select ===
                              </option>
                              {props.DocumentTypeList.map((val) => {
                                return (
                                  <option value={val.id}>{val.Name}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_date"
                            className="control-label col-sm-4"
                          >
                            Resolution/Document Date
                          </label>
                          <div className="col-sm-8">
                            <DatePicker
                              showPopperArrow={false}
                              dateFormat={"dd/MM/yyyy"}
                              selected={props.docDate}
                              locale={"en"}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: "0px, 0px",
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: "viewport",
                                },
                              }}
                              onChange={(Date) => {
                                props.sendValues("docDate", Date);
                              }}
                            >
                              <div
                                className="trash-icon"
                                onClick={() => props.sendValues("docDate", "")}
                              >
                                <span class="glyphicon glyphicon-trash"></span>
                              </div>
                            </DatePicker>
                            <p className="help-block">DD/MM/YYYY </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label
                            htmlFor="document_tag"
                            className="control-label col-sm-4"
                          >
                            Notes
                          </label>
                          <div className="col-sm-8">
                            <textarea
                              className="form-control"
                              value={props.note}
                              onChange={(e) =>
                                props.sendValues("note", e.target.value)
                              }
                              rows={3}
                              name="document_tag"
                              cols={50}
                              id="document_tag"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id_doc"
                        type="hidden"
                        defaultValue={489}
                      />
                    </div>
                    <div className="col-sm-12" >
                      {props.clientApiStatus ? (
                        <div
                          className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                            }`}
                          role="alert"
                        >
                          {props.clientApiStatus === 1 ? "" : props.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      className="btn form-control btn-success"
                      type="submit"
                      id="addnew"
                      value="new"
                      name="action"
                    >
                      <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                      Update
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  className="btn form-control"
                  id="b0a92336-3dbc-475e-891b-2b5bc0d374b0"
                  

                  onClick={handleClose} 
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {
    editCharges,
    chargeNo,
    dateRegistered,
    dateDischarge,
    currency,
    amountSecured,
    chargee,
    remark,
    docLink,
    docId,
    attachment,
    attachmentKey,
    docType,
    docDate,
    note,
    description,
    editChargesStatus,
    charges_id,
    currencyList,
  } = state.Charges;
  return {
    editCharges,
    chargeNo,
    dateRegistered,
    dateDischarge,
    currency,
    amountSecured,
    chargee,
    remark,
    docLink,
    docId,
    attachment,
    attachmentKey,
    docType,
    docDate,
    note,
    description,
    editChargesStatus,
    charges_id,
    currencyList,
    pageSelected: state.Client.pageSelected,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    DocumentTypeList: state.Settings.DocumentTypeList,
    alldocumentclientList: state.Client.alldocumentclientList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(charges(k, v)),
    sendError: (f, v) => dispatch(client.changeClientInfo(f, v)),
    editCharegs: (data) => dispatch(editCharegs(data)),
    getCurrencyList: () => dispatch(getCurrencyList()),
    allListDocuments: (data) => dispatch(client.allListDocuments(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCharges);
