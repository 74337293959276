import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import {
  charges,
  addDocument_Old,
  getCurrencyList,
  getListDocumentbyid,
} from "../../../actions/clientDetails/Charges";
import DatePicker from "react-datepicker";
import * as client from "../../../actions/Client";
import moment from "moment";

function AddDocumentNew(props) {
  useEffect(() => {
    props.getCurrencyList();
    props.sendValues("docLink", "");
    props.allListDocuments(sessionStorage.getItem("personalId"));
  }, []);
  useEffect(() => {
    if(props.idshowelement)
      props.getListDocumentbyid(props.idshowelement);
    props.sendValues("docLink", "");
    props.allListDocuments(sessionStorage.getItem("personalId"));
  }, [props.idshowelement]);


  const addFunc = () => {
    var userData = JSON.parse(localStorage.getItem("userData"));
    let document_date = '';
    if (props.docDate != '') {
      document_date = moment(props.docDate).format('YYYY-MM-DD')
    }
    let input = {
      company_id: userData.company_id,
      client_id: sessionStorage.getItem("personalId"),
      charge_no: props.chargeNo,
      date_registered: moment(props.dateRegistered).format("YYYY-MM-DD"),
      date_discharge: moment(props.dateDischarge).format("YYYY-MM-DD"),
      currency_id: props.currency,
      amount_secured: props.amountSecured,
      chargee: props.chargee,
      remarks: props.remark,
      document_link: props.docLink,
      document_id: props.docId,
      attachment: props.attachment,
      attachment_keyword: props.attachmentKey,
      document_type: props.docType,
      document_date: document_date,
      notes: props.note,
      description_desc: props.description,
      active_tab: props.pageSelected,
    };
    props.addDocument_Old(input);
  };

  // Handle Modal Close
  const handleClose = () => {
    
    console.log('handleClose: ');
    console.log("Modal adddocumentmodel prop:", props.adddocumentmodel);

    props.sendValues("adddocumentmodel", false); // Close the modal
    props.sendValues("docLink", ""); // Reset docLink
    props.sendError("adddocumentmodel", false)
  };

  return (
    <Modal
    key={props.adddocumentmodel ? 'modal-open' : 'modal-closed'} 
    onHide={handleClose} 




      show={props.adddocumentmodel}
      className="modal bootstrap-dialog type-primary fade size-normal "
      role="dialog"
      aria-hidden="true"
      id="adddocumentmodel"
      aria-labelledby="adddocumentmodel"
      tabIndex={-1}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              <div
                className="bootstrap-dialog-title"
                id="3482c5e5-cce1-4718-aa17-bd0354520bba_title"
              >
                Add Document
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div>
                  <form
                    acceptCharset="UTF-8"
                    encType="multipart/form-data"
                    onSubmit={addFunc}
                    id="form"
                    className="form-horizontal"
                  >
                    <input
                      name="_token"
                      type="hidden"
                      defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446"
                    />

                    <div>
                      <div className="form-group">
                        <label
                          htmlFor="document_id"
                          className="control-label col-sm-4"
                        >
                          Document ID
                        </label>
                        <div className="col-sm-8">
                          <input
                            className="form-control"
                            onChange={(e) =>
                              props.sendValues("docId", e.target.value)
                            }
                            defaultValue={props.docId}
                            name="document_id"
                            type="text"
                            id="document_id"
                          />
                          <p className="help-block">
                            Must be unique ID with this client{" "}
                          </p>
                        </div>
                      </div>
                      <div className="form-group ">
                        <label
                          htmlFor="original_filename"
                          className="control-label col-sm-4"
                        >
                          Attachment
                        </label>
                        <div className="col-sm-8">
                          <input
                            className="form-control"
                            onChange={(e) => {
                              if (e.target?.files[0]?.size <= 2000000) {
                                props.sendValues(
                                  "attachment",
                                  e.target.files[0]
                                )
                              } else {
                                props.sendValues("attachment", '');
                                e.target.value = '';
                                props.sendError("clientApiStatus", 2);
                                props.sendError("message", "File size limit only 2Mb");
                                setTimeout(() => {
                                  props.sendError("clientApiStatus", 0);
                                  props.sendError("message", "");
                                }, 2500);
                              }
                            }
                            }
                            maxLength={2048}
                            accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                            name="original_filename"
                            type="file"
                            id="original_filename"
                          />
                          <p className="help-block">
                            * Uploading of file limited to 2MB only.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="form-group ">
                        <label
                          htmlFor="document_tag"
                          className="control-label col-sm-4"
                        >
                          Description
                        </label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control"
                            onChange={(e) =>
                              props.sendValues("description", e.target.value)
                            }
                            rows={3}
                            name="document_tag"
                            cols={50}
                            id="document_tag"

                            defaultValue={props.description}
                          />
                        </div>
                      </div>
                      <div className="form-group ">
                        <label
                          htmlFor="keywords"
                          className="control-label col-sm-4"
                        >
                          Attachment Keyword for Searching Purposes
                        </label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control"
                            onChange={(e) =>
                              props.sendValues(
                                "attachmentKey",
                                e.target.value
                              )
                            }
                            rows={3}
                            name="keywords"
                            cols={50}
                            id="keywords"
                            defaultValue={props.attachmentKey}
                          />
                        </div>
                      </div>
                      <div className="form-group ">
                        <label
                          htmlFor="document_type_id"
                          className="control-label col-sm-4"
                        >
                          Document Type
                        </label>
                        <div className="col-sm-8">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              props.sendValues("docType", e.target.value)
                            }
                            id="document_type_id"
                            name="document_type_id"
                          >
                            <option value='' >
                              === Select ===
                            </option>
                            {props.DocumentTypeList.map((val, i) => {
                              var dfv = 0, selectedval = '';
                              if (props.docType == val.id) {
                                return (<option selected value={val.id} key={i} >{val.Name}</option>)
                              }
                              return (
                                <option value={val.id}  key={i}>{val.Name}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-group ">
                        <label
                          htmlFor="document_date"
                          className="control-label col-sm-4"
                        >
                          Resolution/Document Date
                        </label>
                        <div className="col-sm-8">
                          <DatePicker
                            showPopperArrow={false}
                            dateFormat={"dd/MM/yyyy"}
                            selected={props.docDate && (props.docDate != '0000-00-00') && (props.docDate != '') && moment(props.docDate)._d}
                            locale={"en"}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: "0px, 0px",
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                              },
                            }}
                            onChange={(Date) => {
                              props.sendValues("docDate", Date);
                            }}
                          >
                            <div
                              className="trash-icon"
                              onClick={() => props.sendValues("docDate", "")}
                            >
                              <span class="glyphicon glyphicon-trash"></span>
                            </div>
                          </DatePicker>
                          <p className="help-block">DD/MM/YYYY </p>
                        </div>
                      </div>
                      <div className="form-group ">
                        <label
                          htmlFor="document_tag"
                          className="control-label col-sm-4"
                        >
                          Notes
                        </label>
                        <div className="col-sm-8">
                          <textarea
                            className="form-control"
                            onChange={(e) =>
                              props.sendValues("note", e.target.value)
                            }
                            rows={3}
                            name="document_tag"
                            cols={50}
                            id="document_tag"
                            defaultValue={props.note}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        name="client_id_doc"
                        type="hidden"
                        defaultValue={489}
                      />
                    </div>
                    <div className="col-sm-12" >
                      {props.clientApiStatus ? (
                        <div
                          className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                            }`}
                          role="alert"
                        >
                          {props.clientApiStatus === 1 ? "" : props.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      className="btn form-control btn-success"
                      type="submit"
                      id="addnew"
                      value="new"
                      name="action"
                    >
                      <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  className="btn form-control"
                  id="b0a92336-3dbc-475e-891b-2b5bc0d374b0"
                  onClick={handleClose}
                  
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {

    chargeNo,
    dateRegistered,
    dateDischarge,
    currency,
    amountSecured,
    chargee,
    remark,
    docLink,
    docId,
    attachment,
    attachmentKey,
    docType,
    docDate,
    note,
    description,
    adddocumentmodelStatus,
    currencyList,
  } = state.Charges;
  return {

    chargeNo,
    dateRegistered,
    dateDischarge,
    currency,
    amountSecured,
    chargee,
    remark,
    docLink,
    docId,
    attachment,
    attachmentKey,
    docType,
    docDate,
    note,
    description,
    adddocumentmodelStatus,
    currencyList,
    pageSelected: state.Client.pageSelected,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    DocumentTypeList: state.Settings.DocumentTypeList,
    alldocumentclientList: state.Client.alldocumentclientList,
    adddocumentmodel: state.Client.adddocumentmodel,
    idshowelement: state.Client.idshowelement,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(charges(k, v)),
    sendError: (f, v) => dispatch(client.changeClientInfo(f, v)),
    addDocument_Old: (data) => dispatch(addDocument_Old(data)),
    getCurrencyList: () => dispatch(getCurrencyList()),
    allListDocuments: (data) => dispatch(client.allListDocuments(data)),
    getListDocumentbyid: (data) => dispatch(getListDocumentbyid(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentNew);
