import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import "../../../styles/CommenStyle.css";
import * as actions from "../../../actions/clientDetails/ShareRegister";
import moment from "moment";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ReactHtmlParser from "react-html-parser";

function History(props) {

  const [history, setHistory] = useState([]);

  useEffect(() => {
    if (props.historyList) {
      console.log("props.historyList: ", props.historyList);
  
      const sortedHistory = props.historyList.map((item) => ({
        ...item,
        shisData: [...item.shisData].sort((a, b) => {
          const isAOrdinaryShares = a.name === "ORDINARY SHARES (SGD)";
          const isBOrdinaryShares = b.name === "ORDINARY SHARES (SGD)";
          
          // Prioritize "ORDINARY SHARES (SGD)" to the top
          if (isAOrdinaryShares && !isBOrdinaryShares) return -1;
          if (!isAOrdinaryShares && isBOrdinaryShares) return 1;
          return 0; // Keep the original order for other items
        }),
      }));
  
      setHistory(sortedHistory);
    }
  }, [props.historyList]);
  
  
  



  console.log("history: ", history);

  // Handle Modal Close
  const handleClose = () => {
    
    console.log('handleClose: ');
    console.log("Modal show prop:", props.show);

    props.sendValues("viewHistory", false); // Close the modal
    props.sendValues("docLink", ""); // Reset docLink
  };

  return (
    <Modal
    key={props.show ? 'modal-open' : 'modal-closed'} 
    show={props.show}
    onHide={handleClose} 










      className="modal bootstrap-dialog type-primary fade size-normal "
      role="dialog"
      aria-hidden="true"
      id="History"
      aria-labelledby="History"
      tabIndex={-1}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div
                className="bootstrap-dialog-close-button"
                style={{ display: "none" }}
              >
                <button className="close" aria-label="close">
                  ×
                </button>
              </div>
              <div
                className="bootstrap-dialog-title"
                id="d4d390a1-1549-4b64-9d9f-4f4561397b2c_title"
              >
                History
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div>
                  <div className="box-body">
                    {
                    
                    
                    
                    
                    
                    history &&
                      history.map((share, index) => {
                        let historyData = "";

                        share.list.map((list, k) => {
                          list.lists.map((his, o) => {
                            // if(his.resolution_date === share.date){
                              if (his.action == "add") {
                                historyData += `<h5>
                                    Add ${his.share_holder.toUpperCase()} with ${
                                  his.share_type
                                }
                                    for ${his.no_of_shares} at share value of
                                    ${his.share_value}
                                  </h5>`;
                                  //<div>Remark : ${his.remarks != null &&his.remarks != undefined ?his.remarks :"" }</div>
                              } else if (his.action == "transfer") {
                                historyData += `<h5>
                                    Transfer from ${his.share_holder.toUpperCase()} to ${his.to_share_holder.toUpperCase()} with ${
                                  his.share_type
                                }
                                    for ${his.no_of_shares} at share value of
                                    ${his.share_value}
                                  </h5>`;
                                  //<div>Remark : ${his.remarks != null &&his.remarks != undefined ?his.remarks :"" }</div>
                              } else if (his.action == "convert") {
                                historyData += `<h5>
                                    Convert ${his.share_holder.toUpperCase()} with ${
                                  his.share_type
                                } to ${his.to_share_type}
                                    for ${his.no_of_shares} at share value of
                                    ${his.share_value}
                                  </h5>`;
                                  //<div>Remark : ${his.remarks != null &&his.remarks != undefined ?his.remarks :"" }</div>
                              } else if (his.action == "reduce") {
                                historyData += `<h5>
                                    Reduce ${his.share_holder.toUpperCase()} with ${
                                  his.share_type
                                }
                                    for ${
                                  his.to_no_of_shares
                                } at share value of
                                    ${his.share_value}
                                  </h5>`;
                                  //<div>Remark : ${his.remarks != null &&his.remarks != undefined ?his.remarks :"" }</div>
                              } else if (his.action == "split") {
                                historyData += `<h5>
                                    Split ${his.share_type}
                                    at share value of
                                    ${his.share_value}
                                  </h5>`;
                                  //<div>Remark : ${his.remarks != null &&his.remarks != undefined ?his.remarks :"" }</div>
                              }
                            // }
                          });
                        });
              

                        
                        

                      

                        return (
                          <div className="timeline">
                            <div className="column">
                              <div className="title">
                                
                                <h4>
                                  {" "}
                                  {moment(share.date).format("DD/MM/YYYY")}
                                </h4>
                                {ReactHtmlParser(historyData)}
                              </div>
                              <div className="description">
                                <hr />
                                <table className="table table-hover table-condensed history-table">
                                  <thead>
                                    <tr className="">
                                      <th className>Shareholder</th>
                                      <th className>Share Held</th>
                                      <th className>Value/Share $</th>
                                      <th className>Total Value $</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {share.shisData &&
                                      share.shisData.map((li, key) => {
                                        let totalNoOfShare = 0;
                                        let totalShareValue = 0;
                                        let totalShares = 0;
                                        return (
                                          <>
                                            <tr>
                                              <td
                                                className="active"
                                                colSpan={4}
                                              >
                                                {li.name}
                                              </td>
                                            </tr>
                                            {li.lists &&
                                              li.lists.map((shareli, keyli) => {
                                                totalNoOfShare +=
                                                  shareli.no_of_shares;
                                                totalShareValue +=
                                                  Number(shareli.share_value);
                                                totalShares +=
                                                Number(
                                                    shareli.no_of_shares
                                                  ) *
                                                  Number(shareli.share_value);
                                                return (
                                                  <tr>
                                                    <td>
                                                      {/* {shareli.action === "transfer"? shareli.to_share_holder : shareli.share_holder} */}
                                                      {shareli.name}
                                                    </td>
                                                    <td align="center">
                                                      {shareli.no_of_shares?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                    </td>
                                                    <td align="center">
                                                      {shareli.share_value}
                                                    </td>
                                                    <td align="center">
                                                      {(Number(
                                                        shareli.no_of_shares
                                                      ) *
                                                      Number(
                                                          shareli.share_value
                                                        )).toFixed(2)?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            <tr>
                                              <td align="right">Total: </td>
                                              <td align="center">
                                                {totalNoOfShare?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                              </td>
                                              <td />
                                              <td align="center">
                                                {totalShares.toFixed(2)?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {/* /.box-body */}
                  <div className="box-footer clearfix no-border"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons">
                <button
                  className="btn form-control"
                  id="79574d44-177e-49b0-86c4-19750e94d451"
                  onClick={handleClose} 

                  // onClick={() => {props.sendValues("viewHistory", false); window.location.reload(); }}
                >
                  <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    show: state.ShareReg.viewHistory,
    historyList: state.ShareReg.shareHistoryList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (k, v) => dispatch(actions.share_register(k, v)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
