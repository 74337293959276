import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import "../../../styles/CommenStyle.css";
import { Modal } from 'react-bootstrap';
import * as actions from "../../../actions/clientDetails/ShareRegister";
import * as client from "../../../actions/Client";
import DatePicker from "react-datepicker";
import moment from "moment";



function ShareTransfer(props) {
  console.log('ShareTransfer: =============================== ', props);

  useEffect(() => {
    // props.getShareTypeList();
    // props.getShareHolderList();
    // props.getValuePerShareList();
    props.sendValues("transferDocLink", '')
    props.allListDocuments(sessionStorage.getItem("personalId"))
  }, []);
  const getfilename=(e)=>{
    var splitedval=(e.target.value).split("_")
    props.sendValues("transferDocLink", e.target.value)
   
    var index = e.nativeEvent.target.selectedIndex;

    if(e.target.value != 0 && e.target.value != ''){
      props.sendValues("transferDocLink", e.target.value)
      props.alldocumentclientList && props.alldocumentclientList.map((filepathresultnn,indexvalnn)=>{
      
        if(splitedval[0] == filepathresultnn.id){
          document.getElementById("viewdocument").style.display="block";
          document.getElementById("viewdocument").href=filepathresultnn.file_path;
        }
      })
    }else{
      document.getElementById("viewdocument").style.display="none";
    }
    
  }
  const addTransfer = (e) => {
    var userData = JSON.parse(localStorage.getItem("userData"));
    e.preventDefault();
    let document_date = '';
    if (props.transferDocDate != '') {
      document_date = moment(props.transferDocDate).format('YYYY-MM-DD')
    }
   

  
    let selectedLot = props.shareLots.filter((lot) => {
      
      return Number(lot.id) === Number(props.lotId); 
    });
  


    let input = {
      company_id: userData.company_id,
      client_id: sessionStorage.getItem("personalId"),
      resolution_date: moment(props.transferResolutionDate).format('YYYY-MM-DD'),
      acrafileddate:moment(props.acrafileddate).format("YYYY-MM-DD"),
      share_holder: props.transferShareHolder,
      to_share_holder: props.transferToShareHolder,
      share_type: props.transferShareType,
      value_per_share: props.transferValueShare,
      no_of_shares_to_transfer: props.transferNoOfShare,
      remarks: props.transferRemark,
      document_link: props.transferDocLink,
      document_id: props.transferDocId,
      attachment: props.transferAttachment,
      attachment_keyword: props.transferAttachmentKey,
      document_type: props.transferDocType,
      document_date: document_date,
      notes: props.transferNotes,
      description: props.description,
      lotId: props.lotId,
      initial_value:selectedLot[0].no_of_shares_held
    };

    props.addTransferShare(input);

  };

  const handleClose = () => {
    
    console.log('handleClose: ');
    console.log('props.transferShares: ', props.transferShares);
    console.log("Modal transferShares prop:", props.transferShares);

    props.sendValues('transferShares', false)
    props.sendValues("transferDocLink", '')
    props.sendValues("transferResolutionDate", '');
    props.sendValues("acrafileddate", '');
  };

  return (
    <Modal show={props.transferShares}
    key={props.transferShares ? 'modal-open' : 'modal-closed'} 
    onHide={handleClose} 
      // onHide={() => props.sendValues('transferShares', false)} 
      className="modal bootstrap-dialog type-primary fade size-normal" role="dialog" aria-hidden="true" id="Transfershares" aria-labelledby="Transfershares" tabIndex={-1}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bootstrap-dialog-draggable">
            <div className="bootstrap-dialog-header">
              <div className="bootstrap-dialog-close-button" style={{ display: 'none' }}><button className="close" aria-label="close">×</button></div>
              <div className="bootstrap-dialog-title" id="5109b4a8-5772-470b-9a3a-84691984dd05_title">Transfer Shares</div>
            </div>
          </div>
          <div className="modal-body">
            <div className="bootstrap-dialog-body">
              <div className="bootstrap-dialog-message">
                <div>
                  <form onSubmit={addTransfer} acceptCharset="UTF-8" encType="multipart/form-data" id="form" className="form-horizontal"><input name="_method" type="hidden" defaultValue="PUT" /><input name="_token" type="hidden" defaultValue="16F3EYZyvwSIjn0HU7qrR7iTj2abLaza8likx446" />
                    <div className="form-group">
                      <label htmlFor="recorddate" className="control-label col-sm-4 required">Resolution
                          Date</label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.transferResolutionDate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          required="required"

                          onChange={(Date) => {
                            props.sendValues("transferResolutionDate", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() =>
                              props.sendValues("transferResolutionDate", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">
                          * Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="recorddate"
                        className="control-label col-sm-4 required"
                      >
                       Acra Filed Date
                      </label>
                      <div className="col-sm-8">
                        <DatePicker
                          showPopperArrow={false}
                          dateFormat={"dd/MM/yyyy"}
                          selected={props.acrafileddate}
                          locale={"en"}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "0px, 0px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          onChange={(Date) => {
                            props.sendValues("acrafileddate", Date);
                          }}
                        >
                          <div
                            class="trash-icon"
                            onClick={() =>
                              props.sendValues("acrafileddate", "")
                            }
                          >
                            <span class="glyphicon glyphicon-trash"></span>{" "}
                          </div>
                        </DatePicker>
                        <p className="help-block">* Required </p>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="clientemployee_id" className="control-label col-sm-4 required">Share Holder</label>
                      <div className="col-sm-8">
                        <select className="form-control" onChange={(e) => {
                          props.sendValues("transferShareHolder", e.target.value)
                          props.getShareTypeList(e.target.value)
                          props.getValuePerShareList(e.target.value, '')
                          let cbFunc = (ele) => ele.id == e.target.value
                          let arr = [...props.shareHolderList]
                          let index = arr.findIndex(cbFunc)
                          arr.splice(index, 1)
                          props.sendValues("toShareHolderList", arr)

                        }} required="required" id="clientemployee_id" name="clientemployee_id">
                          <option value='' selected="selected">=== Select Shareholder ===</option>
                          {props.shareHolderList && props.shareHolderList.map((val) => {
                            return (
                              <option value={val.id}>{val.name}</option>
                            )
                          })}
                        </select>
                        <p className="help-block">
                          * Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="toclientemployee_id" className="control-label col-sm-4 required">To Share Holder</label>
                      <div className="col-sm-8">
                        <select className="form-control" onChange={(e) => { props.sendValues("transferToShareHolder", e.target.value) }} required="required" id="toclientemployee_id" name="toclientemployee_id">
                          <option value='' selected="selected">=== Select Shareholder ===</option>
                          {props.toShareHolderList && props.toShareHolderList.map((val) => {
                            return (
                              <option value={val.id}>{val.name}</option>
                            )
                          })}
                        </select>
                        <p className="help-block">
                          * Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="sharetype_id" className="control-label col-sm-4 required">Share Type</label>
                      <div className="col-sm-8">
                        <select className="form-control" onChange={(e) => {
                          props.sendValues("transferShareType", e.target.value)
                          props.getValuePerShareList(props.transferShareHolder, e.target.value)
                        }} style={{ backgroundColor: '#fff' }} required="required" id="sharetype_id" name="sharetype_id">
                          <option value='' selected="selected">=== Please Select ===</option>
                          {props.ShareTypeList.map((shar) => {
                            return (
                              <option value={shar.id}>{shar.Name}</option>
                            )
                          })
                          }
                        </select>
                        <p className="help-block">
                          * Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="valuepershare" className="control-label col-sm-4 required">Value Per Share</label>
                      <div className="col-sm-8">
                        <select className="form-control" onChange={(e) => { 
                          props.sendValues("transferValueShare", e.target.value);
                          console.log("chinnu props", props)
                          props.getShareLot({client_id: sessionStorage.getItem("personalId"), value_per_share: e.target.value, share_holder: props.transferShareHolder, share_type: props.transferShareType});
                        }} 
                        required="required" id="valuepershare" name="valuepershare">
                          <option value='' selected="selected">=== Please Select ===</option>
                          {props.valuePerShareList && props.valuePerShareList.map((val, idx) => {
                            return (
                              <option value={val.id}>{val.value}</option>
                            )
                          })}

                        </select>
                        <p className="help-block">
                          * Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="lot" className="control-label col-sm-4 required">Select lot</label>
                      <div className="col-sm-8">
                        <select className="form-control" 
                        onChange={(e) => { 
                          props.sendValues("lotId", e.target.value) 
                        }} 
                        required="required" id="lot" name="valuepershare">
                          <option value='' selected="selected">=== Please Select ===</option>
                          {props.shareLots && props.shareLots.map((val, idx) => {
                            return (
                              <option value={val.id}>Share held : {val.no_of_shares_held} </option>
                            )
                          })}
                        </select>
                        <p className="help-block">
                          * Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="noofsharesheld" className="control-label col-sm-4 required">No of Shares to Transfer</label>
                      <div className="col-sm-8">
                        <input className="form-control" step={1} onChange={(e) => { props.sendValues("transferNoOfShare", e.target.value) }} required="required" min={1} name="noofsharesheld" type="number" id="noofsharesheld" />
                        <p className="help-block">
                          * Required </p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="remarks" className="control-label col-sm-4">Remarks</label>
                      <div className="col-sm-8">
                        <textarea className="form-control" onChange={(e) => { props.sendValues("transferRemark", e.target.value) }} rows={3} name="remarks" cols={50} id="remarks" defaultValue={""} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="document_link" className="control-label col-sm-4">Document Link</label>
                      <div className="col-sm-8">
                      {/* onChange={(e) => { props.sendValues("transferDocLink", e.target.value) } */}
                        <select className="form-control" id="document_link" onChange={(e) => getfilename(e) } name="document_link">
                          <option value='' selected="selected">View from the drop-down list / Select New Doc to upload new</option>
                          <option value={0}>New Document</option>
                          {
                            props.alldocumentclientList && props.alldocumentclientList.map((filepathresult,index)=>{
                              return(<>
                             <option value= {filepathresult.id+"_"+filepathresult.document_type+"_"+filepathresult.indexno}> {filepathresult.file_path.split('/').pop().split('?')[0].split('#')[0].replace(/[/\\?%*:|"<>]/g, '-') }</option>
                              </>);
                            })
                          }
                        </select>
                        <a href="" id="viewdocument" style={{display:"none"}} target="_blank">View Selected Document</a>
                      </div>
                    </div>
                    {props.transferDocLink == '0' ? (
                      <div>
                        <div className="form-group ">
                          <label htmlFor="document_id" className="control-label col-sm-4">Document ID</label>
                          <div className="col-sm-8">
                            <input className="form-control" onChange={(e) => { props.sendValues("transferDocId", e.target.value) }} name="document_id" type="text" id="document_id" />
                            <p className="help-block">
                              Must be unique ID with this client </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="original_filename" className="control-label col-sm-4">Attachment</label>
                          <div className="col-sm-8">
                            <input className="form-control" onChange={(e) => {
                              if (e.target.files[0].size <= 2000000) { props.sendValues("transferAttachment", e.target.files[0]) } else {
                                props.sendValues("transferAttachment", '');
                                e.target.value = '';
                                props.sendError("clientApiStatus", 2);
                                props.sendError("message", "File size limit only 2Mb");
                                setTimeout(() => {
                                  props.sendError("clientApiStatus", 0);
                                  props.sendError("message", "");
                                }, 2500);
                              }
                            }
                            } maxLength={4096} accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif" name="original_filename" type="file" id="original_filename" />
                            <p className="help-block">
                              * Uploading of file limited to 2MB only. </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="document_tag" className="control-label col-sm-4">Description</label>
                          <div className="col-sm-8">
                            <textarea className="form-control" onChange={(e) => { props.sendValues("description", e.target.value) }} rows={3} name="document_tag" cols={50} id="document_tag" defaultValue={""} />
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="keywords" className="control-label col-sm-4">Attachment Keyword for
                          Searching Purposes</label>
                          <div className="col-sm-8">
                            <textarea className="form-control" onChange={(e) => { props.sendValues("transferAttachmentKey", e.target.value) }} rows={3} name="keywords" cols={50} id="keywords" defaultValue={""} />
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="document_type_id" className="control-label col-sm-4">Document
                          Type</label>
                          <div className="col-sm-8">
                            <select className="form-control" onChange={(e) => { props.sendValues("transferDocType", e.target.value) }} id="document_type_id" name="document_type_id">
                              <option value='' >=== Select ===</option>
                              {props.DocumentTypeList.map((val) => {
                                return (
                                  <option value={val.id}>{val.Name}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="document_date" className="control-label col-sm-4">Resolution/Document
                          Date</label>
                          <div className="col-sm-8">
                            <DatePicker
                              showPopperArrow={false}
                              dateFormat={"dd/MM/yyyy"}
                              selected={props.transferDocDate}
                              locale={"en"}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: "0px, 0px",
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: "viewport",
                                },
                              }}
                              onChange={(Date) => {
                                props.sendValues("transferDocDate", Date);
                              }}
                            >
                              <div
                                class="trash-icon"
                                onClick={() =>
                                  props.sendValues("transferDocDate", "")
                                }
                              >
                                <span class="glyphicon glyphicon-trash"></span>{" "}
                              </div>
                            </DatePicker>
                            <p className="help-block">
                              DD/MM/YYYY </p>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label htmlFor="document_tag" className="control-label col-sm-4">Notes</label>
                          <div className="col-sm-8">
                            <textarea className="form-control" onChange={(e) => { props.sendValues("transferNotes", e.target.value) }} rows={3} name="document_tag" cols={50} id="document_tag" defaultValue={""} />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-sm-8">
                      <input className="form-control" name="client_id_doc" type="hidden" defaultValue={489} />
                    </div>
                    <div className="form-group">
                      <div className="text-danger lead strong col-xs-12 col-sm-12 col-md-12 col-lg-12" id="errortext" />
                    </div>
                    <div className="col-sm-8">
                      <input className="form-control" name="client_id" type="hidden" defaultValue={489} />
                    </div>
                    <div className="col-sm-8">
                      <input className="form-control" name="operationtype" type="hidden" defaultValue="T" />
                    </div>
                    <div className="col-sm-12" >
                      {props.clientApiStatus ? (
                        <div
                          className={`alert ${props.clientApiStatus === 1 ? "" : "alert-danger"
                            }`}
                          role="alert"
                        >
                          {props.clientApiStatus === 1 ? "" : props.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button className="btn form-control btn-success" type="submit" value="T" name="action" id="action"><span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" /> Transfer
                        Share</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="bootstrap-dialog-footer">
              <div className="bootstrap-dialog-footer-buttons"><button className="btn form-control" id="a3abe36b-09d9-415c-b0b8-b46ece06a0fe"
                    onClick={handleClose} ><span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
};

const mapStateToProps = (state, ownProps) => {
  const {
    transferShares,
    transferResolutionDate,
    acrafileddate,
    transferShareHolder,
    transferToShareHolder,
    transferShareType,
    transferValueShare,
    transferNoOfShare,
    transferRemark,
    transferDocLink,
    transferDocId,
    transferAttachment,
    transferAttachmentKey,
    transferDocType,
    transferDocDate,
    transferNotes,
    description,
    DocumentTypeList,
    ShareTypeList,
    shareHolderList,
    valuePerShareList,
    toShareHolderList,
    shareLots,
    lotId
  } = state.ShareReg
  return {
    transferShares,
    transferResolutionDate,
    acrafileddate,
    transferShareHolder,
    transferToShareHolder,
    transferShareType,
    transferValueShare,
    transferNoOfShare,
    transferRemark,
    transferDocLink,
    transferDocId,
    transferAttachment,
    transferAttachmentKey,
    transferDocType,
    transferDocDate,
    transferNotes,
    description,
    DocumentTypeList,
    ShareTypeList,
    shareHolderList,
    valuePerShareList,
    toShareHolderList,
    shareLots,
    lotId,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    personalList: state.Personal.personalList,
    alldocumentclientList: state.Client.alldocumentclientList,
  }

};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    allListDocuments: (data) => dispatch(client.allListDocuments(data)),
    sendValues: (k, v) => dispatch(actions.share_register(k, v)),
    sendError: (f, v) => dispatch(client.changeClientInfo(f, v)),
    getShareTypeList: (data) => dispatch(actions.getShareTypeList(data)),
    getShareHolderList: (data) => dispatch(actions.getShareHolderList(data)),
    getValuePerShareList: (data, type) => dispatch(actions.getValuePerShareList(data, type)),
    addTransferShare: (data) => dispatch(actions.addTransferShare(data)),
    getShareLot: (obj) => dispatch(actions.getShareLot(obj)),
  }

};

export default connect(mapStateToProps, mapDispatchToProps)(ShareTransfer);