import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import "../../styles/CommenStyle.css";
import { Modal } from "react-bootstrap";
import * as actions from "../../actions/Settings";

import "react-datepicker/dist/react-datepicker.css";

function EditSettingItem(props) {
  useEffect(() => {
    if (props.addCommonStatus === 1) {
      setTimeout(function () {
        props.setFieldValues("addCommonStatus", 0);
        props.setFieldValues("common_edit_modal_show", false);
        props.reset_common_info();
      }, 2000);
    }
  }, [props.addCommonStatus]);
  const editItem = (event) => {
    event.preventDefault();
    if (validItemInfo()) {
      props.editSettingItem({
        id: props.id,
        description: props.description,
        setting_type: props.setting_type,
      });
    }
  };

  const validItemInfo = () => {
    let valid = true;
    let errors = props.addCommonErrors;
    if (!props.description) {
      valid = false;
      errors.description = "Cannot be Empty";
    } else {
      errors.description = "";
    }
    props.updateCommonSettingErrors(errors);
    return valid;
  };



  const handleClose = () => {
    
    console.log('handleClose: ');



    props.setFieldValues("show", false);
    props.setFieldValues("common_edit_modal_show", false);

    props.reset_common_info();

    console.log("Modal common_edit_modal_show prop:", props.common_edit_modal_show);
    console.log("Modal show prop:", props.common_edit_modal_show);

  };

  return (
    <Modal
    key={props.show ? 'modal-open' : 'modal-closed'} 
    onHide={handleClose} 
      show={props.show}
      className="modal bootstrap-dialog type-primary size-normal "
      role="dialog"
      aria-hidden="true"
      id="infocurrency"
      aria-labelledby="infocurrency"
      tabIndex={-1}
      onHide={() => props.setFieldValues("common_edit_modal_show", false)}
    >
      <div className="modal-content">
        <div className="modal-header bootstrap-dialog-draggable">
          <div className="bootstrap-dialog-header">
            <div
              className="bootstrap-dialog-close-button"
              style={{ display: "none" }}
            >
              <button className="close" aria-label="close">
                ×
              </button>
            </div>
            <div
              className="bootstrap-dialog-title"
              id="2a36ebec-2638-4ea0-b388-a1eb4d8a20d0_title"
            >
              Information
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="bootstrap-dialog-body">
            <div className="bootstrap-dialog-message">
              <div className="bootstrap-dialog-body">
                <form
                  method="POST"
                  action="https://crm2.gbsc.geneasol-apps.info/dashboard/setting/1/currency/1"
                  acceptCharset="UTF-8"
                  id="form"
                  className="form-horizontal"
                >
                  <input name="_method" type="hidden" defaultValue="PUT" />
                  <input
                    name="_token"
                    type="hidden"
                    defaultValue="F3AwN3mnzZZGeheR5gRty4f9fSSfjanNtr2WbpNg"
                  />
                  {props.addCommonStatus ? (
                    <div
                      className={`alert ${
                        props.addCommonStatus === 1
                          ? "alert-success"
                          : "alert-danger"
                      }`}
                      role="alert"
                    >
                      {props.addCommonStatus === 1
                        ? "Item updated successfully"
                        : props.message}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group">
                    <label
                      htmlFor="description"
                      className="control-label col-sm-4 required"
                    >
                      Description
                    </label>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        required="required"
                        name="description"
                        type="text"
                        id="description"
                        value={props.description}
                        onChange={(e) =>
                          props.setFieldValues("description", e.target.value)
                        }
                      />
                      {props.addCommonErrors.description &&
                      props.addCommonErrors.description.length > 0 ? (
                        <span className="text-danger">
                          {props.addCommonErrors.description}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <button
                    className="btn form-control btn-success"
                    type="button"
                    value="update"
                    name="action"
                    onClick={(e) => {
                      // props.changeCompanyInfo("addCompanyStatus", 1);
                      editItem(e);
                    }}
                  >
                    <span className="bootstrap-dialog-button-icon glyphicon glyphicon-ok" />
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="bootstrap-dialog-footer">
            <div className="bootstrap-dialog-footer-buttons">
              <button
                className="btn form-control"
                id="7ce21806-658c-4f15-a8ee-48d73ec19c66"
                data-dismiss="modal"
                onClick={() => {
                  props.setFieldValues("common_edit_modal_show", false);
                  props.reset_common_info();
                }}
              >
                <span className="bootstrap-dialog-button-icon glyphicon glyphicon-remove" />
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    show: state.Settings.common_edit_modal_show,
    description: state.Settings.description,
    id: state.Settings.id,
    setting_type: state.Settings.setting_type,
    addCommonErrors: state.Settings.addCommonErrors,
    addCommonStatus: state.Settings.addCommonStatus,
    message: state.Settings.message,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setFieldValues: (f, v) => dispatch(actions.settings_data(f, v)),
    editSettingItem: (data) => dispatch(actions.editSettingItem(data)),
    updateCommonSettingErrors: (data) =>
      dispatch(actions.updateCommonSettingErrors(data)),
    reset_common_info: () => dispatch(actions.reset_common_info()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSettingItem);
