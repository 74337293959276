import React, { Fragment, useEffect, useState, useRef } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { ColorRing } from "react-loader-spinner";

// import Img from "../../../aactt-node/common/outputTemplates/"

import { connect } from "react-redux";
import "../styles/CommenStyle.css";
import "./LeftSideBar.css";
import history from "../stores/history";
import { Link } from "react-router-dom";
import moment from "moment";
import * as actions from "../actions/clientDetails/Document";
import {
  changeClientInfo,
  check_otpvalidation,
  signatureList,
  documentList,
} from "../actions/Client";
import * as settings from "../actions/Settings";
import queryString from "query-string";
import * as client from "../actions/Client";
import $ from "jquery";
import { Buffer } from "buffer";

var SignaturePad = require("react-signature-pad");
// var imageToBase64 = require("image-to-base64");

function SignUploadPage(props) {
  const { history, location = {} } = props;
  let padRef = useRef({});
  let fileRef = useRef({});
  const [file, setFile] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [iframelink, setIframelink] = useState("");
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [agreeList, setAgreeList] = useState([]);
  const [agreeStatus, setAgreeStatus] = useState(0);

  const [loading, setLoading] = useState(false);
  const [showGoogleViewer, setShowGoogleViewer] = useState(true);
  const [reloadKey, setReloadKey] = useState(0);

  const demoPdfUrl =
    "https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf";

  const handleChange = async (e) => {
    console.log(e.target.value);
    if (e.target.files.length) {
      const base64 = await convertBase64(e.target.files[0]);
      setIframelink(base64);
    }
  };
  const update_selectpreview = async (i) => {
    console.log("🚀 ~ constupdate_selectpreview= ~ i:", i)
    console.log("🚀 ~ constupdate_selectpreview= ~ props.signatureListdata:", props.signatureListdata)

    if (
      props.signatureListdata[i].signature_image != "" &&
      props.signatureListdata[i].signature_image != null
    ) {
      var signimageresult =
        "https://api-gbs.smitiv.co" +
        props.signatureListdata[i].signature_image;
      setIframelink(signimageresult);

      
    } else {
      setIframelink("");
      console.log("🚀 ~ constupdate_selectpreview= ~ signimageresult:", signimageresult)

    }
  };

  useEffect(() => {

    props.check_otpvalidation(props.match.params.id);
    props.signatureList(props.match.params.id);

// Decode the base64 encoded ID
let dtext = Buffer.from(props.match.params.id, "base64").toString("ascii");
console.log("🚀 ~ useEffect ~ dtext:", dtext)

// Split the decoded text
let stext = dtext.split("_");

// Generate the key with or without the "other" string based on the condition
let generatekey = `${stext[0]}_${props.currencyList[props.currencyList.length - 1]['id']}_${stext[2]}`;


// Append "other" to the key if other_type or emails are undefined or falsy
if (!props.currencyList?.other_type || !props.currencyList?.emails) {

  // Encode the key to base64
let encryptedtext = Buffer.from(generatekey).toString('base64');

// Send the encrypted text to the documentList prop
props.documentList(encryptedtext);
} else{

  generatekey += `"_other" + _${stext[2]}`;

  // Encode the key to base64
let encryptedtext = Buffer.from(generatekey).toString('base64');
console.log("🚀 ~ useEffect ~ generatekey:", generatekey)

// Send the encrypted text to the documentList prop
props.documentList(encryptedtext);
}


    // var decrypted_text = Buffer.from(props.match.params.id, "base64").toString("ascii");
    // var spilttext = decrypted_text.split("_");

    

    props.agreeListbyID(props.currencyList[props.currencyList.length-1]['id']);
  }, []);



  useEffect(() => {
    var arr = [];
    props.agreeListdata &&
      props.agreeListdata.map((v, i) => {
        if (sessionStorage.getItem("agree_status") == 1) {
          var obj = { id: v.agreeid, name: v.agree_name, status: true };
        } else if (sessionStorage.getItem("agree_status") == 3) {
          var obj = { id: v.agreeid, name: v.agree_name, status: false };
        } else {
          var obj = { id: v.agreeid, name: v.agree_name, status: false };
        }

        arr.push(obj);
      });
    setAgreeList(arr);
  }, [props.agreeListdata]);
  const changecheckboxValue = (targerid, i) => {
    setAgreeList((prev) => {
      agreeList[i].status = document.getElementById(targerid).checked;
      return agreeList;
    });
    //console.log("agreeList",agreeList)
  };



  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  const AddNewFunc = (e) => {

    setLoading(true)


    if (iframelink == "") {
      alert("Please Preview it. before Upload");
      
    } else if(agreeStatus === 0 ){
      alert("Please select anyone option for document sign");
     
    }else {
      let dtext = Buffer.from(props.match.params.id, "base64").toString("ascii");
      let stext = dtext.split("_");
      let generatekey=`${stext[0]}_${props.currencyList[props.currencyList.length-1]['id']}_${stext[2]}`;
      let encryptedtext=Buffer.from(generatekey).toString('base64');
      console.log("🚀 ~ AddNewFunc ~ generatekey:", generatekey)
      
      let input = {
        image: iframelink,
        document_id: encryptedtext,
        placeholder: placeholder,
        agreeList: agreeList,
        agreestatus: agreeStatus,
        office_bearer_id: sessionStorage.getItem("office_bearer_ids"),
      };
     
      props.signUpload(input)
      .then(()=>{
        setLoading(false)
      })
     
    }

  

  };


// Extract the name after the last '/'
const fileName = props.documentlist.substring(props.documentlist.lastIndexOf('/') + 1);

let filePath = `aactt-node/common/outputTemplates/${fileName}`
console.log('filePath: ', filePath);


  
  const handleClear = () => {
    padRef.current.clear();
  };
  const handleImages = (imagetype) => {
    console.log("🚀 ~ handleImages ~ imagetype:", imagetype)
    if (imagetype == 1) {
      padRef.current.clear();
      $("#drawsign").css("opacity", "1");
      $("#clearButton").css("display", "block");
      $("#signlist").css("display", "none");
    } else if (imagetype == 2) {
      $("#drawsign").css("opacity", "0");
      $("#clearButton").css("display", "none");
      $("#signlist").css("display", "block");
    } else if (imagetype == 3) {
      $("#upload-button").val("");
      $("#drawsign").css("opacity", "0");
      $("#clearButton").css("display", "none");
      $("#signlist").css("display", "none");
      fileRef.current.click();
    }
    setImage({ preview: "", raw: "" });
    setIframelink("");
    setPlaceholder(imagetype);
  };
  const showpreview = () => {
    var imagelink = padRef.current.toDataURL();
    setIframelink(imagelink);
  };
  if (props.otpstatus == 0) {
    setTimeout(function () {
      sessionStorage.clear();
      window.localStorage.removeItem("token");
      window.location = "https://gbs.smitiv.co/"; 
    }, 1000);
  } else {
    console.log("true");
  }
  if (props.clientApiStatus == 4) {
    // sessionStorage.clear();
    // window.localStorage.removeItem("token");
    // window.location = "http://13.214.35.227:4000/"; 
    window.location = `https://gbs.smitiv.co/listing_document/${props.match.params.id}`; 
  }
  // console.log("props.match.params.id", props.match.params.id)
  var decrypted_text = Buffer.from(props.match.params.id, "base64").toString("ascii");
  var spilttext = decrypted_text.split("_");


  const reloadDocument = () => {
    setReloadKey(reloadKey + 1); // Update reloadKey to force re-render
    console.log("🚀 ~ reloadDocument triggered"); // Log to confirm function execution
  };

  return (
    <Fragment>
      <header className="navbar-default navbar-fixed-top">
        <div className="container-fluid">
          {/* Flash Message */}
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle Navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <a className="navbar-brand" href="/">
            GBS Secretarial
            </a>
            <a
              className="navbar-brand"
              href="#"
              style={{ position: "fixed", right: "0" }}
            >
              {spilttext[0]}
            </a>
          </div>
        </div>
      </header>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n        body {\n            padding-top: 60px;\n        }\n    ",
        }}
      />
      <div className="container-fluid">
        <p
          style={{
            color: "red",
            fontSize: "18px",
            padding: "10px",
          }}
        >
          NOTE: Cannot refresh the page until signing and submitting the
          document process
        </p>

        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default">
              <div className="panel-heading">Upload Sign</div>
              <div className="panel-body">
                <div>
                  <button
                    onClick={reloadDocument}
                    className="reload-button btn"
                    style={{ borderRadius: "" }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/reload.png`}
                      style={{ width: "30px" }}
                      alt="Link"
                    />
                  </button>
                  <span style={{ margin: "10px 0 0 0  " }}>
                    {" "}
                    <span style={{ fontSize: "20px", fontWeight: 700 }}>
                      {" "}
                     If you can't see the document click this icon.{" "}
                      <span style={{ color: "red", fontSize: "30px" }}>
                        *
                      </span>{" "}
                    </span>
                  </span>
                </div>
                {props.clientApiStatus ? (
                  <div
                    className={`alert ${
                      props.clientApiStatus == 3 || props.clientApiStatus == 4
                        ? "alert-success"
                        : "alert-danger"
                    }`}
                    role="alert"
                  >
                    {props.message}
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-sm-6 ">
                    <div
                      className="mt-2 text-center"
                      style={{ minHeight: "470px" }}
                    >
                      {/* <DocViewer
                        documents={[
                          { uri: `${props.documentlist}` }, 
                        ]}
                        pluginRenderers={DocViewerRenderers}
                      /> */}

                      {props.documentlist ? (
                        // <iframe
                        //   // ref={iframeRef}
                        //   // key={1}
                        //   key={reloadKey}
                        //   // src ={url}
                        //   src={
                        //     "https://docs.google.com/viewer?url=" +
                        //     props.documentlist +
                        //     "&embedded=true"
                        //   }
                        //   frameBorder="0"
                        //   width="100%"
                        //   id="iFrame1"
                        //   style={{ minHeight: "inherit" }}
                        // />
                        // <iframe
                        //   key={reloadKey}
                        //   src={
                        //     `https://docs.google.com/viewer?url=${encodeURIComponent(
                        //       props.documentlist
                        //     )}&embedded=true` + `&reloadKey=${reloadKey}` // Add reloadKey to URL to force refresh
                        //   }
                        //   frameBorder="0"
                        //   width="100%"
                        //   id="iFrame1"
                        //   style={{
                        //     minHeight: "500px",
                        //     border: "1px solid #ddd",
                        //     marginTop: "20px",
                        //   }}
                        //   title="Google Docs Viewer"
                        // />

                        <iframe
                        key={reloadKey}
                        src={
                          `https://docs.google.com/viewer?url=${encodeURIComponent(
                            props.documentlist
                          )}&embedded=true` + `&reloadKey=${reloadKey}` // Add reloadKey to URL to force refresh
                        }
                        frameBorder="0"
                        width="100%"
                        id="iFrame1"
                        style={{
                          minHeight: "470px",
                          border: "1px solid #ddd",
                          marginTop: "20px",
                        }}
                        title="Google Docs Viewer"
                      />
                      ) : (
                        <div style={{ color: "red", fontSize: "18px" }}>
                          Document viewer is not available. Please check the
                          document URL or upload a valid document.
                        </div>
                      )}
                    </div>

                    <div className="form-group row" style={{ padding: "20px" }}>
                      <div className="col-md-5"></div>
                      <button
                        class="document  btn btn-primary "
                        title="Agree to all resolutions"
                        onClick={(e) => setAgreeStatus(1)}
                      >
                        Agree
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        class="document  btn btn-primary  "
                        title="Agree to partial resolutions"
                        onClick={(e) => {
                          setAgreeStatus(2);
                          var arr = [];
                          props.agreeListdata &&
                            props.agreeListdata.map((v, i) => {
                              var obj = {
                                id: v.agreeid,
                                name: v.agree_name,
                                status: false,
                              };
                              arr.push(obj);
                            });
                          setAgreeList(arr);
                        }}
                      >
                        Partially Agree
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        class="document  btn btn-primary  "
                        title="Disagree to all resolutions"
                        onClick={(e) => {
                          setAgreeStatus(3);
                          var arr = [];
                          props.agreeListdata &&
                            props.agreeListdata.map((v, i) => {
                              var obj = {
                                id: v.agreeid,
                                name: v.agree_name,
                                status: false,
                              };
                              arr.push(obj);
                            });
                          setAgreeList(arr);
                        }}
                      >
                        DisAgree
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-3 ">
                    <p>
                      <button
                        className="btn btn-light same-width"
                        onClick={() => {
                          handleImages(1);
                        }}
                      >
                        Draw sign
                      </button>
                    </p>
                    <p>
                      <button
                        className="btn btn-light same-width"
                        onClick={() => {
                          handleImages(2);
                        }}
                      >
                        Select Previous sign
                      </button>
                    </p>
                    <p>
                      <button
                        className="btn btn-light same-width"
                        onClick={() => {
                          handleImages(3);
                        }}
                      >
                        Upload From computer
                      </button>
                    </p>
                    <p id="signlist" style={{ display: "none" }}>
                      <div className="row">
                        {props.signatureListdata &&
                          props.signatureListdata.map(
                            (signaturevalue, indeximage) => {
                              var imagelinknew = "";
                              if (
                                signaturevalue.signature_image != "" &&
                                signaturevalue.signature_image != null
                              ) {
                                imagelinknew =
                                  "https://api-gbs.smitiv.co" +
                                  signaturevalue.signature_image;
                              }
                              var generatedid = "imageid" + indeximage;
                              return (
                                <>
                                  <div
                                    className="col-sm-4"
                                    style={{ padding: "15px" }}
                                  >
                                    <input
                                      type="radio"
                                      name="image-previous"
                                      value={indeximage}
                                      onClick={() => {
                                        update_selectpreview(indeximage);
                                      }}
                                    />
                                    <img
                                      src={imagelinknew}
                                      id={generatedid}
                                      style={{ width: "100%", height: "100%" }}
                                    />
                                    <br />
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                    </p>
                    <p id="drawsign" style={{ opacity: "0" }}>
                      <SignaturePad ref={padRef} />
                      <p
                        style={{ marginTop: "10px", display: "none" }}
                        id="clearButton"
                      >
                        <button
                          className="btn btn-light same-width-2"
                          onClick={() => {
                            handleClear();
                          }}
                        >
                          Clear{" "}
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-light same-width-2"
                          onClick={() => {
                            showpreview();
                          }}
                        >
                          Preview{" "}
                        </button>
                      </p>
                    </p>

                    <p>
                      <input
                        ref={fileRef}
                        type="file"
                        id="upload-button"
                        style={{ display: "none" }}
                        onChange={handleChange}
                        accept="image/png, image/gif, image/jpeg"
                      />
                    </p>
                  </div>
                  <div className="col-sm-3">
                    <p>
                      <div
                        className="mt-20 text-center"
                        style={{ height: "200px" }}
                      >
                        <h6 className="mb-25">Sign Preview</h6>

                        <iframe
                          // ref={iframeRef}
                          key={1}
                          // src ={url}
                          src={iframelink}
                          frameBorder="0"
                          width="100%"
                          id="iFrame"
                          style={{ minHeight: "inherit" }}
                        />
                      </div>
                    </p>
                    {agreeStatus === 2 ? <h4>List of Resolutions</h4> : ""}
                    <p>
                      {agreeStatus === 2 &&
                        props.agreeListdata &&
                        props.agreeListdata.map((valdata, indexdata) => {
                          return (
                            <>
                              <input
                                key={indexdata}
                                type="checkbox"
                                id={valdata.agree_name}
                                name="agree[]"
                                onChange={(e) =>
                                  changecheckboxValue(
                                    valdata.agree_name,
                                    indexdata
                                  )
                                }
                              />
                              {valdata.agree_name}
                              <br />
                            </>
                          );
                        })}
                    </p>
                    <div className="form-group row" style={{ padding: "20px" }}>
                      <div className="col-md-5"></div>
                      {agreeStatus !== 0 && (
                        <button
                          className="btn btn-primary"
                          onClick={(e) => AddNewFunc(e)}
                        >
                          {loading ? (
                            <ColorRing
                              visible={true}
                              height="25"
                              width="25"
                              ariaLabel="blocks-loading"
                              wrapperStyle={{}}
                              wrapperClass="blocks-wrapper"
                              colors={[
                                "#e15b64",
                                "#f47e60",
                                "#f8b26a",
                                "#abbd81",
                                "#849b87",
                              ]}
                            />
                          ) : (
                            "Submit Signature"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    addNewDocumentModal: state.Document.addNewDocumentModal,
    tabSelected: state.Document.tabSelected,
    pageSelected: state.Client.pageSelected,
    document_id: state.Document.document_id,
    attachment_key: state.Document.attachment_key,
    document_type: state.Document.document_type,
    docDate: state.Document.docDate,
    notes: state.Document.notes,
    clientApiStatus: state.Client.clientApiStatus,
    message: state.Client.message,
    DocumentTypeList: state.Settings.DocumentTypeList,
    otpstatus: state.Client.otpstatus,
    signatureListdata: state.Client.signatureListdata,
    documentlist: state.Client.documentlist,
    agreeListdata: state.Client.agreeListdata,
    currencyList: state.Client.currencyList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendValues: (f, v) => dispatch(actions.documentFunction(f, v)),
    getDocumentList: () => dispatch(settings.getDocumentClassification()),
    changeClientInfo: (k, v) => dispatch(changeClientInfo(k, v)),
    signUpload: (input) => dispatch(actions.signUpload(input)),
    check_otpvalidation: (input) => dispatch(check_otpvalidation(input)),
    signatureList: (input) => dispatch(signatureList(input)),
    documentList: (input) => dispatch(documentList(input)),
    agreeListbyID: (input) => dispatch(client.agreeList_BYID(input)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUploadPage);
