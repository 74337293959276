import config from "../common/Api_Links";
import { headers } from "../common/Headers";
import { ifTokenExpire } from "../common/TokenValidation";
import history from "../../stores/history";
import { s3_file_upload } from "../common/S3_Upload";
import { changeClientInfo } from "../Client";
// var fs = require('fs');


export const documentFunction = (field, value) => {
 

 

  return {
    type: "DOCUMENT_DATA",
    field,
    value,
  };
};

export const attachmentArray = (data) => ({
  type: "ATTACHMENT",
  data,
});
export const multipleidArray = (data) => ({
  type: "ARRAY",
  data,
});
export const multipleidArray_clear = (data) => ({
  type: "CLEARARRAY",
  data,
});

export const editDate = (id, date) => {
  let value = { id: id, date: date };
  var header = headers();
  return (dispatch) => {
    return fetch(config.editDate, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        console.log('responseData: ', responseData);



        if (responseData) {
          dispatch(documentFunction("editDocumentModalDate", false));
          dispatch(
            changeClientInfo(
              "message",
              "Document Resolution date updated successfully!!!"
            )
          );
          dispatch(changeClientInfo("clientApiStatus", 1));

          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
            dispatch(getDocumentList(1));
          }, 2500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
export const reSendMail = (id) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.resendMail, {
      method: "POST",
      body: JSON.stringify({ id }),
      headers: header,
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {

          dispatch(
            documentFunction("reSendMailDetails", responseData.combinedResults)
          );
        }
      });
  };
};

export const saveDocumentNotes = (val, notesdata, document_id) => {
  let url;
  if (val != undefined && val != null) {
    url =
      config.savenotes +
      `?client_id=${sessionStorage.getItem(
        "personalId"
      )}&status=${val}&notes=${notesdata}&document_id=${document_id}`;
  } else {
    url =
      config.savenotes +
      `?client_id=${sessionStorage.getItem(
        "personalId"
      )}&status=${val}&notes=${notesdata}&document_id=${document_id}`;
  }
  var header = headers();
  return (dispatch) => {
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
          } else {
          }
        }
      });
  };
};

export const saveDocumentDescription = (val, descriptiondata, document_id) => {
  let url;
  if (val != undefined && val != null) {
    url =
      config.savedescription +
      `?client_id=${sessionStorage.getItem(
        "personalId"
      )}&status=${val}&description=${descriptiondata}&document_id=${document_id}`;
  } else {
    url =
      config.savedescription +
      `?client_id=${sessionStorage.getItem(
        "personalId"
      )}&status=${val}&description=${descriptiondata}&document_id=${document_id}`;
  }
  var header = headers();
  return (dispatch) => {
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
          } else {
          }
        }
      });
  };
};

export const getDocumentList_Shareholder = (val, val2) => {
  let url;
  if (val != undefined && val != null) {
    url = config.listdocs + `?client_id=${val2}&status=${val}`;
  } else {
    url = config.listdocs + `?client_id=${val2}`;
  }
  var header = headers();
  return (dispatch) => {
    dispatch(documentFunction("documentList", []));
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            let res = responseData.data.filter((lis, idx) => {
              if (lis.list.length > 0) {
                return lis;
              }
            });
            dispatch(documentFunction("documentList", res));
            dispatch(changeClientInfo("processModal", "none"));
          } else {
            dispatch(documentFunction("documentList", []));
          }
        }
      });
  };
};

export const getDocumentPath = (data) => {
  var header = headers();
  let bodyData = {
    doc_ids: data,
  };
  return (dispatch) => {
    return fetch(config.getDocPath, {
      method: "POST",
      body: JSON.stringify(bodyData),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          dispatch({ type: "DOC_PATH", payload: responseData?.docData });
        }
      });
  };
};
export const getDocumentList = (val) => {
  let url;
  if (val != undefined && val != null) {
    url =
      config.listdocs +
      `?client_id=${sessionStorage.getItem("personalId")}&status=${val}`;
  } else {
    url =
      config.listdocs + `?client_id=${sessionStorage.getItem("personalId")}`;
  }
  var header = headers();
  return (dispatch) => {
    dispatch(documentFunction("documentList", []));
    dispatch(changeClientInfo("processModal", "block"));
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            let res = responseData.data.filter((lis, idx) => {
              if (lis.list.length > 0) {
                return lis;
              }
            });
            dispatch(documentFunction("documentList", res));
            dispatch(changeClientInfo("processModal", "none"));
          } else {
            dispatch(documentFunction("documentList", []));
          }
        }
      });
  };
};
export const getDocumentStatus = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.documennt_status, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(documentFunction("documentList2", responseData.data));
          } else {
            dispatch(documentFunction("documentList2", []));
          }
        }
      });
  };
};
export const getDocumentTypeList = () => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.documentTypeList, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(documentFunction("documentTypeList", responseData.data));
          } else {
            dispatch(documentFunction("documentTypeList", []));
          }
        }
      });
  };
};
export const adddocumentcat = (data) => {
  var header = headers();
  return (dispatch) => {
    return fetch(config.add_category, {
      method: "POST",
      body: JSON.stringify(data),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
        }
      });
  };
};
export const sendDocTypeAndGetDocument_AutoPreview = (val, setRendr) => {

  var header = headers();

  let value = { document_type: val };
  // let value = {
  //   "document_type": 99,
  //   "generate_doc": true,
  //   "file_url": "/docx/temp_1621848898616.docx",
  //   "placeholder_list": { "company_name": "Smitv Go Pte Ltd", "registration_no": "GD123", "current_date": "02/04/2022", "director_name1": "Jayaprakash", "director_name2": "dame", "director_name3": "Ashwin", "address": "33 Ubi Ave 3, Singapore 408868", "shareholder_name1": "Jayaprakash", "shareholder_name2": "chinnu", "shareholder_name3": "Dame", "shareholder_name4": "santhosh" }
  // }

  return (dispatch) => {
    return fetch(config.getDocumentPreview, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          

          dispatch(documentFunction("docPreview", responseData.url));
          dispatch(documentFunction("htmldata", responseData.htmldata));
          dispatch({ type: "FILE_JSON", payload: responseData?.fileJson });

          setRendr(true);
        }
      });
  };
};
export const sendDocTypeAndGetDocument = (val) => {
  console.log('==========================================: ');
  console.log('sendDocTypeAndGetDocument: ');
  var header = headers();

  let value = { document_type: val, generate_doc: true, auto_preview: true };
  console.log('sendDocTypeAndGetDocument value: ', value);

  return (dispatch) => {
    return fetch(config.getDocumentPreview, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        console.log(' sendDocTypeAndGetDocument response: ', response);


        return response.json();
      })
      .then((responseData) => {
        console.log('responseData: ', responseData);

        console.log('==========================================: ');

        if (responseData) {
          // var objectURL = myBlob.type;
          // ifTokenExpire(responseData);
          dispatch(documentFunction("docPreview", responseData.url));

          dispatch(documentFunction("htmldata", responseData.htmldata));
          dispatch({ type: "FILE_JSON", payload: responseData?.fileJson });

          dispatch(documentFunction("selectDocumentModal", false));
          dispatch(documentFunction("transferOfShareModal", true));
        } else {
          dispatch(documentFunction("selectDocumentModal", false));
          dispatch(documentFunction("transferOfShareModal", true));
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", "Add file"));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const getDefaultPlaceHolderNext = (val) => {
  var header = headers();
  let value = {
    document_type: val,
    client_id: sessionStorage.getItem("personalId"),
  };

  return (dispatch) => {
    return fetch(config.getDefaultPlaceHolderValues, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          dispatch(documentFunction("defaultFields", responseData.data));
        }
      });
  };
};
export const getDefaultPlaceHolder = (val) => {
  var header = headers();
  let value = {
    document_type: val,
    client_id: sessionStorage.getItem("personalId"),
  };

  return (dispatch) => {
    return fetch(config.getDefaultPlaceHolderValues, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          // var objectURL = myBlob.type;
          // ifTokenExpire(responseData);

          dispatch(documentFunction("defaultFields", responseData.data));

          dispatch(documentFunction("selectDocumentModal", false));
          dispatch(documentFunction("transferOfShareModal", true));
        } else {
          // dispatch(documentFunction("selectDocumentModal", false));
          // dispatch(documentFunction("transferOfShareModal", true));
          // dispatch(changeClientInfo("clientApiStatus", 2));
          // dispatch(changeClientInfo("message", "Add file"));
          // setTimeout(() => {
          //   dispatch(changeClientInfo("clientApiStatus", 0));
          //   dispatch(changeClientInfo("message", ""));
          // }, 2500);
        }
      });
  };
};

// export const getDocumentPreview = (val, setRendr, setLoading) => {


//   var header = headers();
//   let value = val;
//   console.log('value: ', value);

//   return (dispatch) => {

//     return fetch(config.getDocumentPreview, {
//       method: "POST",
//       body: JSON.stringify(value),
//       headers: header,
//     })
//       .then((response) => {
//   console.log(' .then((response) => {: ', response);

//         return response.json();
//       })
//       .then((responseData) => {
//         if (responseData) {
//           console.log('getDocumentPreview: =========== if  ');
//           console.log('responseData.url: ', responseData.url);
//           console.log('responseData?.fileJson: ', responseData?.fileJson);


//           // var objectURL = myBlob.type;
//           // ifTokenExpire(responseData);
         

//           dispatch(documentFunction("docPreview", responseData.url));
         
//           dispatch({ type: "FILE_JSON", payload: responseData?.fileJson });
//           setRendr(true);
//           // setLoading(false);



//           // dispatch(documentFunction("selectDocumentModal", false));
//           // dispatch(documentFunction("transferOfShareModal", true));
//         } else {

          
//           console.log('getDocumentPreview: =========== else  ');
//           // setLoading(false);
//           // dispatch(documentFunction("selectDocumentModal", false));
//           // dispatch(documentFunction("transferOfShareModal", true));
//           // dispatch(changeClientInfo("clientApiStatus", 2));
//           // dispatch(changeClientInfo("message", "Add file"));
//           // setTimeout(() => {
//           //   dispatch(changeClientInfo("clientApiStatus", 0));
//           //   dispatch(changeClientInfo("message", ""));
//           // }, 2500);
//         }
//       });
//   };
// };


export const getDocumentPreview = (val, setRendr, setLoading) => {
  const header = headers();
  const value = val;

  console.log("Starting getDocumentPreview...");
  console.log("Request value: ", value);

  return (dispatch) => {
    try {
      console.log("Dispatching fetch request...");

      return fetch(config.getDocumentPreview, {
        method: "POST",
        body: JSON.stringify(value),
        headers: header,
      })
        .then((response) => {
          console.log("Received fetch response: ", response);

          if (!response.ok) {
            throw new Error(
              `HTTP error! status: ${response.status}, statusText: ${response.statusText}`
            );
          }

          return response.json();
        })
        .then((responseData) => {
          console.log("Received response data: ", responseData);

          if (responseData) {

            console.log("Document preview URL: ", responseData.url);
            console.log("File JSON: ", responseData?.fileJson);

            // Dispatch the document preview URL
            dispatch(documentFunction("docPreview", responseData.url));

            // Dispatch the file JSON payload
            dispatch({ type: "FILE_JSON", payload: responseData?.fileJson });

            console.log("Processing response data...");

            setRendr(true);
            
            console.log("Successfully processed response data.");
          } else {
            console.warn("No response data received!");
          }
        })
        .catch((error) => {
          console.error("Error in fetch or processing response data: ", error);
        });
    } catch (error) {
      console.error("Error in getDocumentPreview function: ", error);
    } finally {
      console.log("Setting loading to false...");
      // setLoading(false);
    }
  };
};

export const sendAndSaveDocument_multiple = (val, dummy, tab) => {
  var header = headers();
  let value = val;

  return (dispatch) => {
    return fetch(config.sendAndSaveDocument, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          // var objectURL = myBlob.type;
          ifTokenExpire(responseData);
          dispatch(documentFunction("transferOfShareModal", false));
          dispatch(documentFunction("document_id", responseData.document_id));
          dispatch(multipleidArray(responseData.document_id));
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 1));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
            if (dummy == "save") {
              dispatch(documentFunction("sendRequestModal", true));
            }
            if (tab == "ALL") {
              dispatch(getDocumentList());
            } else if (tab == "Pending") {
              dispatch(getDocumentList(1));
            } else if (tab == "Received") {
              dispatch(getDocumentList(2));
            }
          }, 2500);
          // dispatch(documentFunction("docPreview", responseData.url));
          // dispatch(documentFunction("selectDocumentModal", false));
          // dispatch(documentFunction("transferOfShareModal", true));
        } else {
          // dispatch(documentFunction("selectDocumentModal", false));
          // dispatch(documentFunction("transferOfShareModal", true));
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", "Add file"));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const sendAndSaveDocument = (val, dummy, tab, templateData, temName) => {
  var header = headers();
  let value = JSON.stringify(val);
  let formData = new FormData();
  let tempFile = templateData;
  if (temName) {
    tempFile = new File([templateData], `${temName}.docx`);
  }
  formData.append("file", tempFile);
  formData.append("templateName", temName);
  formData.append("value", value);
  val.formData = formData;
  return (dispatch) => {
    return fetch(config.sendAndSaveDocument, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: header["Authorization"],
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          // var objectURL = myBlob.type;
          ifTokenExpire(responseData);
          dispatch(documentFunction("transferOfShareModal", false));
          dispatch(documentFunction("document_id", responseData.document_id));
          dispatch(multipleidArray(responseData.document_id));
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 1));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
            if (dummy == "save") {
              dispatch(documentFunction("sendRequestModal", true));
            }
            if (tab == "ALL") {
              dispatch(getDocumentList());
            } else if (tab == "Pending") {
              dispatch(getDocumentList(1));
            } else if (tab == "Received") {
              dispatch(getDocumentList(2));
            }
          }, 2500);
          // dispatch(documentFunction("docPreview", responseData.url));
          // dispatch(documentFunction("selectDocumentModal", false));
          // dispatch(documentFunction("transferOfShareModal", true));
        } else {
          // dispatch(documentFunction("selectDocumentModal", false));
          // dispatch(documentFunction("transferOfShareModal", true));
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", "Add file"));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};
export const sendAndSaveDocumentNext = (val, dummy, tab, templateData) => {
  var header = headers();
  let value = JSON.stringify(val);
  let formData = new FormData();
  formData.append("file", templateData);
  formData.append("value", value);
  val.formData = formData;

  return (dispatch) => {
    return fetch(config.sendAndSaveDocument, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: header["Authorization"],
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          // var objectURL = myBlob.type;
          ifTokenExpire(responseData);
          dispatch(multipleidArray(responseData.document_id));
          dispatch(documentFunction("document_id", responseData.document_id));
        } else {
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", "Add file"));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const addDocument = (val, tab) => {
  var header = headers();
  let formData = new FormData();

  formData.append("template", val.template);
  formData.append("placeholders", val.placeholders);
  formData.append("directorcount", val.directorcount);
  formData.append("secratorycount", val.secratorycount);
  formData.append("shareholdercount", val.shareholdercount);
  formData.append(
    "corporaterepresentativecount",
    val.corporaterepresentativecount
  );

  formData.append("documentcategory", val.documentCategoryField);
  formData.append("isAgree", val.isAgree);
  formData.append("agreeFields", val.agreeFields);
  formData.append("isSign", val.isSign);
  formData.append("signatureFields", val.signatureFields);
  return (dispatch) => {
    return fetch(config.addNewDocument, {
      method: "POST",
      body: formData,
      headers: { Authorization: header.Authorization },
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          // var objectURL = myBlob.type;
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(documentFunction("addNewDocumentModal", false));
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 1));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
              dispatch(getDocumentTypeList());
              if (tab == "ALL") {
                dispatch(getDocumentList());
              } else if (tab == "Pending") {
                dispatch(getDocumentList(1));
              } else if (tab == "Received") {
                dispatch(getDocumentList(2));
              }
            }, 2500);
            // dispatch(documentFunction("docPreview", responseData.url));
            // dispatch(documentFunction("selectDocumentModal", false));
            // dispatch(documentFunction("transferOfShareModal", true));
          } else {
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 2));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 2500);
          }
        } else {
          // dispatch(documentFunction("selectDocumentModal", false));
          // dispatch(documentFunction("transferOfShareModal", true));
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", "Add file"));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const editDocument = (val, dummy, tab) => {
  var header = headers();
  let value = val;

  return (dispatch) => {
    return fetch(config.editDocument, {
      method: "POST",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          // var objectURL = myBlob.type;
          ifTokenExpire(responseData);
          dispatch(documentFunction("transferOfShareModal", false));
          dispatch(documentFunction("document_id", responseData.document_id));
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 1));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
            if (dummy == "save") {
              dispatch(documentFunction("sendRequestModal", true));
            }
            if (tab == "ALL") {
              dispatch(getDocumentList());
            } else if (tab == "Pending") {
              dispatch(getDocumentList(1));
            } else if (tab == "Received") {
              dispatch(getDocumentList(2));
            }
          }, 2500);
          // dispatch(documentFunction("docPreview", responseData.url));
          // dispatch(documentFunction("selectDocumentModal", false));
          // dispatch(documentFunction("transferOfShareModal", true));
        } else {
          // dispatch(documentFunction("selectDocumentModal", false));
          // dispatch(documentFunction("transferOfShareModal", true));
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", "Add file"));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const sendDocumentInMail = (data, tab) => {
  var header = headers();
  var multiple_document_id = data.multiple_document_id;
  if (multiple_document_id.length > 0) {
    let uri = `${
      tab === "Received"
        ? config.sendDocAfterSign
        : config.sendDocumentMail_multiple
    }`;
    return (dispatch) => {
      return fetch(uri, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              dispatch(documentFunction("sendRequestModal", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(changeClientInfo("clientApiStatus", 1));
              dispatch(multipleidArray_clear(responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
                if (tab == "ALL") {
                  dispatch(getDocumentList());
                } else if (tab == "Pending") {
                  dispatch(getDocumentList(1));
                } else if (tab == "Received") {
                  dispatch(getDocumentList(2));
                }
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    };
  } else {
    let uri = `${
      tab === "Received" ? config.sendDocAfterSign : config.sendDocumentMail
    }`;
    return (dispatch) => {
      return fetch(uri, {
        method: "POST",
        body: JSON.stringify(data),
        headers: header,
      })
        .then((response) => {
          if (response) {
            return response.json();
          }
        })
        .then((responseData) => {
          if (responseData) {
            ifTokenExpire(responseData);
            if (responseData.status === 1) {
              dispatch(documentFunction("sendRequestModal", false));
              dispatch(changeClientInfo("message", responseData.message));
              dispatch(changeClientInfo("clientApiStatus", 1));
              dispatch(multipleidArray_clear(responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
                if (tab == "ALL") {
                  dispatch(getDocumentList());
                } else if (tab == "Pending") {
                  dispatch(getDocumentList(1));
                } else if (tab == "Received") {
                  dispatch(getDocumentList(2));
                }
              }, 2500);
            } else {
              dispatch(changeClientInfo("clientApiStatus", 2));
              dispatch(changeClientInfo("message", responseData.message));
              setTimeout(() => {
                dispatch(changeClientInfo("clientApiStatus", 0));
                dispatch(changeClientInfo("message", ""));
              }, 2500);
            }
          }
        });
    };
  }
};
export const documentRejection = (val, tab) => {
  var header = headers();
  // let value = { document_type: val };

  return (dispatch) => {
    return fetch(config.rejectDocument, {
      method: "PUT",
      body: JSON.stringify(val),
      headers: header,
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          // dispatch(documentFunction("rejectDocumentModal", false));
          dispatch(changeClientInfo("clientApiStatus", 1));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(documentFunction("rejectDocumentModal", false));
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
            if (tab == "ALL") {
              dispatch(getDocumentList());
            } else if (tab == "Pending") {
              dispatch(getDocumentList(1));
            } else if (tab == "Received") {
              dispatch(getDocumentList(2));
            }
          }, 2500);
        } else {
          dispatch(documentFunction("rejectDocumentModal", false));
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", "Add file"));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const documentStatusUpdate = (val, tab) => {
  var header = headers();
  // let value = { document_type: val };

  return (dispatch) => {
    return fetch(config.changestatusDocument, {
      method: "PUT",
      body: JSON.stringify(val),
      headers: header,
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          // dispatch(documentFunction("rejectDocumentModal", false));
          dispatch(changeClientInfo("clientApiStatus", 1));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(documentFunction("rejectDocumentModal", false));
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            if (tab == "ALL") {
              dispatch(getDocumentList());
            } else if (tab == "Pending") {
              dispatch(getDocumentList(1));
            } else if (tab == "Received") {
              dispatch(getDocumentList(2));
            }
          }, 2500);
        } else {
          dispatch(documentFunction("rejectDocumentModal", false));
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", "Add file"));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const documentAccept = (val, tab) => {
  var header = headers();
  // let value = { document_type: val };

  return (dispatch) => {
    return fetch(config.approveDocument, {
      method: "PUT",
      body: JSON.stringify(val),
      headers: header,
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData) {
          // dispatch(documentFunction("rejectDocumentModal", false));
          dispatch(changeClientInfo("clientApiStatus", 1));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(documentFunction("acceptDocumentModal", false));
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
            setTimeout(() => {
              // window.location.reload();
            }, 1000);
            if (tab == "ALL") {
              dispatch(getDocumentList());
            } else if (tab == "Pending") {
              dispatch(getDocumentList(1));
            } else if (tab == "Received") {
              dispatch(getDocumentList(2));
            }
          }, 2500);
        } else {
          dispatch(documentFunction("acceptDocumentModal", false));
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", "Add file"));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};

export const deleteDocument = (val, tab) => {
  var header = headers();
  let value = { document_id: val };

  return (dispatch) => {
    return fetch(config.deleteDocument, {
      method: "PUT",
      body: JSON.stringify(value),
      headers: header,
    })
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((responseData) => {
        if (responseData) {
          ifTokenExpire(responseData);
          if (responseData.status === 1) {
            dispatch(documentFunction("deleteDocumentModal", false));
            dispatch(changeClientInfo("message", responseData.message));
            dispatch(changeClientInfo("clientApiStatus", 1));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              if (tab == "ALL") {
                dispatch(getDocumentList());
              } else if (tab == "Pending") {
                dispatch(getDocumentList(1));
              } else if (tab == "Received") {
                dispatch(getDocumentList(2));
              }
            }, 2500);
          } else {
            dispatch(changeClientInfo("clientApiStatus", 2));
            dispatch(changeClientInfo("message", responseData.message));
            setTimeout(() => {
              dispatch(changeClientInfo("clientApiStatus", 0));
              dispatch(changeClientInfo("message", ""));
            }, 2500);
          }
        }
      });
  };
};

export const signUpload = (val) => {
  var header = headers();
  let formData = new FormData();
  formData.append("sign_image_value", val.image);
  formData.append("document_id", val.document_id);
  formData.append("document_type_id", 1);
  formData.append("placeholder_sign_id", val.placeholder);
  formData.append("agreestatus", val.agreestatus);
  formData.append("office_bearer_id", val.office_bearer_id);
  formData.append("agreeList", JSON.stringify(val.agreeList));
  return (dispatch) => {
    return fetch(config.signDocnew, {
      method: "POST",
      body: formData,
      headers: { Authorization: header.Authorization },
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status == 1) {
          // var objectURL = myBlob.type;
          ifTokenExpire(responseData);

          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 4));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 4));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
          // dispatch(documentFunction("docPreview", responseData.url));
          // dispatch(documentFunction("selectDocumentModal", false));
          // dispatch(documentFunction("transferOfShareModal", true));
        } else {
          // dispatch(documentFunction("selectDocumentModal", false));
          // dispatch(documentFunction("transferOfShareModal", true));
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};
export const signUpload_newUser = (val) => {
  var header = headers();
  let formData = new FormData();
  formData.append("sign_image_value", val.image);
  formData.append("document_id", val.document_id);
  formData.append("document_type_id", 1);
  formData.append("placeholder_sign_id", val.placeholder);
  formData.append("agreestatus", val.agreestatus);
  formData.append("office_bearer_id", val.office_bearer_id);
  formData.append("agreeList", JSON.stringify(val.agreeList));
  return (dispatch) => {
    return fetch(config.signdoc_new_user, {
      method: "POST",
      body: formData,
      headers: { Authorization: header.Authorization },
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status == 1) {
          // var objectURL = myBlob.type;
          ifTokenExpire(responseData);
          dispatch(changeClientInfo("message", responseData.message));
          dispatch(changeClientInfo("clientApiStatus", 3));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 6));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
          // dispatch(documentFunction("docPreview", responseData.url));
          // dispatch(documentFunction("selectDocumentModal", false));
          // dispatch(documentFunction("transferOfShareModal", true));
        } else {
          // dispatch(documentFunction("selectDocumentModal", false));
          // dispatch(documentFunction("transferOfShareModal", true));
          dispatch(changeClientInfo("clientApiStatus", 2));
          dispatch(changeClientInfo("message", responseData.message));
          setTimeout(() => {
            dispatch(changeClientInfo("clientApiStatus", 0));
            dispatch(changeClientInfo("message", ""));
          }, 2500);
        }
      });
  };
};
